<template>
  <div
    v-if="editor"
    class="ProseMirror_container--outer"
  >
    <div
      class="ProseMirror_menu"
      @click.stop
    >
      <div class="menu-button-group">
        <!-- undo, redo -->
        <rg-button
          tabindex="-1"
          title="Rückgängig (Strg+Z)"
          :disabled="!editor.can().undo()"
          icon="undo"
          @click="editor.chain().focus().undo().run()"
        />
        <rg-button
          tabindex="-1"
          title="Wiederholen (Strg+Y)"
          :disabled="!editor.can().redo()"
          icon="redo"
          @click="editor.chain().focus().redo().run()"
        />
      </div>
      <div class="menu-button-group">
        <!-- bold, italic, underline -->
        <rg-button
          tabindex="-1"
          title="Fett (Strg+B)"
          :disabled="!editor.can().toggleBold()"
          :type="{ 'is-primary': editor.isActive('bold') }"
          icon="bold"
          @click="editor.chain().focus().toggleBold().run()"
        />
        <rg-button
          tabindex="-1"
          title="Kursiv (Strg+I)"
          :disabled="!editor.can().toggleItalic()"
          :type="{ 'is-primary': editor.isActive('italic') }"
          icon="italic"
          @click="editor.chain().focus().toggleItalic().run()"
        />
        <rg-button
          tabindex="-1"
          title="Unterstrichen (Strg+U)"
          :type="{ 'is-primary': editor.isActive('underline') }"
          icon="underline"
          @click="editor.chain().focus().toggleUnderline().run()"
        />
      </div>
      <div class="menu-button-group">
        <!-- align-left, align-center, align-right -->
        <rg-button
          tabindex="-1"
          title="Text links ausrichten"
          :type="{ 'is-primary': editor.isActive({ textAlign: 'left' }) }"
          icon="align-left"
          @click="editor.chain().focus().setTextAlign('left').run()"
        >
          left
        </rg-button>
        <rg-button
          tabindex="-1"
          title="Text zentrieren"
          :type="{ 'is-primary': editor.isActive({ textAlign: 'center' }) }"
          icon="align-center"
          @click="editor.chain().focus().setTextAlign('center').run()"
        >
          center
        </rg-button>
        <rg-button
          tabindex="-1"
          title="Text rechts ausrichten"
          :type="{ 'is-primary': editor.isActive({ textAlign: 'right' }) }"
          icon="align-right"
          @click="editor.chain().focus().setTextAlign('right').run()"
        >
          right
        </rg-button>
      </div>
      <rg-button
        tabindex="-1"
        title="Link einfügen (Strg+K)"
        :type="{ 'is-primary': editor.isActive('link') }"
        icon="paperclip"
        @click="setLink"
      />
      <div class="menu-button-group">
        <!-- list-ul, list-ol -->
        <rg-button
          tabindex="-1"
          title="Aufzählung (Strg+U)"
          :type="{ 'is-primary': editor.isActive('bulletList') }"
          icon="list-ul"
          @click="editor.chain().focus().toggleBulletList().run()"
        />
        <rg-button
          tabindex="-1"
          title="Nummerierung (Strg+O)"
          :type="{ 'is-primary': editor.isActive('orderedList') }"
          icon="list-ol"
          @click="editor.chain().focus().toggleOrderedList().run()"
        />
      </div>
      <div class="menu-button-group">
        <!-- subscript, superscript -->
        <rg-button
          tabindex="-1"
          title="Tiefgestellt (Strg+,)"
          :type="{ 'is-primary': editor.isActive('subscript') }"
          icon="subscript"
          @click="editor.chain().focus().toggleSubscript().run()"
        />
        <rg-button
          tabindex="-1"
          title="Hochgestellt (Strg+.)"
          :type="{ 'is-primary': editor.isActive('superscript') }"
          icon="superscript"
          @click="editor.chain().focus().toggleSuperscript().run()"
        />
      </div>
      <rg-dropdown
        :width="500"
        position="bottom-center"
      >
        <template #trigger>
          <rg-button
            tabindex="-1"
            :title="editor.isActive('image') ? 'Bild bearbeiten' : 'Bild einfügen'"
            icon-left="image"
            icon-right="angle-down"
            :type="{ 'is-primary': editor.isActive('image') }"
            @click="() => {
              const previousUrl = editor.getAttributes('image').src;
              if (previousUrl) newImageUrl = previousUrl
            }"
          />
        </template>
        <div>
          <p>
            <strong v-if="isFrickeUrl">URL eines Fricke-Cloud Ordners erkannt</strong>
            <strong v-else>URL des Bildes:</strong>
            <textarea
              v-model="newImageUrl"
              :style="{
                width: '100%',
                resize: 'vertical',
                minHeight: '50px',
                maxHeight: '200px',
                marginTop: '10px',
              }"
              rows="4"
              placeholder="Bitte die URL des Bildes eingeben"
              :class="{'has-danger-glow': newImageUrl.length && !isFrickeUrl && !isValidImageUrl}"
              @click.stop
            />
          </p>
          <p v-if="isFrickeUrl">
            <strong>Bitte den Bildnamen inkl. Dateiendung angeben:</strong>
            <input
              v-model="newImageName"
              type="text"
              placeholder="z.B. 'Bild.jpg'"
              :style="{
                width: '100%',
                marginTop: '10px',
              }"
              :class="{'has-danger-glow': newImageName.length && !isValidImageName}"
              @click.stop
            >
            <ol>
              <li>Das gewünschte Bild in der Fricke-Cloud anklicken/öffnen</li>
              <li>Oben rechts auf den Button "Seitenleiste öffnen" klicken</li>
              <li>In der Seitenleiste den Bildnamen kopieren und hier einfügen</li>
              <ul>
                <li>Ein Dreifachklick markiert den kompletten Text.</li>
              </ul>
            </ol>
          </p>
          <p v-else>
            <strong>Hinweis zum Einfügen von Bildern aus der Fricke-Cloud:</strong>
            <ol>
              <li>Ordner in der Fricke-Cloud öffnen.</li>
              <li>URL aus der Adresszeile kopieren und oben einfügen.</li>
            </ol>
            <strong>Wichtig: Bilder aus der Fricke-Cloud müssen leider nach wie vor von einem Admin freigegeben werden.</strong>
          </p>
          <rg-button
            tabindex="-1"
            icon-left="save"
            :disabled="!editor.can().setImage() || (isFrickeUrl && !isValidImageName)"
            type="is-primary"
            @click="addImage"
          >
            {{ editor.isActive('image') ? 'Speichern' : 'Einfügen' }}
          </rg-button>
        </div>
      </rg-dropdown>
      <rg-dropdown
        :width="300"
        position="bottom-center"
      >
        <template #trigger>
          <rg-button
            tabindex="-1"
            :title="editor.isActive('table') ? 'Tabelle bearbeiten' : 'Tabelle einfügen'"
            icon-left="table"
            icon-right="angle-down"
            :type="{ 'is-primary': editor.isActive('table') }"
          />
        </template>
        <div v-if="editor.isActive('table')">
          <rg-button
            tabindex="-1"
            :disabled="!editor.can().addColumnBefore()"
            @click="editor.chain().focus().addColumnBefore().run()"
          >
            <fa-layers
              class="fa-fw"
              style="margin-right:4px"
            >
              <fa
                icon="caret-left"
                transform="left-6"
                style="opacity:1"
              />
              <fa
                icon="minus"
                transform="rotate-90 grow-5 right-2"
                style="opacity:.4"
              />
            </fa-layers>
            Spalte links hinzufügen
          </rg-button>
          <rg-button
            tabindex="-1"
            :disabled="!editor.can().addColumnAfter()"
            @click="editor.chain().focus().addColumnAfter().run()"
          >
            <fa-layers
              class="fa-fw"
              style="margin-right:4px"
            >
              <fa
                icon="caret-right"
                transform="right-6"
                style="opacity:1"
              />
              <fa
                icon="minus"
                transform="rotate-90 grow-5 left-2"
                style="opacity:.4"
              />
            </fa-layers>
            Spalte rechts hinzufügen
          </rg-button>
          <rg-button
            tabindex="-1"
            :disabled="!editor.can().deleteColumn()"
            type="is-danger"
            @click="editor.chain().focus().deleteColumn().run()"
          >
            <fa-layers
              class="fa-fw"
              style="margin-right:4px"
            >
              <fa
                icon="times"
                style="opacity:1"
              />
              <fa
                icon="minus"
                transform="rotate-90 grow-5"
                style="opacity:.4"
              />
            </fa-layers>
            Spalte löschen
          </rg-button>
          <hr>
          <rg-button
            tabindex="-1"
            :disabled="!editor.can().addRowBefore()"
            @click="editor.chain().focus().addRowBefore().run()"
          >
            <fa-layers
              class="fa-fw"
              style="margin-right:4px"
            >
              <fa
                icon="caret-up"
                transform="up-5"
                style="opacity:1"
              />
              <fa
                icon="minus"
                transform="grow-5 down-3"
                style="opacity:.4"
              />
            </fa-layers>
            Zeile oberhalb hinzufügen
          </rg-button>
          <rg-button
            tabindex="-1"
            :disabled="!editor.can().addRowAfter()"
            @click="editor.chain().focus().addRowAfter().run()"
          >
            <fa-layers
              class="fa-fw"
              style="margin-right:4px"
            >
              <fa
                icon="caret-down"
                transform="down-5"
                style="opacity:1"
              />
              <fa
                icon="minus"
                transform="grow-5 up-3"
                style="opacity:.4"
              />
            </fa-layers>
            Zeile unterhalb hinzufügen
          </rg-button>
          <rg-button
            tabindex="-1"
            :disabled="!editor.can().deleteRow()"
            type="is-danger"
            @click="editor.chain().focus().deleteRow().run()"
          >
            <fa-layers
              class="fa-fw"
              style="margin-right:4px"
            >
              <fa
                icon="times"
                style="opacity:1"
              />
              <fa
                icon="minus"
                transform="grow-5"
                style="opacity:.4"
              />
            </fa-layers>
            Zeile löschen
          </rg-button>
          <hr>
          <rg-button
            tabindex="-1"
            :disabled="!editor.can().toggleHeaderRow()"
            @click="editor.chain().focus().toggleHeaderRow().run()"
          >
            <fa-layers
              class="fa-fw"
              style="margin-right:4px"
            >
              <fa
                icon="minus"
                transform="grow-2 up-6"
                style="opacity:1"
              />
              <fa
                icon="minus"
                transform="grow-2 up-5"
                style="opacity:1"
              />
              <fa
                icon="th"
                style="opacity:.4"
              />
            </fa-layers>
            Überschriften-Zeile
          </rg-button>
          <rg-button
            tabindex="-1"
            :disabled="!editor.can().toggleHeaderColumn()"
            @click="editor.chain().focus().toggleHeaderColumn().run()"
          >
            <fa-layers
              class="fa-fw"
              style="margin-right:4px"
            >
              <fa
                icon="minus"
                transform="rotate-90 left-7"
                style="opacity:1"
              />
              <fa
                icon="minus"
                transform="rotate-90 left-5"
                style="opacity:1"
              />
              <fa
                icon="th"
                style="opacity:.4"
              />
            </fa-layers>
            Überschriften-Spalte
          </rg-button>
          <hr>
          <rg-button
            tabindex="-1"
            :disabled="!editor.can().deleteTable()"
            type="is-danger"
            icon-left="trash"
            @click="editor.chain().focus().deleteTable().run()"
          >
            Tabelle löschen
          </rg-button>
        </div>
        <div v-else-if="editor.can().insertTable()">
          <p>
            <strong>Tabelle generieren: </strong>
            <span>({{ lastHighlight[0] }} × {{ lastHighlight[1]+1 }})</span>
          </p>
          <div class="table_generator">
            <div
              v-for="(_, ROW) in Array(6)"
              :key="ROW"
              class="table_generator-row"
            >
              <div
                v-if="!ROW"
                class="table_generator-root"
              >
                <fa
                  icon="ruler-combined"
                  transform="rotate-270"
                />
              </div>
              <div
                v-else
                class="table_generator-label"
              >
                {{ ROW }}
              </div>
              <div
                v-for="(__, COL) in Array(9)"
                :key="COL"
              >
                <div
                  v-if="!ROW"
                  class="table_generator-label"
                >
                  {{ COL+1 }}
                </div>
                <div
                  v-if="ROW"
                  :class="[
                    'table_generator-cell',
                    {
                      'table_generator-cell--active':
                        lastHighlight[0] >= ROW && lastHighlight[1] >= COL
                    },
                  ]"
                  @mouseenter="lastHighlight = [ROW, COL]"
                  @click="editor.chain().focus().insertTable({ rows: ROW, cols: COL + 1, withHeaderRow: true }).run()"
                />
              </div>
            </div>
          </div>
          <p>
            <strong>Andere Größen generieren:</strong>
          </p>
          <div
            class="table_generator-other"
            @click.stop
          >
            <input
              v-model="manualTable[0]"
              type="text"
              placeholder="Zeilen"
              :class="{'has-danger-glow': parseInt(manualTable[0]) > 100}"
            >
            <fa
              icon="times"
              style="opacity:.4"
            />
            <input
              v-model="manualTable[1]"
              type="text"
              placeholder="Spalten"
              :class="{'has-danger-glow': parseInt(manualTable[1]) > 100}"
            >
            <rg-button
              type="is-primary"
              :disabled="
                !manualTable[0] || parseInt(manualTable[0]) != manualTable[0] ||
                  parseInt(manualTable[0]) < 1 || parseInt(manualTable[0]) > 1000 ||
                  !manualTable[1] || parseInt(manualTable[1]) != manualTable[1] ||
                  parseInt(manualTable[1]) < 1 || parseInt(manualTable[1]) > 1000"
              @click="
                editor.chain().focus().insertTable({
                  rows: manualTable[0] || 0,
                  cols: manualTable[1] || 0,
                  withHeaderRow: true
                }).run()
              "
            >
              <fa icon="table" />
              <fa
                icon="plus"
                style="margin-left:3px"
              />
            </rg-button>
          </div>
        </div>
      </rg-dropdown>
      <rg-dropdown
        :width="300"
        position="bottom-center"
      >
        <template #trigger>
          <rg-button
            tabindex="-1"
            title="HTML einfügen"
            icon-left="code"
            icon-right="angle-down"
          />
        </template>
        <div>
          <p>
            <strong>HTML beim Cursor einfügen:</strong>
            <textarea
              v-model="newHTMLContent"
              :style="{
                width: '100%',
                resize: 'vertical',
                minHeight: '50px',
                maxHeight: '200px',
                marginTop: '10px',
              }"
              rows="4"
              placeholder="Bitte den HTML-Code einfügen"
              @click.stop
            />
          </p>
          <rg-button
            tabindex="-1"
            icon-left="plus"
            :disabled="!newHTMLContent || !editor.can().insertContent()"
            type="is-primary"
            @click="addContent"
          >
            Einfügen
          </rg-button>
        </div>
      </rg-dropdown>
      <rg-button
        tabindex="-1"
        :title="preview ? 'Vorschau verbergen' : 'Vorschau anzeigen'"
        icon="eye"
        :type="{ 'is-primary': preview }"
        @click="preview = !preview"
      />
    </div>
    <div
      v-if="!preview"
      class="ProseMirror_container"
      @click="setFocus($event)"
    >
      <bubble-menu
        :editor="editor"
        :tippy-options="{ duration: 100 }"
      >
        <div
          class="menu-button-group card"
          style="border-radius:9px;padding:1px"
        >
          <rg-button
            tabindex="-1"
            title="Fett (Strg+B)"
            :disabled="!editor.can().toggleBold()"
            :type="{ 'is-primary': editor.isActive('bold') }"
            icon="bold"
            @click="editor.chain().focus().toggleBold().run()"
          />
          <rg-button
            tabindex="-1"
            title="Kursiv (Strg+I)"
            :disabled="!editor.can().toggleItalic()"
            :type="{ 'is-primary': editor.isActive('italic') }"
            icon="italic"
            @click="editor.chain().focus().toggleItalic().run()"
          />
          <rg-button
            tabindex="-1"
            title="Unterstrichen (Strg+U)"
            :type="{ 'is-primary': editor.isActive('underline') }"
            icon="underline"
            @click="editor.chain().focus().toggleUnderline().run()"
          />
          <rg-button
            v-if="editor.can().mergeCells()"
            tabindex="-1"
            title="Verbindet mehrere Zellen zu einer"
            icon-left="link"
            @click="editor.chain().focus().mergeCells().run()"
          >
            Zellen verbinden
          </rg-button>
          <rg-button
            v-else-if="editor.can().splitCell()"
            tabindex="-1"
            title="Löst verbundene Zellen auf"
            icon-left="unlink"
            @click="editor.chain().focus().splitCell().run()"
          >
            Zellen teilen
          </rg-button>
        </div>
      </bubble-menu>
      <editor-content :editor="editor" />
    </div>
    <div
      v-else
      class="ProseMirror_container"
      v-html="editor.getHTML()"
    />
  </div>
</template>

<script>
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import HardBreak from '@tiptap/extension-hard-break'
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Link from '@tiptap/extension-link'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import Underline from '@tiptap/extension-underline'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import History from '@tiptap/extension-history'
import Image from '@tiptap/extension-image'
import Dropcursor from '@tiptap/extension-dropcursor'
import Gapcursor from '@tiptap/extension-gapcursor'
import TextAlign from '@tiptap/extension-text-align'

import dropdown from "@/components/elements/rg-dropdown";

export default {
  components: {
    EditorContent,
    BubbleMenu,
    "rg-dropdown": dropdown
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editor: null,
      preview: false,
      lastHighlight: [1, 1],
      manualTable: [null, null],
      newImageUrl: '',
      newImageName: '',
      newHTMLContent: ''
    }
  },
  computed: {
    isFrickeUrl() {
      return /cloud\.fricke.+fra1\.repguide\//.test(this.newImageUrl.trim())
    },
    isValidImageUrl() {
      // NOTE: intentionally not checking for file extensions -> it would only cause support side-effects
      return /^https?:\/\/.+\.\w{3,4}$/.test(this.newImageUrl.trim())
    },
    isValidImageName() {
      // NOTE: intentionally not checking for file extensions -> it would only cause support side-effects
      return /\.\w{3,4}$/.test(this.newImageName.trim())
    },
  },
  watch: {
    value(value) {
      if (this.editor.getHTML() === value) return
      this.editor.commands.setContent(value, false)
      
      // INFO: JSON => const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)
    },
  },
  mounted() {
    const INSTANCE = this

    this.editor = new Editor({
      content: this.value,
      onFocus({ event }) {
        INSTANCE.$emit('focus', event)
      },
      extensions: [
        Document,
        Paragraph,
        HardBreak,
        Text,
        Bold,
        Italic,
        Link.extend({
          addKeyboardShortcuts() {
            return {
              'Mod-k': () => INSTANCE.setLink()
            }
          }
        }),
        Subscript,
        Superscript,
        Underline,
        BulletList,
        OrderedList,
        ListItem,
        Table,
        TableRow,
        TableHeader,
        TableCell,
        History,
        Image.configure({ inline: true }),
        Dropcursor,
        Gapcursor,
        TextAlign.configure({
          types: ['paragraph'],
          alignments: ['left', 'center', 'right'],
        }),
      ],
      onUpdate: () => {
        // HTML
        INSTANCE.$emit('input', this.editor.getHTML())

        // JSON
        // INSTANCE.$emit('input', this.editor.getJSON())
      },
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    addImage() {
      let src = this.newImageUrl.trim()

      if (this.isFrickeUrl) {
        const BASE_URL = 'https://repguide.fra1.digitaloceanspaces.com/'
        const FRICKE_URL = this.newImageUrl.trim().match(/cloud\.fricke.+fra1\.repguide\/(.+?)(?:[&#].*)?$/)?.[1]

        src = BASE_URL + FRICKE_URL + '/' + encodeURIComponent(this.newImageName.trim())
      }

      this.editor.chain().focus().setImage({ src }).run()
    },
    addContent() {
      const TEMP = document.createElement('div');
      TEMP.innerHTML = this.newHTMLContent.trim()

      TEMP.querySelectorAll('*').forEach((node) => {
        if (['TH', 'TD'].includes(node.tagName) && !node.innerHTML) {
          node.appendChild(document.createElement('p'))
        }
      });

      this.editor.chain().focus().insertContent(TEMP.innerHTML, {
        parseOptions: {
          preserveWhitespace: true,
        }
      }).run()
    },
    setFocus(e) {
      if (e.srcElement.className === 'ProseMirror_container')
        this.editor.commands.focus('end')
    },
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      if (url === '') this.editor.chain().focus().extendMarkRange('link').unsetLink().run()
      if (!url) return // NOTE: cancelled window.prompt()

      this.editor.chain().focus().extendMarkRange('link').setLink({ href: url, target: '_blank' }).run()
    },
  },
}
</script>

<style lang="scss">
.table_generator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin: 2px;

  .table_generator-row {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .table_generator-root {
    height: 25px;
    width: 25px;
    display: grid;
    align-content: center;
    justify-items: center;
    opacity: .4;
  }
  .table_generator-label {
    height: 25px;
    width: 25px;
    display: grid;
    align-content: center;
    justify-items: center;
    font-family: monospace;
    border-radius: 5px;
    background: var(--card-light-stack);
  }
  .table_generator-cell {
    height: 25px;
    width: 25px;
    border: 1px solid;
    cursor: pointer;
    &--active {
      background: var(--card-light-stack);
    }
  }
}

.table_generator-other {
  display: flex;
  align-items: center;
  padding: 0 5px;
  gap: 5px;

  input { width: 105px }
  button { margin: 0!important; }
}

.ProseMirror_container--outer {
  border: 2px solid var(--card-light-stack);
  border-radius: 8px;
}

.ProseMirror_container {
  background: var(--input-background);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow: auto;
  resize: vertical;
  cursor: text;
  min-height: 150px;
}

.ProseMirror_menu {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  background: var(--black-1);
  padding: 4px;
  border-bottom: 1px solid var(--card-light-stack);
  cursor: default;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ProseMirror {
  padding: 8px;
  display: block;
  font-size: 1em;
  line-height: 1em;
  color: var(--text-dark);
  outline: none;
  &:focus {
    border-color: var(--text-dark);
  }
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: 0.75em;
  }
  div.ProseMirror-selectednode {
    outline: 3px solid var(--primary);
  }
  img {
    max-width: 100%;
    height: auto;

    &.ProseMirror-selectednode {
      outline: 3px solid var(--primary);
    }
  }
  svg {
    display: block;
    &.ProseMirror-selectednode {
      outline: 3px solid var(--primary)!important;
    }
  }
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;
    cursor: cell;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: var(--card-light-stack);
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0; right: 0; top: 0; bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    p {
      margin: 0;
    }

    ul,
    ol {
      padding: 0 1rem;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
.menu-button-group {
  display: inline-flex;
  button {
    border-radius: 0;
  }
  button:first-of-type {
    border-top-left-radius:8px;border-bottom-left-radius:8px;
  }
  button:last-of-type {
    border-top-right-radius:8px;border-bottom-right-radius:8px;
  }
}
</style>