<template>
  <div :style="cssLevelVars">
    <navigation>
      <div
        v-if="inputArray.length"
        style="display:flex;align-items:center"
      >
        <span style="margin-right:5px">Autosave</span>
        <label
          class="switch"
          style="margin-right:5px"
          :title="'Automatisches Speichern ist '+(autosave ? 'aktiviert' : 'deaktiviert')"
        >
          <input
            v-model="autosave"
            type="checkbox"
            @change="autosavetoggle()"
          >
          <span class="slider" />
        </label>&nbsp;

        <rg-dropdown
          stay
          :width="500"
          position="bottom-center"
        >
          <template #trigger>
            <rg-button
              :notice="audit.errors"
              title="Formular auf Fehler überprüfen"
              style="margin-right:5px"
              icon-left="tasks"
              icon-right="chevron-down"
              label="Prüfen"
            />
          </template>
          <rg-button
            title="Fehlerüberprüfung starten"
            style="justify-content:center"
            label="Formular auf Fehler überprüfen"
            type="is-primary"
            @click="saveValidation()"
          /><br>
          <div v-if="audit.length">
            <hr>
            <p style="text-align:center">
              <strong v-if="audit.errors">{{ audit.errors }} Fehler gefunden, davon {{ audit.fatal }} <dfn title="Schwerwiegende Fehler verhindern das Speichern">schwerwiegend</dfn>.</strong>
            </p>
            <div
              v-if="!audit.errors"
              style="text-align:center"
            >
              <div :class="$style.audit_check">
                <fa
                  class="fa-fw"
                  icon="check"
                />
              </div><br>
              <strong>Keine Fehler gefunden</strong><br><br>
            </div>
            <template v-for="line in audit">
              <p
                v-if="line.errors.length"
                :key="line.id"
              >
                <fa
                  v-if="line.fatal"
                  class="fa-fw"
                  icon="exclamation"
                />&nbsp;
                <span
                  v-if="!settings.view.lineVisible(line.line)"
                  title="Dieses Element ist aktuell ausgeblendet. Wähle eine andere Formularansicht um zu diesem Element zu scrollen."
                >
                  <strong>Zeile {{ line.line+1 }}</strong>: Element "{{ line.title }}" hat {{ line.fatal ? 'schwerwiegende' : '' }} Fehler.
                </span>
                <a
                  v-else
                  @click="theElderScrolls(line.line)"
                >
                  <strong>Zeile {{ line.line+1 }}</strong>: Element "{{ line.title }}" hat {{ line.fatal ? 'schwerwiegende' : '' }} Fehler.
                </a>
                <ul style="margin:0">
                  <li
                    v-for="error in line.errors"
                    :key="error"
                  >
                    {{ error }}
                  </li>
                </ul>
              </p>
            </template>
            <p style="text-align:center; opacity:.5">
              {{ audit.length }} {{ audit.length === 1 ? 'Zeile' : 'Zeilen' }} in {{ audit.performance }}ms überprüft.
            </p>
          </div>
          <div v-else>
            <hr>
            <p>
              <strong><fa
                icon="info"
                class="fa-fw"
              />&nbsp;Aktuell werden folgende Fehler erkannt:</strong>
              <ul>
                <li
                  v-for="rule in rules"
                  :key="rule"
                  v-html="rule"
                />
              </ul>
            </p>
          </div>
        </rg-dropdown>


        <rg-button
          style="border-top-right-radius: 0px; border-bottom-right-radius: 0px"
          :title="audit.fatal ? 'Eingaben mit schwerwiegenden Fehlern können nicht gespeichert werden' : 'Änderungen speichern ⌘ S'"
          icon-left="save"
          type="is-primary"
          label="Speichern"
          :disabled="!language.id || versionOutdated"
          @click="save(!version)"
        />
        <rg-dropdown
          stay
          icon="chevron-down"
          style="margin-right:5px"
        >
          <template #trigger>
            <rg-button
              style="border-top-left-radius: 0px; border-bottom-left-radius: 0px"
              icon="chevron-down"
            />
          </template>
          <rg-button
            title="Dupliziert die aktuelle Version"
            :disabled="!version"
            icon-left="save"
            label="Version duplizieren"
            @click="save(true)"
          />
          <rg-button
            title="Leere Attribute entfernen"
            icon-left="broom"
            label="Version bereinigen"
            @click="cleanUp()"
          />
          <rg-button
            title="Version als JSON herunterladen"
            icon-left="download"
            label="JSON generieren"
            @click="startRecovery()"
          />

          <hr v-if="version">
          <p v-if="version">
            <code style="user-select: all">{{ version }}</code>
          </p>
        </rg-dropdown>
        <input
          type="text"
          :style="{
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
            width: '75px',
            borderRight: 'none',
            lineHeight: '18px',
            fontFamily: 'monospace',
            fontSize: '16px'
          }"
          :value="selectedNew"
          @keyup.enter="jumpTo()"
          @input="(e) => {
            if (e.target.value.match(/[^0-9]/g)) {
              e.target.value = e.target.value.replace(/[^0-9]/g, '');
            }
            if (e.target.value > inputArray.length) {
              e.target.value = inputArray.length;
            }
            selectedNew = e.target.value
          }"
        >
        <rg-button
          :title="selectedNew ? `Zu Element ${selectedNew} scrollen` : ''"
          style="margin-right:5px; border-top-left-radius: 0px; border-bottom-left-radius: 0px"
          :icon="selectedNew-1 > selected ? 'long-arrow-alt-down' : selectedNew-1 < selected ? 'long-arrow-alt-up' : 'arrows-alt-v'"
          @click="jumpTo()"
        />
        <rg-dropdown
          stay
          :width="420"
          :height="690"
          icon="list-ol"
          triggertitle="Verzeichnisse"
          style="margin-right:5px"
        >
          <p><strong>Inhaltsverzeichnisse</strong></p>
          <div :class="$style.tabswitch">
            <rg-button
              :type="(directories.current === 'groups' ? 'is-primary' : '')"
              label="Gruppenstruktur"
              @click="directories.set('groups')"
            />
            <rg-button
              :type="(directories.current === 'marked' ? 'is-primary' : '')"
              label="Markierte Elemente"
              @click="directories.set('marked')"
            />
          </div>
          <hr>
          <template v-if="directories.current === 'groups'">
            <p
              v-if="!directories.groups.length"
              style="margin-top: 10px"
              class="text-center"
            >
              Keine Gruppen im Formular gefunden
            </p>
            <draggable
              tag="div"
              handle=".tocEntry_grip"
              animation="200"
              ghost-class="ghost"
              @end="directories.handleDrops"
            >
              <transition-group
                type="transition"
                name="flip-list"
              >
                <div
                  v-for="e in directories.groups"
                  :key="e.id"
                  :class="[$style.tocEntry, 'list-group-item']"
                >
                  <div>
                    <span :class="[$style.tocEntry_grip, 'tocEntry_grip']"><fa
                      icon="grip-vertical"
                      class="fa-fw"
                    /></span>
                    <div
                      :class="$style.techinput_level"
                      :title="'Level '+e.level"
                    >
                      <span
                        v-for="index in e.level"
                        :key="index"
                        :class="$style.techinput_level__dot"
                        :style="'background: '+getColor(index-1)"
                      ><br></span>
                      <span
                        :class="$style.techinput_level__dot__line"
                        :style="'background:'+e.color"
                      >{{ e.line+1 }}</span>
                    </div>
                    <a
                      :style="!e.title ? 'color:red' : ''"
                      @click.prevent="theElderScrolls(e.line)"
                    >{{ e.title || 'Gruppe ohne Titel' }}</a>
                  </div>
                  <div>
                    <div
                      v-for="child in e.children"
                      :key="child.id"
                      :class="$style.tocEntry"
                    >
                      <div
                        :class="$style.techinput_level"
                        :title="'Level '+child.level"
                      >
                        <span
                          v-for="index in child.level"
                          :key="index"
                          :class="$style.techinput_level__dot"
                          :style="'background: '+getColor(index-1)"
                        ><br></span>
                        <span
                          :class="$style.techinput_level__dot__line"
                          :style="'background:'+child.color"
                        >{{ child.line+1 }}</span>
                      </div>
                      <a
                        :style="!child.title ? 'color:red' : ''"
                        @click.prevent="theElderScrolls(child.line)"
                      >{{ child.title || 'Gruppe ohne Titel' }}</a>
                    </div>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </template>
          <div
            v-else
            style="margin-top: 10px"
          >
            <p
              v-if="!directories.marked.length"
              class="text-center"
            >
              Keine markierten Elemente im Formular gefunden
            </p>
            <div
              v-for="e in directories.marked"
              :key="e.id"
              :class="$style.flagsAndComments"
            >
              <fa
                icon="flag"
                :class="[(e.flagged ? $style.active : null), 'fa-fw']"
                :title="e.flagged ? 'Element markiert' : 'Element nicht markiert'"
              />
              &nbsp;
              <fa
                icon="comment"
                :class="[(!!e.comment ? $style.active : null), 'fa-fw']"
                :title="e.comment"
              />
              &nbsp;
              <span
                :class="$style.techinput_level__dot__line"
                :style="'background: '+getColor(e.level)"
              >{{ e.line+1 }}</span>
              &nbsp;
              <a
                :style="!e.title ? 'color:red' : ''"
                @click.prevent="theElderScrolls(e.line)"
              >{{ e.title || 'Gruppe ohne Titel' }}</a>
            </div>
          </div>
        </rg-dropdown>
        <rg-dropdown :width="330">
          <template #trigger>
            <rg-button
              icon="eye"
              style="margin-right:5px"
              title="Ansicht"
              :notice="(
                settings.view.current === 'marked' ? '•' : settings.view.current === 'limit' ? settings.view.options.limit.count : null
              )"
            />
          </template>
          <p><strong>Formularansicht</strong> wählen</p>
          <rg-button
            v-for="(view, key) in settings.view.options"
            :key="key"
            class="level"
            :title="view.help"
            :type="{'is-primary': settings.view.current === key}"
            :style="(key === 'limit' ? 'padding: 3px 3px 3px 10px' : '')"
            @click="settings.view.set(key)"
          >
            <span>
              <span :style="(settings.view.current !== key ? 'opacity:.2' : '')">
                <fa
                  class="fa-fw icon-left"
                  :icon="(settings.view.current === key ? 'check' : 'circle')"
                />
              </span>{{ view.title }}
            </span>
            <span
              v-if="key === 'limit'"
              :class="[$style.limit_select, (settings.view.current === 'limit' ? $style.limit_active : null)]"
              @click.stop
            >
              <rg-button
                v-for="count in [20, 40, 100, 200]"
                :key="count"
                :class="[
                  (settings.view.options.limit.count === count ? $style.active : null),
                  {'custom': settings.view.options.limit.count === count},
                ]"
                :label="count.toString()"
                @click="settings.view.set(null, count)"
              />
            </span>
          </rg-button>
          <hr>
          <!-- <a
            :href="'https://rep.guide/de/admin/preview?version='+version"
            target="_blank"
            rel="noopener noreferrer"
          >
            <rg-button
              icon-left="eye"
              icon-right="external-link-alt"
              label="Rep.Guide Vorschau"
              title="Rep.Guide Vorschau (neuer Tab)"
            />
          </a> -->
        </rg-dropdown>
        <rg-dropdown
          :width="250"
          :height="550"
          type=""
          triggertitle="Sonderzeichen kopieren"
          icon="paragraph"
        >
          <p><strong>Sonderzeichen</strong> kopieren</p>
          <hr>
          <template v-for="(group) in specialChars">
            <p :key="group.title">
              {{ group.title }}
            </p>
            <div
              :key="group.title+'-charizard'"
              :class="$style.special_select"
            >
              <rg-button
                v-for="char in group.chars"
                :key="char.title"
                v-clipboard="() => char.value"
                v-clipboard:success="clipboardSuccessHandler"
                class="level"
                :title="char.title"
                :label="char.value"
              />
            </div>
          </template>
        </rg-dropdown>
      </div>
      <div v-else>
        Formular wird geladen…
      </div>
    </navigation>
    <main>
      <timer
        v-if="versionmeta && versionmeta.id"
        :reference="String(versionmeta.id)"
      />
      <section>
        <div
          v-if="versionOutdated"
          style="color:red"
        >
          <strong>
            <strong v-if="newestEditorVersion === 'v2'">Für diese Maschine gibt es eine Version, die mit dem neuen Editor erstellt wurde. Um die Datenkonsistenz zu gewährleisten, wird das Bearbeiten von allen Versionen dieser Maschine blockiert.</strong>
            <span v-else-if="newestLive.isCurrentVersion">Diese Version kann nicht gespeichert werden, da sie bereits am {{ new Date(newestLive.publishedat,).toLocaleString() }} publiziert wurde.</span>
            <span v-else>Diese Version kann nicht gespeichert werden, da es eine neuere Version gibt, die bereits publiziert wurde: {{ newestLive.id }} ({{ new Date(newestLive.publishedat,).toLocaleString("de-DE") }})</span>
          </strong>
        </div>
      </section>
      <section>
        <h1>
          Dateneingabe <router-link :to="'/machines/'+machine.id+'/tech'">
            {{ machine.title }}
          </router-link>
        </h1>
        <br>
        <div
          class="rg-grid"
          style="margin-bottom: 100px;"
        >
          <widget
            class="w4"
            title="Maschinendaten"
          >
            <template #meta>
              <span :style="(machine.hastechdata ? 'color:#1575f7' : 'opacity:.5')"><fa
                class="fa-fw fa-lg"
                icon="file"
              /></span>
              <span :style="(machine.hasparts ? 'color:#1575f7' : 'opacity:.5')"><fa
                class="fa-fw fa-lg"
                icon="cogs"
              /></span>
              <span :style="(machine.hasserviveplan ? 'color:#1575f7' : 'opacity:.5')"><fa
                class="fa-fw fa-lg"
                icon="clipboard-check"
              /></span>
            </template>
            <template #data>
              <div
                class="level"
                style="margin-bottom:10px"
              >
                <strong style="font-size: 1.2em">{{ machine.title || 'Lädt…' }}</strong>
              </div>
              <div
                class="level"
                style="margin-bottom:10px"
              >
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="toggle-on"
                />&nbsp;Status</strong>
                <strong style="font-size: 1.2em">{{ machine.status !== undefined ? '"'+machine.status+'"' : 'Lädt…' }}</strong>
              </div>
              <div
                class="level"
                style="margin-bottom:10px"
              >
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="eye"
                />&nbsp;Kundenansicht</strong>
                <a
                  :href="'https://rep.guide/de/machine/'+machine.slug"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong style="font-size: 1.2em">Zu Rep.Guide<span style="opacity:.5; margin-left:5px"><fa
                    icon="external-link-alt"
                    class="fa-fw"
                  /></span></strong>
                </a>
              </div>
              <div
                class="level"
                style="margin-bottom:10px"
              >
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  :icon="['fab', 'gitlab']"
                />&nbsp;Gitlab Ticket</strong>
                <router-link
                  v-if="!machine.gitlabticket"
                  :to="'/machines/'+machine.id+'/type'"
                >
                  <strong style="font-size: 1.2em">Verknüpfen<span
                    title="Noch keine ID hinterlegt"
                    style="opacity:.5"
                  ><fa
                    icon="exclamation-triangle"
                    class="fa-fw"
                  /></span></strong>
                </router-link>
                <a
                  v-else
                  :href="'https://frixlab.fricke.de/innovationlab/repguide/redaktion/-/issues/'+machine.gitlabticket"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong style="font-size: 1.2em"><span style="opacity:.5"><fa
                    icon="hashtag"
                    class="fa-fw"
                  /></span>{{ machine.gitlabticket }}<span style="opacity:.5; margin-left:5px"><fa
                    icon="external-link-alt"
                    class="fa-fw"
                  /></span></strong>
                </a>
              </div>
              <div class="level">
                <strong :style="!language.id ? 'color:red' : ''"><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="globe"
                />&nbsp;Eingabesprache</strong>
                <span style="font-size: 1.2em">
                  <select
                    v-if="languages"
                    v-model="language.id"
                    class="rg-select"
                  >
                    <option
                      v-for="option in languages"
                      :key="option.id"
                      :value="option.id"
                      :selected="option.id === language.id"
                    >{{ option.language_name }} ({{ option.language_code }})</option>
                  </select>
                </span>
              </div>
            </template>
          </widget>
          <widget class="w4">
            Aktuelle Version
            <template #data>
              <div
                class="level"
                style="margin-bottom:10px"
              >
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="hashtag"
                />&nbsp;ID</strong>
                <strong style="font-size: 1.2em">{{ versionmeta.id }}</strong>
              </div>
              <div
                class="level"
                style="margin-bottom:10px"
              >
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="user"
                />&nbsp;Erstellt von</strong>
                <span style="font-size: 1.2em">{{ versionmeta.author || 'Autor unbekannt' }}</span>
              </div>
              <div class="level">
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="calendar-alt"
                />&nbsp;Letzte Änderung</strong>
                <span style="font-size: 1.2em">{{ versionmeta.timestamp ? new Date(versionmeta.timestamp).toLocaleString() : 'vor dem 20.4.2020' }}</span>
              </div>
              <div class="level">
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="comment"
                />&nbsp;Kommentar</strong>
              </div>
              <div class="level">
                <span style="font-size: 1.2em">
                  <textarea
                    v-model="comment"
                    cols="50"
                    rows="5"
                  />
                </span>
              </div>
            </template>
          </widget>
          <widget class="w4">
            Statistiken
            <template #data>
              <div
                class="level"
                style="margin-bottom:10px"
              >
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="folder"
                />&nbsp;Gruppen / Untergruppen</strong>
                <span style="font-size: 1.2em"><strong>{{ stats.groups }}</strong><span>/{{ stats.subgroups }}</span></span>
              </div>
              <div
                class="level"
                style="margin-bottom:10px"
              >
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="server"
                />&nbsp;Technische Daten</strong>
                <span style="font-size: 1.2em"><strong>{{ stats.techdata }}</strong><span style="opacity:.5">/{{ stats.all }}</span></span>
              </div>
              <div
                class="level"
                style="margin-bottom:10px"
              >
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="wrench"
                />&nbsp;Anzugswerte</strong>
                <span style="font-size: 1.2em"><strong>{{ stats.torque }}</strong><span style="opacity:.5">/{{ stats.all }}</span></span>
              </div>
              <div class="level">
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="oil-can"
                />&nbsp;Füllmengen</strong>
                <span style="font-size: 1.2em"><strong>{{ stats.filling }}</strong><span style="opacity:.5">/{{ stats.all }}</span></span>
              </div>
            </template>
          </widget>
        </div>

        <datalist
          v-if="settings.unitShow"
          id="units"
        >
          <option
            v-for="unit in units"
            :key="unit"
          >
            {{ unit }}
          </option>
        </datalist>

        <p
          v-if="settings.view.current === 'limit'"
          class="text-center"
        >
          Dein Formular ist auf <strong>{{ settings.view.options.limit.count }}</strong> Elemente limitiert.
          Aktuell {{ settings.view.elementsHidden() === 1 ? 'wird' : 'werden' }}
          <span v-if="!settings.view.elementsHidden()"><strong>keine</strong> Elemente ausgeblendet.</span>
          <span v-else><strong>{{ settings.view.elementsHidden() + '/' + inputArray.length }}</strong> Elementen ausgeblendet.</span>

          <br>
          Limit anpassen:
          <span
            v-for="(count, i) in [20, 40, 100, 200].filter(c => c !== settings.view.options.limit.count)"
            :key="count"
          >
            <a @click="settings.view.set('limit', count)">{{ count }}</a>
            <span v-if="i < 2"> • </span>
          </span>
          oder
          <a @click="settings.view.set('all')">alle anzeigen</a>
        </p>
        <p
          v-if="settings.view.current === 'marked'"
          class="text-center"
        >
          Dein Formular zeigt nur <strong>markierte und/oder kommentierte Elemente</strong> an. <br>
          <strong>Folgende Funktionen sind deaktiviert:</strong> Elemente bewegen, hinzufügen oder löschen, sowie das gruppenweite Festlegen von Kategorien. <br>
          Wieder <a @click="settings.view.set('all')">alle anzeigen</a>.
        </p>
        <p
          v-if="!inputForm.length"
          style="padding: 200px 0; text-align: center;"
        >
          <span v-if="inputArray.length">
            In der aktuellen Ansicht ist das Formular leer.
            Schalte die Ansicht auf <a @click="settings.view.set('all')">alle Elemente</a>, um alle {{ inputArray.length }} Elemente anzuzeigen.
          </span>
          <span v-else>
            Das Formular {{ loading ? 'lädt…' : ' ist leer' }}
          </span>
        </p>

        <div
          v-if="!language.id"
          class="text-center"
          style="display:block; gap: 5px; color:red"
        >
          <p>
            <strong>Bitte zunächst die Sprache wählen, in der die Daten eingegeben werden.</strong>
          </p>
          <strong><fa
            class="fa-fw"
            style="opacity:.5;"
            icon="globe"
          />&nbsp;Eingabesprache</strong>
          <span style="font-size: 1.2em">
            <select
              v-if="languages"
              v-model="language.id"
              style="display:inline; margin-left:5px;"
              class="rg-select"
            >
              <option
                v-for="option in languages"
                :key="option.id"
                :value="option.id"
                :selected="option.id === language.id"
              >{{ option.language_name }} ({{ option.language_code }})</option>
            </select>
          </span>
        </div>
        <transition-group
          :style="!language.id ? 'opacity: .5; pointer-events: none; filter: saturate(0); user-select: none' : ''"
          tag="div"
          :class="[$style.techinput_container, (settings.view.current === 'marked' ? $style.hide_control : null)]"
          name="flip"
        >
          <section
            v-for="(element, index) in inputForm"
            :key="element.id"
            :class="$style.techinput_wrap"
            :style="'--level: '+(element.level * 50)+'px'"
          >
            <rg-element-level :level="element.level" />
            <component
              :is="element.is"
              :ref="element.line"
              :index="index"
              :element="element"
              :prevent="settings.view.prevent"
              :selected="selected === element.line"
              :replace-units="settings.unitReplace"
              @select="selected = $event"
              @move="move(element, $event)"
              @copy="copy(element, $event)"
              @create="create(element, $event)"
              @remove="remove(element, $event)"
              @set-category="setCategory(element, $event)"
              @set-comment="element.showComment = $event"
              @set-unit="element.unit = $event"
            />
            <transition name="opacity-basic">
              <rg-control-bubble
                v-if="settings.view.current !== 'marked' && element.line < inputArray.length"
                @create="create(element, $event, true)"
                @paste="paste(element)"
              />
            </transition>
          </section>
        </transition-group>

        <div
          v-if="language.id"
          style="text-align:center"
        >
          <br>
          <rg-button
            :disabled="!$store.state.copyPasta"
            tabindex="-1"
            type="is-primary"
            icon-left="copy"
            label="Einfügen"
            :title="!$store.state.copyPasta.length ? '' : ($store.state.copyPasta[0].is === 'group' ? 'Gruppe \'' : 'Attribut \'') + $store.state.copyPasta[0].title + '\' hier einfügen'"
            @click="paste()"
          />
          &nbsp;
          <rg-button
            type="is-primary"
            icon-left="file"
            @click="create(inputArray.length+1, 'attribute', true)"
          >
            Attribut
          </rg-button>
          &nbsp;
          <rg-button
            type="is-primary"
            icon-left="folder"
            @click="create(inputArray.length+1, 'group', true)"
          >
            Gruppe
          </rg-button>
        </div>
        <br><br><br>

        <rg-button
          type="is-primary"
          style="z-index:99999; position: fixed; left: 25px; bottom: 25px;"
          icon="question"
          @click="showModal = !showModal"
        />
        <transition name="opacity">
          <section
            v-if="showModal"
            style="width: 1000px;"
            :class="[$style.modal, 'card']"
          >
            <div class="rg-grid">
              <div class="w6">
                <h2>Einstellungen & Tipps</h2>
                <br>
                <div class="level">
                  <strong style="font-size:1.1em">Reihenfolge der farblichen Einrückung</strong>
                  <strong style="font-size:1.5em">
                    <span
                      v-for="i in 5"
                      :key="i"
                      :style="'color:'+getColor(i-1)"
                    >{{ i }}&nbsp;</span>
                    <span style="opacity:.5">…</span>
                  </strong>
                </div>
                <br><hr style="opacity:.5"><br>
                <div class="level">
                  <strong>
                    <abbr title="Aktiviert bzw. deaktiviert die Shortcuts">
                      <fa
                        class="fa-fw"
                        style="opacity:.5"
                        icon="question-circle"
                      />
                    </abbr>
                    Shortcuts für das Formular benutzen
                  </strong>
                  <label class="switch">
                    <input
                      v-model="settings.shortcutsEnabled"
                      type="checkbox"
                      @change="toggleShortcuts($event)"
                    ><span class="slider" />
                  </label>
                </div>
                <br>
                <div class="level">
                  <strong>
                    <abbr title="Die Formatierung und Schreibweise von Einheiten wird automatisch geprüft und ggf. korrigiert.">
                      <fa
                        class="fa-fw"
                        style="opacity:.5"
                        icon="question-circle"
                      />
                    </abbr>
                    Einheiten automatisch anpassen / ersetzen
                  </strong>
                  <div>
                    <label class="switch">
                      <input
                        v-model="settings.unitReplace"
                        type="checkbox"
                      ><span class="slider" />
                    </label>
                  </div>
                </div>
                <br>
                <div class="level">
                  <strong>
                    <abbr title="Der Klick in das Einheiten-Feld öffnet ein Dropdown mit Vorschlägen.">
                      <fa
                        class="fa-fw"
                        style="opacity:.5"
                        icon="question-circle"
                      />
                    </abbr>
                    Liste mit Einheiten anzeigen
                  </strong>
                  <div>
                    <label class="switch">
                      <input
                        v-model="settings.unitShow"
                        type="checkbox"
                      ><span class="slider" />
                    </label>
                  </div>
                </div>
                <br>
                <div class="level">
                  <strong>
                    <abbr title="Verschiebst du die Auswahl oder ein Element wird die Ansicht 'mitgescrollt'. So bleibt das aktive Element immer im sichtbaren Bereich der Seite.">
                      <fa
                        class="fa-fw"
                        style="opacity:.5"
                        icon="question-circle"
                      />
                    </abbr>
                    Bei Bewegung automatisch scrollen
                    <abbr title="RFC: Lässt sich diese Funktion noch verbessern?">
                      <fa
                        class="fa-fw"
                        icon="comment"
                      />
                    </abbr>
                  </strong>
                  <div>
                    <label class="switch">
                      <input
                        v-model="settings.scrollOnMove"
                        type="checkbox"
                      ><span class="slider" />
                    </label>
                  </div>
                </div>
                <br><hr style="opacity:.5"><br>

                <h3>
                  <fa
                    class="fa-fw"
                    icon="spell-check"
                  />&nbsp;Rechtschreibprüfung aktivieren
                </h3>
                <br>
                <strong><fa
                  style="opacity:.5"
                  :icon="['fab', 'chrome']"
                  class="fa-fw"
                />&nbsp;Chrome:</strong>
                Einstellungen<fa
                  style="opacity:.5"
                  icon="chevron-right"
                  class="fa-fw"
                />Erweitert<fa
                  style="opacity:.5"
                  icon="chevron-right"
                  class="fa-fw"
                />Sprachen<br><br>
                <strong><fa
                  style="opacity:.5"
                  :icon="['fab', 'firefox']"
                  class="fa-fw"
                />&nbsp;Firefox:</strong>
                Einstellungen<fa
                  style="opacity:.5"
                  icon="chevron-right"
                  class="fa-fw"
                />Allgemein<fa
                  style="opacity:.5"
                  icon="chevron-right"
                  class="fa-fw"
                />Sprache<br><br>
                <strong><fa
                  style="opacity:.5"
                  :icon="['fab', 'edge']"
                  class="fa-fw"
                />&nbsp;Edge:</strong>
                Einstellungen<fa
                  style="opacity:.5"
                  icon="chevron-right"
                  class="fa-fw"
                />Sprachen<br><br>
                <strong><fa
                  style="opacity:.5"
                  :icon="['fab', 'safari']"
                  class="fa-fw"
                />&nbsp;Safari:</strong>
                Bearbeiten<fa
                  style="opacity:.5"
                  icon="chevron-right"
                  class="fa-fw"
                />Rechtschreibprüfung und Grammatik
              </div>

              <div class="w6">
                <h2>Übersicht der Kurzbefehle</h2>
                <br>
                <div class="level">
                  <span>Attribut hinzufügen (Im Inputfeld)</span><kbd><strong>ENTER</strong></kbd>
                </div>
                <br>
                <div class="level">
                  <span>Gruppe hinzufügen (Im Inputfeld)</span><kbd><strong>ALT&nbsp;+&nbsp;ENTER</strong></kbd>
                </div>
                <br>
                <h3>
                  <fa
                    class="fa-fw"
                    icon="arrows-alt"
                  />&nbsp;Bewegung im Formular
                </h3>
                <br>
                <div class="level">
                  <span>Auswahl bewegen</span>
                  <kbd>
                    <strong>ALT <span style="opacity:.5">+</span> <fa icon="arrow-up" /> <span style="opacity:.5">/</span> <fa icon="arrow-down" /></strong>
                  </kbd>
                </div>
                <br>
                <div class="level">
                  <span>Element bewegen</span>
                  <kbd><strong>ALT <span style="opacity:.5">+</span> SHIFT <span style="opacity:.5">+</span>&nbsp;
                    <fa icon="arrow-up" /> <span style="opacity:.5">/</span> <fa icon="arrow-down" /> <span style="opacity:.5">/</span> &nbsp;
                    <fa icon="arrow-left" /> <span style="opacity:.5">/</span> <fa icon="arrow-right" />
                  </strong></kbd>
                </div>
                <br>
                <div class="level">
                  <span>Zwischen Formularfeldern springen</span>
                  <kbd><strong><span style="opacity:.5">(</span> SHIFT <span style="opacity:.5">) +</span> TAB</strong></kbd>
                </div>
                <br>

                <h3>
                  <fa
                    class="fa-fw"
                    icon="edit"
                  />&nbsp;Elemente bearbeiten
                </h3>
                <br>
                <div class="level">
                  <span>HTML öffnen / schließen</span><kbd><strong>ALT <span style="opacity:.5">+</span> M</strong></kbd>
                </div>
                <br>
                <div class="level">
                  <span>Kommentar öffnen / schließen</span><kbd><strong>ALT <span style="opacity:.5">+</span> K</strong></kbd>
                </div>
                <br>
                <div class="level">
                  <span>Element markieren</span><kbd><strong>ALT <span style="opacity:.5">+</span> 1</strong></kbd>
                </div>
                <br>
                <div class="level">
                  <span>Kategorie eines Attributes festlegen</span>
                  <kbd><strong>ALT <span style="opacity:.5">+</span> T <span style="opacity:.5">/</span>&nbsp;
                    A <span style="opacity:.5">/</span> F</strong>
                  </kbd>
                </div>
                <br>
                <div class="level">
                  <span style="color:red">Element löschen</span>
                  <kbd><strong>ALT <span style="opacity:.5">+</span> SHIFT <span style="opacity:.5">+</span> ⌫</strong></kbd>
                </div>
              </div>
            </div>
            <rg-button
              style="position: absolute; top: 15px; right: 15px;"
              round
              icon="times"
              @click="showModal = false"
            />
          </section>
        </transition>
        <transition name="opacity">
          <section
            v-if="recovery.show"
            :class="[$style.modal, 'card']"
          >
            <h3>JSON kopieren</h3>
            <textarea
              style="width: 100%"
              rows="10"
              :value="recovery.json"
            />
            <div style="text-align:right">
              <br>
              <rg-button
                label="Schließen"
                @click="recovery.show = false"
              />
            </div>
          </section>
        </transition>
      </section>
    </main>
  </div>
</template>

<script>
import Vue from "vue";
import { ref, computed, onUnmounted, watch } from "@vue/composition-api";
import {
  levelColors,
  getColor,
  generateStatus,
  units,
  specialChars,
} from "@/helper.js";
import { validate, rules } from "@/validate.js";
import draggable from "vuedraggable";
import superagent from "superagent";
import { backendUrl, altBackendUrl } from "@/constants.js";
import { uid } from "uid";

import controlBubble from "@/components/elements/tech_input/rg-control-bubble";
import elementLevel from "@/components/elements/tech_input/rg-element-level";
import attribute from "@/components/elements/tech_input/rg-attribute/_index";
import group from "@/components/elements/tech_input/rg-group";
import dropdown from "@/components/elements/rg-dropdown";
import widget from "@/components/elements/rg-widget";
import timer from "@/components/elements/rg-timer";

export default {
  name: "TechdataInput",
  components: {
    "rg-dropdown": dropdown,
    "rg-control-bubble": controlBubble,
    "rg-element-level": elementLevel,
    attribute,
    group,
    draggable,
    widget,
    timer
  },
  beforeRouteLeave(to, from, next) {
    next(window.confirm("Formular wirklich verlassen?"));
  },
  setup(props, context) {
    onUnmounted(() => {
      disableShortcuts();
      clearTimeout(autosavetimer);
    });

    const id = context.root.$route.params.id;
    const cssLevelVars = computed(() =>
      levelColors.map((c, i) => `--level-${i + 1}:${c};`).join("")
    );

    let version = context.root.$route.params.version;
    let versionmeta = ref("");
    let loading = ref(true);
    let machine = ref([]);
    let inputArray = ref([]);
    let childrenCount = ref(null);
    let showModal = ref(false);
    let recovery = ref({ show: false, json: null, start: false });
    let selected = ref(-1);
    let selectedNew = ref(null);
    let autosave = ref(false);
    let autosavetimer = ref(null);
    let audit = ref([]);
    let languages = ref([]);
    let language = ref({});
    let comment = ref(null);
    let versionOutdated = ref(false);
    let newestEditorVersion = ref(null);
    let newestLive = ref({
      isCurrentVersion: null,
      id: null,
      publishedat: null,
    })

    watch(
      () => selected.value,
      (newVal, oldVal) => {
        if (newVal !== oldVal) selectedNew.value = newVal + 1;
      }
    );

    const stats = computed(() => {
      let tc = 0,
        tq = 0,
        fl = 0,
        gp = 0,
        sgp = 0;
      inputArray.value.forEach((e) => {
        if (e.category === "Technische Daten") {
          tc++;
        }
        if (e.category === "Anzugswerte") {
          tq++;
        }
        if (e.category === "Füllmengen") {
          fl++;
        }
        if (e.is === "group" && e.level === 0) {
          gp++;
        } else if (e.is === "group") {
          sgp++;
        }
      });
      return {
        techdata: tc,
        torque: tq,
        filling: fl,
        groups: gp,
        subgroups: sgp,
        all: tc + fl + tq,
      };
    });
    const settings = ref({
      shortcutsEnabled: false,
      scrollOnMove: true,
      unitReplace: true,
      unitShow: true,
      view: {
        current: "limit",
        options: {
          all: {
            title: "Alle Elemente anzeigen",
            help: "Das komplette Formular wird angezeigt.",
          },
          limit: {
            title: "Limitieren auf",
            help: "EINGABE: Limitiert das Formular auf die untersten 25 Elemente.",
            count: 20,
          },
          marked: {
            title: "Nur markierte Elemente",
            help: "PRÜFUNG: Zeigt nur markierte und/oder kommentierte Elemente an.",
            blacklist: [
              "copy:cut",
              "paste",
              "move",
              "create",
              "remove:group",
              "batch",
            ],
          },
        },
        set: (key, count) => {
          settings.value.view.current = ["all", "limit", "marked"].includes(key)
            ? key
            : settings.value.view.current;
          if ([20, 40, 100, 200].includes(count)) {
            settings.value.view.options.limit.count = count;
          }
        },
        lineVisible: (line) => {
          return inputForm.value.find((e) => e.line === line);
        },
        elementsHidden: () => {
          return settings.value.view.current === "limit"
            ? Math.max(
                0,
                inputArray.value.length -
                  settings.value.view.options.limit.count
              )
            : 0;
        },
        prevent: computed(() => {
          return (
            settings.value.view.options[settings.value.view.current]
              .blacklist || []
          );
        }),
      },
    });
    const directories = ref({
      current: "groups",
      groups: computed(() => {
        let list = [];
        inputArray.value
          .filter((e) => e.is === "group")
          .forEach((element) => {
            if (list.length && list[list.length - 1].level < element.level) {
              list[list.length - 1].children.push(
                directories.value.generateEntry({ ...element })
              );
            } else {
              list.push(directories.value.generateEntry({ ...element }));
            }
          });
        return list;
      }),
      marked: computed(() => {
        let list = [];
        inputArray.value
          .filter((e) => e.flagged || e.comment)
          .forEach((element) => {
            list.push(directories.value.generateEntry({ ...element }));
          });
        return list;
      }),
      handleDrops: (e) => {
        const el = inputArray.value.find(
          (obj) => obj.id === directories.value.groups[e.oldIndex].id
        );
        let distance = Math.abs(e.oldIndex - e.newIndex);
        while (distance--) {
          move(el, e.oldIndex > e.newIndex ? "up" : "down");
        }
      },
      generateEntry(element) {
        return { ...element, color: getColor(element.level), children: [] };
      },
      set: (key) => {
        directories.value.current = ["groups", "marked"].includes(key)
          ? key
          : directories.value.current;
      },
    });

    const cleanUp = () => {
      const CONFIRM_PROMPT = `
        Durch diese Aktion werden alle leeren Attribute aus der Eingabe gelöscht.
        Ein Attribut ist leer, wenn es keinen Titel, Wert, Einheit oder Kommentar hat und nicht markiert ist.

        Hinweis: Leere Gruppen werden bei dieser Aktion nicht gelöscht.

        Eingabe jetzt bereinigen?
      `

      if (window.confirm(CONFIRM_PROMPT)) {
        inputArray.value = inputArray.value.filter((e) => {
          const keys = ["title", "unit", "value", "comment"];
          return e.is === 'group' || keys.some((key) => e[key]?.trim()) || e.flagged
        })
      }
    }

    let inputForm = computed(() => {
      inputArray.value.forEach((e, i) => {
        e.line = i;
      });
      if (settings.value.view.current === "limit") {
        if (selected.value >= 0) {
          return inputArray.value.slice(
            Math.max(
              0,
              selected.value - settings.value.view.options.limit.count / 2
            ),
            Math.min(
              inputArray.value.length,
              selected.value + settings.value.view.options.limit.count / 2 + 1
            )
          );
        } else {
          return inputArray.value.slice(
            settings.value.view.options.limit.count * -1
          );
        }
      } else if (settings.value.view.current === "marked") {
        return inputArray.value.filter((el) => el.flagged || el.comment);
      } else {
        return inputArray.value;
      }
    });

    getLanguages();

    const machineQuery = `query Query($machineId: Int) {
      Machine(id: $machineId) {
        id
        slug
        title
        hasTechdata
        hasServiceplan
        hasParts
        status
        gitlabticket
      }
    }`;

    superagent
      .post(backendUrl)
      .send({
        query: machineQuery,
        variables: {
          machineId: parseInt(id),
        },
      })
      .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
      .then((res) => {
        machine.value = res.body?.data?.Machine;

        // FIXME: load unpublished WIP Version
        const machineWIPQuery = `query Query($machineWiPsMachineId: Int!) {
          MachineWIPs(machineID: $machineWiPsMachineId) {
            id
            author
            machine_data
            machine_id
            publishedat
            createdat
            updatedat
            source_language_id
            wip_comment
          }
          NewestWIPEditorVersion(machineID: $machineWiPsMachineId)
        }`;

        superagent
          .post(altBackendUrl)
          .send({
            query: machineWIPQuery,
            variables: {
              machineWiPsMachineId: parseInt(machine.value.id),
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((versionList) => {
            const version =
              versionList.body?.data?.MachineWIPs?.find(
                (v) => v.id == context.root.$route.params.version
              ) || [];

            for(const version of versionList.body?.data?.MachineWIPs) {
              if ((!newestLive.value.publishedat && version.publishedat) || (newestLive.value.publishedat && version.publishedat && newestLive.value.publishedat < version.publishedat))
                newestLive.value.isCurrentVersion = version.id == context.root.$route.params.version,
                newestLive.value.id = version.id,
                newestLive.value.publishedat = version.publishedat
            }

            if (versionList.body?.data?.NewestWIPEditorVersion === 'v2') {
              newestEditorVersion.value = 'v2'
              versionOutdated.value = true
              Vue.$toast.open({
                message: `Veraltete Version: Du arbeitest an einer veralteten Version!
                  Für diese Maschine gibt es eine Version, die mit dem neuen Editor erstellt wurde. Um die Datenkonsistenz zu gewährleisten, wird das Bearbeiten von allen Versionen dieser Maschine blockiert.`,
                type: "error",
                icon: "exclamation",
                duration: 15_000
              });
            } else if (version.id <= newestLive.value.id) {
              newestEditorVersion.value = 'v1'
              versionOutdated.value = true
              Vue.$toast.open({
                message: `Veraltete Version: Du arbeitest an einer veralteten Version!
                  ${newestLive.value.isCurrentVersion ?
                    `Diese Version wurde bereits am ${new Date(newestLive.value.publishedat).toLocaleString()} veröffentlicht und kann daher nicht mehr gespeichert werden. Alle Änderungen gehen verloren.`
                    : `Es gibt bereit eine aktuellere Version (${newestLive.value.id}), welche am ${new Date(newestLive.value.publishedat).toLocaleString()} veröffentlicht wurde. Diese Version kann daher nicht gespeichert werden und alle Änderungen gehen verloren.`
                  }`,
                type: "error",
                icon: "exclamation",
                duration: 15_000
              });
            } else {
              newestEditorVersion.value = 'v1'
              versionOutdated.value = false
            }

            language.value = {
              ...language.value,
              id: version?.source_language_id,
            };

            comment.value = version?.wip_comment;

            if (version.machine_data?.data || version.machine_data.length) {
              flatten(version.machine_data.data || version.machine_data);
              enableShortcuts();
            } else {
              Vue.$toast.open({
                message: "Version korrupt",
                type: "error",
                icon: "exclamation",
              });
            }

            delete version.machine_data;

            versionmeta.value = version;
          });
      })
      .catch((error) => {
        Vue.$toast.open(generateStatus(error.status));
      });

    function getLanguages() {
      const gqQueryAppLanguages = `
      query Query {
        AppLanguages {
          language_name
          id
          language_code
        }
      }
      `;

      superagent
        .post(backendUrl)
        .send({
          query: gqQueryAppLanguages,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          languages.value = res.body.data.AppLanguages.sort((a, b) => {
            return a.language_name.localeCompare(b.language_name);
          });
        });
    }
    function move(e, dir) {
      if (settings.value.view.current === "marked") {
        Vue.$toast.open({
          message: "In der aktuellen Ansicht ist die Funktion nicht verfügbar",
          icon: "exclamation",
          type: "error",
        });
        return;
      }
      // finn lost about 1/69 of his brain cells writing this function
      if (!e || !["up", "right", "down", "left"].includes(dir)) {
        return;
      }
      const index = inputArray.value.indexOf(e);
      let include = 0,
        y = 0,
        x = 0;

      if (e.is === "group") {
        for (let i = index + 1; i < inputArray.value.length; i++) {
          if (inputArray.value[i].level <= e.level) {
            break;
          } else {
            include++;
          }
        }
      }

      // abort if trying to move element or group out of bounds
      if (dir === "down" && !inputArray.value[index + include + 1]) {
        return;
      }
      if (
        (index === 0 && dir === "up") ||
        (index === inputArray.value.length - 1 && dir === "down")
      ) {
        return;
      }
      if (
        (e.level === 0 && dir === "left") ||
        (e.level === 10 && dir === "right")
      ) {
        return;
      }

      if (dir === "up") {
        y--;
        if (e.is === "group" && inputArray.value[index - 1].level > e.level) {
          // if previous attr. is in a group
          for (let i = index - 1; i >= 0; i--) {
            if (inputArray.value[i].level === e.level) {
              break;
            } else {
              y--;
            }
          }
        }
      } else if (dir === "down") {
        y++;
        if (
          e.is === "group" &&
          inputArray.value[index + include + 1].level === e.level
        ) {
          // if next attr. is in a group
          for (
            let i = Math.min(inputArray.value.length - 1, index + include + 2);
            i <= inputArray.value.length - 1;
            i++
          ) {
            if (inputArray.value[i].level <= e.level) {
              break;
            } else {
              y++;
            }
          }
        }
      } else if (dir === "left") {
        x--;
      } else if (dir === "right") {
        x++;
      }

      if (y !== 0) {
        if (dir === "up") {
          for (let idx = 0; idx <= include; idx++) {
            array_move(inputArray.value, index + idx, index + idx + y);
          }
        } else if (dir === "down") {
          for (let idx = include; idx >= 0; idx--) {
            array_move(inputArray.value, index + idx, index + idx + y);
          }
        }
      }
      if (x !== 0) {
        for (let idx = 0; idx <= include; idx++) {
          inputArray.value[index + idx].level += x;
        }
      }
    }

    function remove(e, cascade = false, silent = false) {
      if (
        e.is === "group"
          ? permit(
              "remove:group",
              "In dieser Ansicht können keine Gruppen gelöscht werden"
            )
          : permit("remove")
      ) {
        let msg = null;
        if (e.is === "group") {
          msg =
            'Gruppe "' +
            e.title +
            (cascade
              ? '", sowie alle Unterelemente'
              : '" (Unterelemente bleiben erhalten)') +
            " wirklich löschen?\nDiese Aktion lässt sich nicht rückgängig machen";
        } else {
          msg =
            'Attribut "' +
            e.title +
            '" wirklich löschen?\nDiese Aktion lässt sich nicht rückgängig machen';
        }

        if (silent || window.confirm(msg)) {
          const index = inputArray.value.indexOf(e);
          let length = 1;

          for (let i = index + 1; i < inputArray.value.length; i++) {
            if (inputArray.value[i].level <= e.level || e.is === "attribute") {
              break;
            } else {
              cascade
                ? length++
                : (inputArray.value[i].level = Math.max(
                    1,
                    inputArray.value[i].level - 1
                  ));
            }
          }
          inputArray.value.splice(index, length);
          selected.value = getLine(getIndex(index) - 1);
        }
      }
    }

    function getIndex(line) {
      return inputForm.value.findIndex((e) => line === e.line);
    }

    function getLine(index) {
      return inputForm.value[index].line;
    }

    function countChildren(groupObj) {
      for (
        let i = inputArray.value.indexOf(groupObj) + 1;
        i < inputArray.value.length;
        i++
      ) {
        if (inputArray.value[i].level <= groupObj.level) {
          childrenCount.value = i - inputArray.value.length;
        }
      }
    }

    function setCategory(groupObj, category) {
      if (settings.value.view.current === "marked") {
        Vue.$toast.open({
          message: "In der aktuellen Ansicht ist die Funktion nicht verfügbar",
          icon: "exclamation",
          type: "error",
        });
        return;
      }
      for (
        let i = inputArray.value.indexOf(groupObj) + 1;
        i < inputArray.value.length;
        i++
      ) {
        if (inputArray.value[i].level <= groupObj.level) {
          break;
        } else {
          if (inputArray.value[i].is === "attribute") {
            inputArray.value[i].category = category;
          }
        }
      }
    }

    function create(e, type = "attribute", focus = true) {
      if (!permit("create")) {
        Vue.$toast.open({
          message: "In der aktuellen Ansicht ist die Funktion nicht verfügbar",
          icon: "exclamation",
          type: "error",
        });
        return;
      }
      let index = 0,
        level = 0,
        category = null;

      if (typeof e === "number") {
        index = e;
      } else {
        index = inputArray.value.indexOf(e);
        level = e.is === "group" ? e.level + 1 : e.level;
        category = e.category;
      }

      const element = generateElement(type, level, { category });
      index === inputArray.value.length
        ? inputArray.value.push(element)
        : inputArray.value.splice(index + 1, 0, element);

      if (focus) {
        Vue.nextTick(() => {
          if (settings.value.scrollOnMove) {
            theElderScrolls(index);
          }
          Vue.nextTick(() => {
            context.refs[index + 1][0].setFocus();
          });
        });
      }
    }

    function generateElement(type = "attribute", level = 0, defaults) {
      const TYPES = [
        "string",
        "markdown",
        "html",
        "widget",
        "seperator",
        "link",
        "media",
      ];
      if (!["attribute", "group"].includes(type)) {
        return;
      }
      const baseElement = {
        id: uid(),
        is: type,
        line: -1,
        level,
        title: defaults.title || "",
        flagged: defaults.flagged || false,
        comment: defaults.comment || "",
        showComment: false,
        nanoid: defaults.nanoid || null,
      };

      return type === "group"
        ? {
            ...baseElement,
            description: defaults.description || "",
            showDesc: false,
          }
        : {
            ...baseElement,
            type: TYPES.includes(defaults.type) ? defaults.type : "string",
            unit: defaults.unit || "",
            value: defaults.value || "",
            category: defaults.category || "Technische Daten",
          };
    }

    function flatten(initial, level = 0) {
      initial.forEach((element) => {
        if (Object.keys(element)[0] === "group") {
          inputArray.value.push(generateElement("group", level, element.group));
        } else if (Object.keys(element)[0] === "attribute") {
          inputArray.value.push(
            generateElement("attribute", level, element.attribute)
          );
        }
        if (element.group?.childs.length) {
          flatten(element.group.childs, level + 1);
        }
      });
    }

    function theElderScrolls(line = -1) {
      if (typeof line === "object") {
        line = inputForm.value.findIndex((e) => e.id === line.id);
      }
      if (line >= 0) selected.value = line;
      context.refs[selected.value]?.[0]?.$el?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    function jumpTo() {
      if (isNaN(selectedNew.value)) return;
      selectedNew.value = Math.max(selectedNew.value, 1);
      selectedNew.value = Math.min(selectedNew.value, inputArray.value.length);
      selected.value = selectedNew.value - 1;
      Vue.nextTick(() => {
        context.refs[selectedNew.value]?.[0]?.$el?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      });
    }

    function startRecovery() {
      saveHelper()
        .then((json) => {
          recovery.value.json = JSON.stringify(json);
          recovery.value.show = true;
        })
        .catch(() => {
          Vue.$toast.open({
            message:
              "Fehler bei der Konvertierung. Überprüfe das Formular und versuche es erneut",
            icon: "exclamation",
            type: "error",
          });
        });
    }

    const shortcuts = {
      handleEvent: (ev) => {
        if (ev.keyCode === 27) {
          selected.value = -1;
          showModal.value = false;
          recovery.value.show = false;
        }
        if (ev.metaKey && ev.key === "s") {
          ev.preventDefault();
          document.activeElement.blur();
          save();
        }
        if ((ev.metaKey && ev.key === "r") || ev.key === "F5") {
          ev.preventDefault();
          if (window.confirm("Möchtest du die Seite wirklich neu laden?")) {
            context.root.$router.go();
          }
        }
        if (ev.altKey && ev.key === "ArrowDown") {
          ev.preventDefault();
          if (
            selected.value === inputForm.value[inputForm.value.length - 1].line
          ) {
            return;
          }
          const nextLine =
            inputForm.value[
              inputForm.value.findIndex((e) => selected.value === e.line) + 1
            ].line;

          if (ev.shiftKey) {
            if (!permit("move")) {
              return;
            } else {
              document.activeElement.blur();
              move(
                inputForm.value.find((e) => selected.value === e.line),
                "down"
              );
            }
          }

          if (settings.value.scrollOnMove) {
            Vue.nextTick(() => {
              context.refs[nextLine]?.[0]?.$el?.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            });
          }
          Vue.nextTick(() => {
            context.refs[nextLine][0].setFocus();
            selected.value = nextLine;
          });
        }
        if (ev.altKey && ev.key === "ArrowUp") {
          ev.preventDefault();
          if (selected.value === inputForm.value[0].line) {
            return;
          }
          const nextLine =
            inputForm.value[
              inputForm.value.findIndex((e) => selected.value === e.line) - 1
            ].line;

          if (ev.shiftKey) {
            if (settings.value.view.current === "marked") {
              return;
            } else {
              document.activeElement.blur();
              move(
                inputForm.value.find((e) => selected.value === e.line),
                "up"
              );
            }
          }

          if (settings.value.scrollOnMove) {
            Vue.nextTick(() => {
              context.refs[nextLine]?.[0]?.$el?.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            });
          }
          Vue.nextTick(() => {
            context.refs[nextLine][0].setFocus();
            selected.value = nextLine;
          });
        }
        if (ev.altKey && ev.shiftKey && ev.key === "ArrowLeft") {
          ev.preventDefault();
          if (selected.value > -1) {
            move(inputArray.value[selected.value], "left");
          }
        }
        if (ev.altKey && ev.shiftKey && ev.key === "ArrowRight") {
          ev.preventDefault();
          if (selected.value > -1) {
            move(inputArray.value[selected.value], "right");
          }
        }
        if (ev.altKey && ev.shiftKey && ev.key === "Backspace") {
          ev.preventDefault();
          if (selected.value > -1) {
            remove(inputArray.value[selected.value]);
          }
        }
        if (ev.altKey && ev.keyCode === 77) {
          ev.preventDefault();
          document.activeElement.blur();
          if (
            selected.value > -1 &&
            inputArray.value[selected.value].is === "attribute"
          ) {
            inputArray.value[selected.value].type =
              inputArray.value[selected.value].type === "html"
                ? "string"
                : "html";
            if (inputArray.value[selected.value].type === "html") {
              Vue.nextTick(() => {
                context.refs[selected.value][0].setFocus("value");
              });
            }
          }
        }
        if (ev.altKey && ev.keyCode === 75) {
          ev.preventDefault();
          document.activeElement.blur();
          if (selected.value > -1) {
            Vue.nextTick(() => {
              context.refs[selected.value][0].toggleComment();
            });
          }
        }
        if (ev.altKey && ev.keyCode === 49) {
          ev.preventDefault();
          document.activeElement.blur();
          if (selected.value > -1) {
            inputArray.value[selected.value].flagged =
              !inputArray.value[selected.value].flagged;
          }
        }
        if (ev.altKey && ev.keyCode === 84) {
          ev.preventDefault();
          document.activeElement.blur();
          if (
            selected.value > -1 &&
            inputArray.value[selected.value].is === "attribute"
          ) {
            inputArray.value[selected.value].category = "Technische Daten";
          }
        }
        if (ev.altKey && ev.keyCode === 65) {
          ev.preventDefault();
          document.activeElement.blur();
          if (
            selected.value > -1 &&
            inputArray.value[selected.value].is === "attribute"
          ) {
            inputArray.value[selected.value].category = "Anzugswerte";
          }
        }
        if (ev.altKey && ev.keyCode === 70) {
          ev.preventDefault();
          document.activeElement.blur();
          if (
            selected.value > -1 &&
            inputArray.value[selected.value].is === "attribute"
          ) {
            inputArray.value[selected.value].category = "Füllmengen";
          }
        }
      },
    };

    function enableShortcuts() {
      settings.value.shortcutsEnabled = true;
      document.addEventListener("keydown", shortcuts);
    }

    function disableShortcuts() {
      settings.value.shortcutsEnabled = false;
      document.removeEventListener("keydown", shortcuts);
    }

    function toggleShortcuts(e) {
      e.target.checked ? enableShortcuts() : disableShortcuts();
    }

    const permit = (fn, message) => {
      const permission =
        !settings.value.view.options[
          settings.value.view.current
        ].blacklist?.includes(fn);
      if (!permission && message) {
        Vue.$toast.open({ message, icon: "exclamation", type: "error" });
      }
      return permission;
    };

    function array_move(arr, from, to) {
      while (from < 0) {
        from += arr.length;
      }
      while (to < 0) {
        to += arr.length;
      }
      if (to >= arr.length) {
        let k = to - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(to, 0, arr.splice(from, 1)[0]);
    }

    function copy(element, cut = false) {
      if (cut ? permit("copy:cut") : permit("copy")) {
        let data = [{ ...element }];
        delete data[0].id;

        if (element.is === "group") {
          for (
            let i = inputArray.value.indexOf(element) + 1;
            i < inputArray.value.length;
            i++
          ) {
            if (inputArray.value[i].level <= element.level) {
              break;
            } else {
              data.push({ ...inputArray.value[i] });
              delete data[data.length - 1].id;
            }
          }
        }
        context.root.$store.commit("setCopyPasta", data);
        Vue.$toast.open({
          message:
            (element.is === "group" ? "Gruppe " : "Element ") +
            (cut ? "ausgeschnitten" : "kopiert"),
          icon: "copy",
        });
        if (cut) {
          remove(element, true, true);
        }
      } else {
        Vue.$toast.open({
          message: "In der aktuellen Ansicht ist die Funktion nicht verfügbar",
          icon: "exclamation",
          type: "error",
        });
      }
    }

    function paste(element) {
      if (permit("paste")) {
        const index = element
          ? inputArray.value.indexOf(element) + 1
          : inputArray.value.length;
        let pasta = JSON.parse(
          JSON.stringify(context.root.$store.state.copyPasta)
        );
        let startLevel = element
          ? element.is === "group"
            ? element.level + 1
            : element.level
          : inputArray.value[inputArray.value.length - 1].level;
        let firstNoodle = pasta[0].level;

        pasta.forEach((noodle) => {
          noodle.id = uid();
          noodle.level = startLevel + noodle.level - firstNoodle;
          noodle.nanoid = null;
        });

        inputArray.value.splice(index, 0, ...pasta);
      } else {
        Vue.$toast.open({
          message: "In der aktuellen Ansicht ist die Funktion nicht verfügbar",
          icon: "exclamation",
          type: "error",
        });
      }
    }

    function normalizeText(string) {
      // NOTE: https://flaviocopes.com/non-printable-ascii-characters/
      // eslint-disable-next-line no-control-regex
      return string
        .toString()
        .replace(/\s/g, " ")
        .replace(/[\u0000-\u001F]/g, ""); //eslint-disable-line
    }

    function saveHelper() {
      return new Promise((resolve, reject) => {
        let temp = [];

        inputArray.value.forEach((e, index) => {
          if (e.is === "group") {
            temp.push({
              group: {
                title: normalizeText(e.title),
                description: normalizeText(e.description),
                comment: e.comment,
                childs: [],
                level: e.level,
                flagged: e.flagged,
                nanoid: e.nanoid,
                offset: 0,
              },
            });
          } else {
            for (let i = index; i >= 0; i--) {
              if (temp[i]?.group && temp[i]?.group.level > e.level) {
                continue;
              } // allows attrs to be set back by 2 levels
              if (temp[i]?.group && temp[i]?.group.level === e.level) {
                temp.push({
                  attribute: {
                    title: normalizeText(e.title),
                    unit:
                      e.type === "link" && e.unit === true
                        ? "external"
                        : normalizeText(e.unit),
                    value: normalizeText(e.value),
                    type: e.type,
                    comment: e.comment,
                    category: e.category,
                    level: e.level,
                    flagged: e.flagged,
                    nanoid: e.nanoid,
                  },
                });
                break;
              } else if (temp[i]?.group) {
                temp[i].group.childs.push({
                  attribute: {
                    title: normalizeText(e.title),
                    unit: normalizeText(e.unit),
                    value: normalizeText(e.value),
                    type: e.type,
                    comment: e.comment,
                    category: e.category,
                    level: e.level,
                    flagged: e.flagged,
                    nanoid: e.nanoid,
                  },
                });
                break;
              }
            }
          }
        });

        try {
          let final = [...temp];

          for (let i = temp.length - 1; i >= 0; i--) {
            if (temp[i].group?.level === 0) {
              continue;
            }
            for (let n = i - 1; n >= 0; n--) {
              if (
                temp[n].group?.level === temp[i].group?.level - 1 ||
                temp[n].group?.level === temp[i].attribute?.level - 1
              ) {
                final[n].group.childs.splice(
                  final[n].group.childs.length - final[n].group.offset,
                  0,
                  temp[i]
                ); //                                              exploding
                final[n].group.offset++;
                final.splice(i, 1); //                       is
                break;
              } //                                   brain
            } //                             my
          } //       holy        moly
          temp = final;
          resolve(final);
        } catch (sanity) {
          Vue.$toast.open({
            message: "Ich weiß doch selber nicht, was ich hier suche…",
            icon: "exclamation",
            type: "error",
          });
          reject(sanity);
        }
      });
    }

    function checkIfOutdated() {
      const machineWIPQuery = `query Query($machineWiPsMachineId: Int!) {
          MachineWIPs(machineID: $machineWiPsMachineId) {
            id
            author
            machine_data
            machine_id
            publishedat
            createdat
            updatedat
            source_language_id
            wip_comment
          }
          NewestWIPEditorVersion(machineID: $machineWiPsMachineId)
        }`;

       return superagent
          .post(altBackendUrl)
          .send({
            query: machineWIPQuery,
            variables: {
              machineWiPsMachineId: parseInt(machine.value.id),
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((versionList) => {
            newestLive.value = {}
            for(const version of versionList.body?.data?.MachineWIPs){
              if ((!newestLive.value.publishedat && versionmeta.value.publishedat) || (newestLive.value.publishedat && versionmeta.value.publishedat && newestLive.value.publishedat < versionmeta.value.publishedat))
                newestLive.value.isCurrentVersion = version.id == context.root.$route.params.version,
                newestLive.value.id = version.id,
                newestLive.value.publishedat = version.publishedat
            }

            versionmeta.value.id <= newestLive.value.id ?
              versionOutdated.value = true
              : versionOutdated.value = false

            if (versionList.body?.data?.NewestWIPEditorVersion === 'v2') {
              newestEditorVersion.value = 'v2'
              versionOutdated.value = true
            }

            return versionOutdated.value
          });
    }

    async function saveValidation() {
      const isOutdated = await checkIfOutdated()
      return new Promise((resolve, reject) => {
        audit.value = [];
        validate([...inputArray.value])
          .then((result) => {
            Vue.$toast.open({
              message: "Überprüfung abgeschlossen",
              icon: "info",
            });
            audit.value = result;
            if(isOutdated){
              audit.value.unshift({
                errors: [
                newestEditorVersion.value === 'v2'
                  ? "Für diese Maschine gibt es eine Version, die mit dem neuen Editor erstellt wurde. Um die Datenkonsistenz zu gewährleisten, wird das Bearbeiten von allen Versionen dieser Maschine blockiert."
                  : newestLive.value.isCurrentVersion
                    ? `Diese Version wurde bereits am ${new Date(newestLive.value.publishedat).toLocaleString()} publiziert und kann nicht mehr gespeichert werden.`
                    : `Es existiert eine neuere, bereits publizierte Version: ${newestLive.value.id} vom ${new Date(newestLive.value.publishedat).toLocaleString()}`],
                fatal: true,
                id: `pre-audit_${uid()}`,
                is: "version",
                line: -1,
                title: 'Versionsprüfung'
              })
              audit.value.errors++
              audit.value.fatal++
            }
            resolve(result);
          })
          .catch((error) => {
            Vue.$toast.open({
              message:
                "Vorgang abgebrochen: Schwerwiegender Fehler in Zeile " +
                error.line +
                "." +
                "Sollte die Zeile fehlerfrei sein, wende dich bitte an einen Admin.",
              icon: "exclamation",
              duration: 10000,
              type: "error",
            });
            console.error(error.error);
            console.error(error.element);
            reject(error);
          });
      });
    }

    async function save(newVersion = false) {
      const isOutdated = await checkIfOutdated()
      comment.value = comment?.value?.trim() || null;
      if (isOutdated) {
        Vue.$toast.open({
          message: newestLive.isCurrentVersion ? "Diese Version wurde bereits publiziert" : "Es existiert eine neuere, bereits publizierte Version",
          icon: "exclamation",
          type: "error",
        });
        return;
      }
      if (!language.value.id || language.value.id === 0) {
        Vue.$toast.open({
          message: "Bitte eine Sprache auswählen",
          icon: "exclamation",
          type: "error",
        });
        return;
      }
      saveValidation();
      clearTimeout(autosavetimer);
      if (machine.value.slug || version) {
        Vue.$toast.open({
          message: newVersion
            ? "Speichert neue Version"
            : "Speichert " + version,
          icon: "save",
        });

        saveHelper()
          .then((techdata) => {
            const WIPmutation = `mutation mMachineWIP($machineWipId: Int, $machineWipMachineId: Int, $machineWipMachineData: JSON, $machineWipAuthor: Int, $machineWipPublishedat: DateTime, $machineWipCreatedat: DateTime, $sourceLanguageId: Int, $wipComment: String) {
              MachineWIP(id: $machineWipId, machine_id: $machineWipMachineId, machine_data: $machineWipMachineData, author: $machineWipAuthor, publishedat: $machineWipPublishedat, createdat: $machineWipCreatedat, source_language_id: $sourceLanguageId, wip_comment: $wipComment) {
                id
              }
            }`;

            const PAYLOAD = {
              data: techdata?.data || techdata,
              _slug: machine.value.slug,
              updatedby: context.root.$store.state.user.login,
              timestamp: new Date(),
            };

            if (newVersion || !version) {
              superagent
                .post(backendUrl)
                .send({
                  query: WIPmutation,
                  variables: {
                    machineWipId: -1,
                    machineWipMachineId: machine.value.id,
                    machineWipMachineData: PAYLOAD,
                    machineWipAuthor: context.root.$store.state.user.id,
                    sourceLanguageId: language.value.id,
                    wipComment: null,
                  },
                })
                .set(
                  "Authorization",
                  `Bearer ${context.root.$store.state.user.apiToken}`
                )
                .then((res) => {
                  console.log(res);

                  if (!res.body.error) {
                    context.root.$router.replace(
                      "/machines/" +
                        id +
                        "/tech/" +
                        res.body?.data?.MachineWIP?.id
                    );
                    versionmeta.value.id = res.body?.data?.MachineWIP?.id;

                    Vue.$toast.open({
                      message: "Neue Version gespeichert",
                      icon: "check",
                    });
                    if (autosave.value) {
                      autosavetimer = setTimeout(() => {
                        save();
                      }, 180000);
                    }
                  } else {
                    autosave.value = false;
                    console.error(res.body.error);
                    Vue.$toast.open({
                      message: "Es ist ein Fehler aufgetreten",
                      type: "error",
                      icon: "exclamation",
                    });
                  }
                });
            } else {
              superagent
                .post(backendUrl)
                .send({
                  query: WIPmutation,
                  variables: {
                    machineWipId: parseInt(version),
                    machineWipMachineId: machine.value.id,
                    machineWipMachineData: PAYLOAD,
                    sourceLanguageId: language.value.id,
                    wipComment:
                      comment.value && comment.value !== ""
                        ? comment.value
                        : null,
                  },
                })
                .set(
                  "Authorization",
                  `Bearer ${context.root.$store.state.user.apiToken}`
                )
                .then((res) => {
                  if (!res.body.error) {
                    console.log(res);
                    Vue.$toast.open({
                      message: "Version aktualisiert",
                      icon: "check",
                      type: "error",
                    });
                    if (autosave.value) {
                      autosavetimer = setTimeout(() => {
                        save();
                      }, 180000);
                    }
                  } else {
                    autosave.value = false;
                    console.error(res.body.error);
                    // Vue.$toast.open(res.body.error);
                  }
                });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    function autosavetoggle() {
      autosave.value && version ? save() : clearTimeout(autosavetimer);
    }

    function clipboardSuccessHandler({ value }) {
      Vue.$toast.open({
        message: `'${value}' erfolgreich kopiert`,
        icon: "clipboard-check",
      });
    }

    return {
      userid: context.root.$store.state.user.id,
      directories,
      childrenCount,
      showModal,
      recovery,
      startRecovery,
      machine,
      countChildren,
      cleanUp,
      audit,
      rules,
      copy,
      paste,
      saveValidation,
      versionmeta,
      autosave,
      autosavetoggle,
      theElderScrolls,
      jumpTo,
      inputArray,
      settings,
      inputForm,
      create,
      save,
      remove,
      units,
      specialChars,
      clipboardSuccessHandler,
      cssLevelVars,
      version,
      move,
      stats,
      loading,
      setCategory,
      getColor,
      toggleShortcuts,
      selected,
      selectedNew,
      permit,
      languages,
      language,
      comment,
      newestLive,
      versionOutdated,
      newestEditorVersion,
    };
  },
};
</script>

<style lang="scss" module>
.modal {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999999999;
  width: 500px;
  border-radius: 16px;
  padding: 40px 30px;
}

.tabswitch {
  display: flex;
  background: var(--card-light-stack);
  padding: 3px 1px;
  margin: 0px 15px 13px;
  border-radius: 10px;
  button {
    border-radius: 8px !important;
    margin: 0 2px !important;
    justify-content: center;
    font-size: 1.1em;
    padding: 7px;
  }
}

.limit_select {
  display: inline-flex;
  background: var(--black-1);
  border-radius: 6px;
  padding: 2px;
  &.limit_active {
    background: var(--white-5);
  }
  button {
    margin: 0 !important;
    padding: 5px 10px !important;
    border-radius: 5px !important;
    &:first-child {
      margin-right: 2px !important;
    }
    &.active {
      background: white;
      color: black;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    }
  }
}

.techinput_container {
  padding: 0 60px 0 0;
  transition: padding 0.2s ease;
  &.hide_control {
    padding: 0 !important;
  }
}

.techinput_wrap {
  padding: 2px 0 2px 0;
  transition: padding 0.1s ease box-shadow 0.1s ease !important;
  position: relative;
  box-shadow: 0 0 0 0 #88f9;
  display: grid;
  grid-template-columns: var(--level) auto;
  .is_selected {
    box-shadow: 0 0 0 4px #88fc;
  }
  .techinput_index {
    position: absolute;
  }
}
.JSONdownload {
  height: 100px;
  font-size: 0.7em;
  width: 100%;
  resize: none;
  padding: 10px;
  border: none;
  box-shadow: 0 0 0 4px rgba(232, 73, 16, 0.3);
  border-radius: 4px;
}

.audit_check {
  background: #48f191;
  color: white;
  padding: 20px;
  font-size: 2em;
  display: inline-block;
  border-radius: 40px;
  margin: 20px 0;
}
.techinput_level {
  display: flex;
  align-items: center;
  justify-content: center;
  .techinput_level__dot {
    display: block;
    border-radius: 5px;
    width: 15px;
    height: 10px;
    background: rgba(128, 128, 128, 0.5);
    margin: 0 18px;
  }
}
.techinput_level__dot__line {
  display: inline-block;
  text-align: center;
  color: white;
  font-size: 0.9em;
  padding: 0 7px;
  border-radius: 10px;
  min-width: 40px;
}

.jsonupload {
  display: block;
  padding: 25px 10px;
  border: 1px dashed grey;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background: rgba(128, 128, 128, 0.1);
  }
  &:active {
    background: rgba(128, 128, 128, 0.2);
  }
  input {
    display: none;
  }
}

.tocEntry {
  padding: 5px 15px;
  display: flex;
  align-items: flex-start;
  flex-direction: column !important;
  &:last-of-type {
    margin-bottom: 10px;
  }
  &:first-of-type {
    margin-top: 10px;
  }
  .techinput_level {
    display: inline-flex;
    > span {
      margin: 0 5px;
      width: 15px;
      text-align: center;
    }
  }
  .tocEntry {
    margin: 0;
    padding: 5px 0 5px 20px !important;
    flex-direction: row !important;
    &:last-of-type {
      padding-bottom: 0px !important;
    }
    &:first-of-type {
      padding-top: 10px !important;
    }
    .techinput_level__dot {
      margin: 0 7px 0 17px;
    }
  }
}

.flagsAndComments {
  padding: 5px 15px;
  svg {
    opacity: 0.3;
  }
  .active {
    opacity: 1;
  }
}

.special_select {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  button {
    font-size: 1.3em;
    justify-content: center;
  }
}
</style>

<style lang="scss">
.flip-enter-active,
.flip-leave-active,
.flip-move {
  transition: all 0.15s ease !important;
}
.flip-enter,
.flip-leave-to {
  opacity: 0;
}
.flip-list-move {
  transition: transform 0.5s;
}

.opacity-enter-active,
.opacity-leave-active {
  transform: translateY(-50%) scale(1);
  transition: all 0.2s ease !important;
}
.opacity-enter,
.opacity-leave-to {
  opacity: 0;
  transform: translateY(-50%) scale(0.8) !important;
}

.opacity-basic-enter-active,
.opacity-basic-leave-active {
  transform: scale(1);
  transition: all 0.2s ease !important;
}
.opacity-basic-enter,
.opacity-basic-leave-to {
  opacity: 0;
  transform: scale(0.8) !important;
}

.ghost {
  opacity: 0.5;
}
.tocEntry_grip {
  cursor: row-resize;
}
</style>
