import Vue from "vue";
import Vuex from "vuex";
import superagent from "superagent";
import {backendUrl} from "@/constants.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    copyPasta: localStorage.getItem("copyPasta")
      ? JSON.parse(localStorage.getItem("copyPasta"))
      : "",
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : "",
    token: localStorage.getItem("token") || "",
    api: localStorage.getItem("api") || "",
    // Deprecated API support
    hydraToken: localStorage.getItem("hydraToken"),
    //END
  },
  mutations: {
    setCopyPastaStore(state, content) {
      state.copyPasta = content;
    },
    setCopyPasta(state, content) {
      localStorage.setItem("copyPasta", JSON.stringify(content));
      state.copyPasta = content;
    },
    setUser(state, user) {
      localStorage.setItem("user", JSON.stringify(user));
      state.user = user;
    },
    setToken(state, token) {
      localStorage.setItem("token", token);
      state.token = token;
    },
    // Deprecated API support
    setHydraToken(state, token) {
      localStorage.setItem("hydraToken", token);
      state.hydraToken = token;
    },
    //END
    setApi(state, api) {
      localStorage.setItem("api", api);
      state.api = api;
    },
    clearState(state) {
      localStorage.removeItem("copyPasta");
      state.copyPasta = "";
      localStorage.removeItem("user");
      state.user = "";
      localStorage.removeItem("token");
      state.token = "";
      localStorage.removeItem("api");
      state.api = "";
      // Deprecated API support
      localStorage.removeItem("hydraToken");
      state.hydraToken = "";
      //END
    },
  },
  actions: {
    async login(context, user) {
      // let ip;
      // try {
      // const data = await superagent.get("https://api.ipify.org?format=json");
      // ip = data.body.ip || null;
      // } catch (error) {
      /* lol no ip for you */
      // }

      // const hostname = window.location?.hostname;

      let device = {
        deviceMemory: window.navigator?.deviceMemory || null,
        doNotTrack: window.navigator?.doNotTrack || null,
        geolocation: window.navigator?.geolocation || null,
        hardwareConcurrency: window.navigator?.hardwareConcurrency || null,
        language: window.navigator?.language || null,
        languages: window.navigator?.languages || null,
        platform: window.navigator?.platform || null,
        plugins: window.navigator?.plugins || null,
        product: window.navigator?.product || null,
        productSub: window.navigator?.productSub || null,
        userAgent: window.navigator?.userAgent || null,
        vendor: window.navigator?.vendor || null,
        cookieEnabled: window.navigator?.cookieEnabled || null,
      };

      try {
        device.connection = {
          downlink: window.navigator.connection.downlink,
          effectiveType: window.navigator.connection.effectiveType,
          rtt: window.navigator.connection.rtt,
          saveData: window.navigator.connection.saveData,
        };
      } catch (error) {
        /* hush little error, dont you cry. */
      }

      return new Promise((resolve, reject) => {
        superagent
          .post("https://auth.rep.guide/login")
          .send({
            cfWorkerLogin: user.username,
            cfWorkerQuestion: user.password,
          })
          .then((login) => {
            const gqlQueryUser = `query Query {
              User(login: "${user.username}") {
                id
                login
                email
                salutation
                prename
                name
                sex
                status
                tier
                comment
                activatedat
                country
                roles
              }
            }`;
            let index = login.body.instanceIdentifier.indexOf(".");
            const token = login.body.instanceIdentifier.slice(
              ++index,
              login.body.instanceIdentifier.length
            );
            console.log(token);
            superagent
              .post(backendUrl)
              .send({
                query: gqlQueryUser,
              })
              .set("Authorization", `Bearer ${token}`)
              .then((res) => {
                if (["administration"].includes(res.body.data.User.tier)) {
                  const newUSER = { ...res.body.data.User, apiToken: token };
                  context.commit("setUser", newUSER);

                  console.log(res);
                  resolve();
                  //END
                } else {
                  reject();
                }
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    logout(context) {
      return new Promise((resolve) => {
        context.commit("clearState");
        resolve();
      });
    },
    log({ state }, options) {
      const PAYLOAD = Object.assign({ userid: state.user.id }, options);
      return new Promise((resolve, reject) => {
        if (
          ["channel", "reference", "userid", "action"].every(
            (key) => PAYLOAD[key]
          )
        ) {
          //TODO?
          // whatever should happen here in the future (logging e.g.)
        } else {
          reject();
        }
      });
    },
  },
});
