<template>
  <div
    v-close="close"
    class="dropdown"
  >
    <div
      ref="trigger"
      class="dropdown-trigger"
      @click="isOpen = !isOpen"
    >
      <slot name="trigger">
        <rg-button
          :title="triggertitle"
          tabindex="-1"
          :type="type"
          :icon="icon"
        />
      </slot>
    </div>
    <transition name="dropdown">
      <div
        v-if="isOpen"
        :class="['dropdown-content', 'card', position]"
        :style="'width:'+width+'px; max-height:'+height+'px'"
        @click="isOpen = stay"
      >
        <slot>
          <rg-button
            style="justify-content:center"
            label="Schließen"
          />
        </slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import Vue from 'vue'

let clickedOutside
let triggerBinding

Vue.directive('close', {
  bind (el, binding) {
    clickedOutside = (e) => {
      e.stopPropagation()
      if (!el.contains(e.target)) { binding.value() }
    }
    triggerBinding = (e) => { if (e.keyCode === 27) { binding.value() } }
    document.addEventListener('mousedown', clickedOutside)
    document.addEventListener('touchstart', clickedOutside)
    document.addEventListener('keydown', triggerBinding)
  },

  unbind () {
    document.removeEventListener('mousedown', clickedOutside)
    document.removeEventListener('touchstart', clickedOutside)
    document.removeEventListener('keydown', triggerBinding)
  }
})

export default {
  props: {
    icon: { type: String, default: 'chevron-down' },
    type: { type: String, default: 'is-primary' },
    triggertitle: { type: String, default: null },
    stay: { type: Boolean, default: false },
    width: { type: Number, default: 200 },
    height: { type: Number, default: 400 },
    position: { type: String, default: 'bottom-left' , validator: v => {
      return ['top-left', 'top-center', 'top-right', 'bottom-left', 'bottom-center', 'bottom-right'].includes(v)
    }}
  },
  setup() {
    let isOpen = ref(false)
    function close() { isOpen.value = false }

    document.addEventListener('keypress', e => { if (e.keyCode === 27) { close() } })

    return { isOpen, close }
  }
}
</script>

<style lang="scss">
  div.dropdown {
    position: relative;
    display: inline-block;
    div.dropdown-content {
      &.top-left {
        bottom: calc(100% + 5px);
        right: 0;
        transform-origin: bottom right;
      }
      &.top-center {
        bottom: calc(100% + 5px);
        right: 50%;
        transform: translateX(50%);
        transform-origin: bottom center;
      }
      &.top-right {
        bottom: calc(100% + 5px);
        left: 0;
        transform-origin: bottom left;
      }
      &.bottom-left {
        top: calc(100% + 5px);
        right: 0;
        transform-origin: top right;
      }
      &.bottom-center {
        top: calc(100% + 5px);
        right: 50%;
        transform: translateX(50%);
        transform-origin: top center;
      }
      &.bottom-right {
        top: calc(100% + 5px);
        left: 0;
        transform-origin: top left;
      }
      z-index: 99999999999;
      position: absolute;
      width: 200px;
      padding: 5px 0px;
      border-radius: 10px;
      user-select: none;
      overflow-x: auto;
      button:not(.custom) {
        width: calc(100% - 10px);
        margin: 0 5px;
        padding-left: 10px;
        padding-right: 10px;
        svg { opacity: .8 }
        &:not(.is-primary) {
          background: transparent;
          &:not([disabled]):hover { background: var(--card-light-stack) }
        }
      }
      p {
        margin: 0;
        padding: 10px 15px;
        word-wrap: break-word;
        code { font-size: .9em; }
      }
      hr {
        margin: 5px 0;
        background: rgba(150 150 150 / .5);
        border: none;
        height: 3px;
        width: initial;
      }
    }
  }

.dropdown-enter-active, .dropdown-leave-active {
  &.bottom-center, &.top-center {
    transform: scale(1) translateX(50%)!important;
    right: 50%!important;
  }
  transition: all .123s;
  opacity: 1;
  transform: scale(1)
}

.dropdown-enter, .dropdown-leave-to {
  &.bottom-center, &.top-center {
    transform: scale(.8) translateX(50%)!important;
    right: 0%!important;
  }
  opacity: 0;
  transform: scale(.8)
}
</style>