import tractor_DE_data from "./tractor_DE";
import construction_DE_data from "./construction_DE";
import construction_EN_data from "./construction_EN";
import engine_DE_data from "./engine_DE";
import engine_EN_data from "./engine_EN";
import wheelloader_DE_data from "./wheelloader_DE";

const tractor = {
  name: "Traktoren-Vorlage",
  hide: false,
  data: tractor_DE_data,
  machineTypes: [
    "rgtype_tractor",
    "Traktor",
    "rgtype_crawler-tractor",
    "Raupentraktor",
    "rgtype_combine-harvester",
    "Mähdrescher",
  ],
};

const construction_DE = {
  name: "Baumaschinen-Vorlage (DE)",
  hide: false,
  data: construction_DE_data,
  machineTypes: [
    "rgtype_compact-excavator",
    "Kompaktbagger",
    "rgtype_hydraulic-excavator",
    "Hydraulikbagger",
    "rgtype_telescopic-handler",
    "Teleskoplader",
    "rgtype_mobile-excavator",
    "Mobilbagger",
    "rgtype_crawler-excavator",
    "Raupenbagger",
    "rgtype_mini-excavator",
    "Minibagger",
    "rgtype_wheel-loader",
    "Radlader",
    "rgtype_skid-loader",
    "Kompaktlader",
  ],
};

const construction_EN = {
  name: "Baumaschinen-Vorlage (EN)",
  hide: false,
  data: construction_EN_data,
  machineTypes: [
    "rgtype_compact-excavator",
    "Kompaktbagger",
    "rgtype_hydraulic-excavator",
    "Hydraulikbagger",
    "rgtype_telescopic-handler",
    "Teleskoplader",
    "rgtype_mobile-excavator",
    "Mobilbagger",
    "rgtype_crawler-excavator",
    "Raupenbagger",
    "rgtype_mini-excavator",
    "Minibagger",
    "rgtype_wheel-loader",
    "Radlader",
    "rgtype_skid-loader",
    "Kompaktlader",
  ],
};

const engine_DE = {
  name: "Motoren-Vorlage (DE)",
  hide: false,
  data: engine_DE_data,
  machineTypes: ["rgtype_engine", "Motor"],
};

const engine_EN = {
  name: "Motoren-Vorlage (EN)",
  hide: false,
  data: engine_EN_data,
  machineTypes: ["rgtype_engine", "Motor"],
};

const wheelloader_DE = {
  name: "Radlader-Vorlage (DE)",
  hide: false,
  data: wheelloader_DE_data,
  machineTypes: ["rgtype_wheel-loader", "Radlader"],
};

export default {
  tractor,
  construction_DE,
  construction_EN,
  engine_DE,
  engine_EN,
  wheelloader_DE,
};
