const dev = false;
const es = dev
  ? "http://localhost:9200"
  : "https://b245de.es.europe-west1.gcp.cloud.es.io:9243";
const jal = "https://infoweb.jaltest.com/api/v1";
const issues =
  "https://frixlab.fricke.de/innovationlab/repguide/redaktion/-/issues/";
const levelColors = ["#4C8DDE", "#FE8902", "#947EDB", "#F4BC51", "#2BAC64"];

const units = [
  "Ω",
  "kΩ",
  "A",
  "Ah",
  "Stück",
  "dB(A)",
  "bar",
  "VDC",
  "°",
  "°C",
  "g",
  "J",
  "K",
  "Kg",
  "Kg/Ltr.",
  "Km/h",
  "Kp",
  "KW",
  "m³",
  "cm³",
  "cm",
  "Ltr.",
  "Ltr./min",
  "m",
  "ml",
  "mm",
  "'",
  "min",
  "n.OT",
  "n.UT",
  "N",
  "daN",
  "Nm",
  "PS",
  "%",
  "cm²",
  "s",
  "g/KWh",
  "h",
  "t",
  "U/m",
  "U/min",
  "V",
  "Vol. %",
  "v.OT",
  "v.UT",
  "W",
  '"',
  "i",
  "je mm",
  "je cm",
  "V/A",
  "m/s",
  "Psi",
  "MPa",
  "m/s²",
];

const specialChars = [
  {
    title: "Allgemeine Sonderzeichen",
    chars: [
      { value: "±", title: "Plusminuszeichen" },
      { value: "×", title: "Multiplikationszeichen" },
      { value: "≈", title: "Ungefähr-Zeichen" },
      { value: "≤", title: "Kleiner-gleich-Zeichen" },
      { value: "≥", title: "Größer-gleich-Zeichen" },
      { value: "µ", title: "Mikro-Zeichen" },
      { value: "‰", title: "Promillezeichen" },
      { value: "©", title: "Copyright" },
      { value: "®", title: "Registered Trademark" },
      { value: "™", title: "Unregistered Trademark" },
      { value: "β", title: "Beta-Zeichen" },
      { value: "№", title: "Numero-Zeichen" },
    ],
  },
  {
    title: "Hochgestellte Zeichen",
    chars: [
      { value: "¹", title: "Hochgestellte 1" },
      { value: "²", title: "Hochgestellte 2" },
      { value: "³", title: "Hochgestellte 3" },
      { value: "⁴", title: "Hochgestellte 4" },
      { value: "⁵", title: "Hochgestellte 5" },
      { value: "⁶", title: "Hochgestellte 6" },
      { value: "⁷", title: "Hochgestellte 7" },
      { value: "⁸", title: "Hochgestellte 8" },
      { value: "⁹", title: "Hochgestellte 9" },
      { value: "⁰", title: "Hochgestellte 0" },
      { value: "⁺", title: "Hochgestelltes Plus" },
      { value: "⁻", title: "Hochgestelltes Minus" },
      { value: "⁼", title: "Hochgestelltes Gleichheits-Zeichen" },
      { value: "⁽", title: "Hochgestellte Klammer auf" },
      { value: "⁾", title: "Hochgestellte Klammer zu" },
    ],
  },
  {
    title: "Tiefgestellte Zeichen",
    chars: [
      { value: "₁", title: "Tiefgestellte 1" },
      { value: "₂", title: "Tiefgestellte 2" },
      { value: "₃", title: "Tiefgestellte 3" },
      { value: "₄", title: "Tiefgestellte 4" },
      { value: "₅", title: "Tiefgestellte 5" },
      { value: "₆", title: "Tiefgestellte 6" },
      { value: "₇", title: "Tiefgestellte 7" },
      { value: "₈", title: "Tiefgestellte 8" },
      { value: "₉", title: "Tiefgestellte 9" },
      { value: "₀", title: "Tiefgestellte 0" },
      { value: "₊", title: "Tiefgestelltes Plus" },
      { value: "₋", title: "Tiefgestelltes Minus" },
      { value: "₌", title: "Tiefgestelltes Gleichheits-Zeichen" },
      { value: "₍", title: "Tiefgestellte Klammer auf" },
      { value: "₎", title: "Tiefgestellte Klammer zu" },
    ],
  },
];

const getColor = (level) => levelColors[level % 5];

const onlyInteger = (e, ...allowed) => {
  if ((e.which >= 48 && e.which <= 57) || allowed.includes(e.key)) {
    return true;
  } else {
    e.preventDefault();
  }
};

const generateStatus = (code, msg = { ok: "", er: "" }) => {
  switch (code) {
    case 200:
      return { icon: "check", message: msg.ok || "Anfrage erfolgreich" };
    case 201:
      return { icon: "check", message: msg.ok || "Erfolgreich erstellt" };
    case 204:
      return { icon: "check", message: msg.ok || "Anfrage erfolgreich" };
    case 400:
      return {
        duration: 30000,
        icon: "exclamation",
        type: "error",
        message: msg.er || "Anfrage fehlerhaft",
      };
    case 401:
      return {
        duration: 30000,
        icon: "exclamation",
        type: "error",
        message: msg.er || "Nicht autorisiert",
      };
    case 403:
      return {
        duration: 30000,
        icon: "exclamation",
        type: "error",
        message: msg.er || "Anfrage nicht erlaubt",
      };
    case 404:
      return {
        duration: 30000,
        icon: "exclamation",
        type: "error",
        message: msg.er || "Nicht gefunden",
      };
    case 406:
      return {
        duration: 30000,
        icon: "exclamation",
        type: "error",
        message: msg.er || "Anfrage nicht akzeptiert",
      };
    case 409:
      return {
        duration: 30000,
        icon: "exclamation",
        type: "error",
        message: msg.er || "Anfrage abgelehnt: Konflikt",
      };
    case 426:
      return {
        duration: 30000,
        icon: "exclamation",
        type: "error",
        message: msg.er || "Sitzung abgelaufen. Bitte neu anmelden",
      };
    case 500:
      return {
        duration: 30000,
        icon: "exclamation",
        type: "error",
        message: msg.er || "Server ist hinüber",
      };
    default:
      return { duration: 30000, icon: "", message: "" };
  }
};

const onlyAlphaNumeric = (e, ...allowed) => {
  if (
    allowed.includes(e.key) ||
    e.which === 32 ||
    (e.which >= 48 && e.which <= 57) ||
    (e.which >= 65 && e.which <= 90) ||
    (e.which >= 97 && e.which <= 122)
  ) {
    return true;
  } else {
    e.preventDefault();
  }
};

const millisecondsInHours = (ms) => {
  ms = Math.max(0, ms);
  
  const hours = Math.floor(ms / 1000 / 60 / 60);
  const minutes = String(Math.floor((ms / 1000 / 60) % 60)).padStart(2, "0");
  const seconds = String(Math.floor((ms / 1000) % 60)).padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
};

const formDirty = (a, b) => {
  return (
    (a || (typeof a === "boolean" ? false : null)) !==
    (b || (typeof b === "boolean" ? false : null))
  );
};

const generateSlug = (str) => {
  return str
    .trim()
    .toLowerCase()
    .replace("ä", "ae")
    .replace("Ä", "Ae")
    .replace("ö", "oe")
    .replace("Ö", "Oe")
    .replace("ü", "ue")
    .replace("Ü", "Ue")
    .replace("ß", "ss")
    .replace(/\u00c4|\u00e4/, "s")
    // remove special characters
    .replace(/[^a-zA-Z-\d\s]/g, "")
    // remove extra dashes
    .replace(/[\s-]+/g, "-")
    .replace(/^-/, "")
    // remove any non word character at the end and beginning
    .replace(/[\W_]*$/, "")
    .replace(/^[\W_]*/, "");
};

module.exports = {
  levelColors,
  getColor,
  issues,
  es,
  jal,
  onlyInteger,
  onlyAlphaNumeric,
  formDirty,
  generateStatus,
  units,
  specialChars,
  generateSlug,
  millisecondsInHours,
};
