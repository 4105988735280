<template>
  <article class="timer-container">
    <rg-dropdown
      v-if="!timers.length"
      :width="420"
      position="top-left"
    >
      <template #trigger>
        <rg-button
          tabindex="-1"
          icon-left="stopwatch"
          icon-right="plus"
          class="is-primary is-large"
          :disabled="!timersLoaded"
        />
      </template>
      <div style="display: flex; flex-direction: column; gap: .25rem;">
        <button
          class="timer-button"
          @click="startTimer('entry')"
        >
          <fa
            class="fa-fw"
            :icon="['fas', 'plus']"
          />
          <div class="timer-button-flex">
            <strong>Erfassung starten</strong>
            <p>
              Mit der Datenerfassung einer neuen Maschine beginnen.
            </p>
          </div>
        </button>
        <button
          class="timer-button"
          @click="startTimer('review')"
        >
          <fa
            class="fa-fw"
            :icon="['fas', 'eye']"
          />
          <div class="timer-button-flex">
            <strong>Prüfung starten</strong>
            <p>Diese Version überprüfen und gegebenenfalls freigeben.</p>
          </div>
        </button>
        <button
          class="timer-button"
          @click="startTimer('revision')"
        >
          <fa
            class="fa-fw"
            :icon="['fas', 'pen']"
          />
          <div class="timer-button-flex">
            <strong>Überarbeitung starten</strong>
            <p>
              Eine bereits veröffentliche Version überarbeiten.
            </p>
          </div>
        </button>
      </div>
    </rg-dropdown>
    <template v-else>
      <div
        style="position: absolute; top: -1rem; left: -1rem; z-index: 999; background: var(--white-O); border-radius: 100%; box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);"
      >
        <rg-button
          tabindex="-1"
          :icon="preventAutoStop ? 'lock' : 'lock-open'"
          :class="['is-round', {'is-danger': preventAutoStop}]"
          style="padding: 8px 6px;"
          :disabled="!isRunning"
          title="Automatisches Stoppen des Timers deaktivieren. Nützlich, wenn Teile der technischen Eingabe in einem anderen Programm stattfinden; Tabellen-Erfassung in Excel, Erstellen von Zeichnungen, ..."
          @click="togglePreventAutoStop"
        />
      </div>

      <rg-button
        tabindex="-1"
        :icon-left="isRunning ? 'pause' : 'play'"
        :class="['is-large', {'is-primary': isRunning}]"
        style="border-top-right-radius: 0px; border-bottom-right-radius: 0px; z-index: 99"
        @click="isRunning ? stopTimer() : startTimer()"
      >
        <span style="font-variant-numeric: tabular-nums; margin-right: 6px">
          {{ totalTime }}
        </span>
        <span
          v-if="timers[0]"
          style="opacity: 0.75;"
        >
          {{ getActivityName(timers[0].activity) }}
        </span>
      </rg-button>
				
      <rg-dropdown
        :width="420"
        position="top-left"
      >
        <template #trigger>
          <rg-button
            tabindex="-1"
            icon="chevron-up"
            class="is-large has-border"
            style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
          />
        </template>
        <div style="display: flex; flex-direction: column;">
          <header class="timer-header">
            <strong>Start</strong>
            <strong>Ende</strong>
            <strong
              style="text-decoration: underline dotted; cursor: help"
              title="Auf ganze Sekunden abgerundet."
            >
              Dauer
            </strong>
            <strong
              style="text-decoration: underline dotted; cursor: help"
              title="Für Korrekturen durch einen Admin notwendig."
            >
              ID
            </strong>
          </header>
          <div
            v-for="timer in timers"
            :key="timer.id"
            class="timer-entry"
          >
            <span>{{ relativeDate(timer.startedat) }}</span>
            <template v-if="timer.endedat">
              <span>{{ relativeDate(timer.endedat) }}</span>
              <strong style="font-variant-numeric: tabular-nums;">
                {{ millisecondsInHours(timer.runtime) }}
              </strong>
            </template>
            <template v-else>
              <span>-</span>
              <strong style="font-variant-numeric: tabular-nums;">
                {{ millisecondsInHours(now - new Date(timers[0].startedat).getTime()) }}
              </strong>
            </template>
            <div>
              <rg-button
                v-clipboard="timer.id"
                v-clipboard:success="clipboardSuccessHandler"
                title="Timer-ID in die Zwischenablage kopieren"
                class="is-small"
                icon="clipboard"
              />
            </div>
          </div>
        </div>
      </rg-dropdown>
    </template>
  </article>
</template>

<script>
import { computed, onBeforeUnmount, ref } from '@vue/composition-api';
import superagent from "superagent";
import { altBackendUrl } from "@/constants.js";
import rgButton from './rg-button.vue';
import rgDropdown from './rg-dropdown.vue';
import Vue from 'vue';
import { millisecondsInHours } from "@/helper.js";

export default {
	name: "Timer",
	components: {
    "rg-button": rgButton,
    "rg-dropdown": rgDropdown
  },
  props: {
    reference: { type: String, default: null },
  },
	setup(props, context) {
		let tickInterval = null;
		let now = ref(new Date().getTime());
		let timers = ref([]);
		let timersLoaded = ref(false);
		let isRunning = ref(false);
		let autoStopTimeout = null;
		let preventAutoStop = ref(false);

		let totalTime = computed(() => {
			const previous = timers.value.filter(t => t.endedat).reduce((ms, t) => ms + t.runtime, 0);
			const current = now.value - new Date(timers.value[0].startedat).getTime();
			return millisecondsInHours(previous + (isRunning.value ? current : 0));
    });
		
		const startTicker = () => {
			now.value = new Date().getTime();
			
			clearInterval(tickInterval);
			tickInterval = setInterval(() => {
				now.value = new Date().getTime()
				updateLocalStorage();
			}, 1000);
		};

		// MARK: auto-stop logic ---------------------------------------------------
		const refreshAutoStopTimeout = () => {
			if (!isRunning.value || preventAutoStop.value) return

			clearTimeout(autoStopTimeout);
			autoStopTimeout = setTimeout(autoStopTimer, 6e5);
		};

		const togglePreventAutoStop = () => {
			preventAutoStop.value = !preventAutoStop.value;
			preventAutoStop.value ? clearTimeout(autoStopTimeout) : refreshAutoStopTimeout()
		};

		const autoStopTimer = () => {
			if (isRunning.value) stopTimer().then(() => {
				setTimeout(() => window.alert("Timer wurde nach 10 Minuten Inaktivität automatisch gestoppt."), 123);
			})
		};

		window.addEventListener("scroll", refreshAutoStopTimeout);
		window.addEventListener("mousemove", refreshAutoStopTimeout);
		window.addEventListener("keydown", refreshAutoStopTimeout);

		// MARK: stopping timers that weren't stopped properly ---------------------
		const TIMERS = JSON.parse(localStorage.getItem("timers") || "{}")
		const MUTATIONS = Object.entries(TIMERS).map((entry) => {
			return superagent
				.post(altBackendUrl)
				.send({ 
					query: `
						mutation RetroactivelyStopTechDataTimer(
							$id: String!, $endedat: DateTime, $meta: JSON
						) {
							Timer(id: $id, endedat: $endedat, meta: $meta) { id }
						}
					`,
					variables: {
						id: entry[0],
						endedat: new Date(entry[1]).toISOString(),
						meta: {
							message: "Timer was stopped retroactively.",
							lastactivity: new Date(entry[1]),
							timestamp: new Date(),
						},
					}
				})
				.set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
				.then((res) => removeTimerFromLocalStorage(res.body.data.Timer.id))
		});

		Promise.all(MUTATIONS).finally(() => {
			localStorage.removeItem("timers");

			// MARK: setting up timers -------------------------------------------------
			superagent
				.post(altBackendUrl)
				.send({ query: `query TimersByReferenceAndUser {
					Timers(
						userid: ${context.root.$store.state.user.id},
						reference: "${props.reference}"
					) {
						id
						startedat
						endedat
						runtime
						activity
					}
				}`})
				.set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
				.then((res) => {
					timers.value = res.body.data.Timers;
					timersLoaded.value = true;
	
					if (timers.value[0]?.endedat === null) {
						isRunning.value = true;
	
						startTicker()
						refreshAutoStopTimeout()
					}
				})
		})
		

		// MARK: timer control -----------------------------------------------------
		const startTimer = (activity) => {
			activity ||= timers.value[0]?.activity
			if (!activity) return;

			setTimeout(() => {
				superagent
					.post(altBackendUrl)
					.send({ query: `mutation StartTechDataTimer {
						StartTimer(
							userid: ${context.root.$store.state.user.id},
							context: "techdata",
							activity: "${activity}",
							reference: "${props.reference}"
						) {
							id
							startedat
							endedat
							runtime
							activity
						}
					}`})
					.set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
					.then((res) => {
						timers.value.unshift(res.body.data.StartTimer);

						isRunning.value = true;

						startTicker();
						refreshAutoStopTimeout()
					})
			}, !timers.value.length ? 123 : 0);
		};

		const stopTimer = () => {
			return superagent
				.post(altBackendUrl)
				.send({ query: `mutation StopTechDataTimer {
					StopTimer(id: "${timers.value[0].id}") {
						id
						startedat
						endedat
						runtime
						activity
					}
				}`})
				.set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
				.then((res) => {
					timers.value[0] = res.body.data.StopTimer;

					isRunning.value = false;

					clearInterval(tickInterval);
					clearTimeout(autoStopTimeout);

					preventAutoStop.value = false;

					removeTimerFromLocalStorage(timers.value[0].id)
				})
			}

		// MARK: helper functions --------------------------------------------------
		const relativeDate = (date) => new Date(date).toLocaleDateString('de-DE', {
			year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',
		});

		const getActivityName = (activity) => {
			switch (activity) {
				case "entry": return "Erfassung";
				case "review": return "Prüfung";
				case "revision": return "Überarbeitung";
				default: return "Unbekannt";
			}
		};

		const clipboardSuccessHandler = () => {
			Vue.$toast.open({
        message: "Timer-ID erfolgreich kopiert",
        icon: "clipboard-check",
      });
		};

		const updateLocalStorage = () => {
			if (!isRunning.value) return;

			const TIMERS = JSON.parse(localStorage.getItem("timers") || "{}")
			TIMERS[timers.value[0].id] = new Date().getTime();
			localStorage.setItem("timers", JSON.stringify(TIMERS));
		};

		const removeTimerFromLocalStorage = (id) => {
			const TIMERS = JSON.parse(localStorage.getItem("timers") || "{}")
			delete TIMERS[id];
			if (Object.keys(TIMERS).length === 0) localStorage.removeItem("timers")
			else localStorage.setItem("timers", JSON.stringify(TIMERS))
		};

		// MARK: cleanup -----------------------------------------------------------
		onBeforeUnmount(() => {
			clearInterval(tickInterval);
			clearTimeout(autoStopTimeout);
			
			window.removeEventListener("scroll", refreshAutoStopTimeout);
			window.removeEventListener("mousemove", refreshAutoStopTimeout);
			window.removeEventListener("keydown", refreshAutoStopTimeout);

			if (isRunning.value) stopTimer();
		});
		
		return {
			now,
			isRunning,
			timers,
			timersLoaded,
			totalTime,
			startTimer,
			stopTimer,
			millisecondsInHours,
			relativeDate,
			clipboardSuccessHandler,
			getActivityName,
			preventAutoStop,
			togglePreventAutoStop,
		}
	},
};
</script>

<style lang="scss">
	.timer-container {
		position: fixed;
		bottom: 21px;
		right: 21px;
		z-index: 9999999999;
		background: var(--white-O);
		border-radius: 10px;
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
	}

	.timer-button {
		position: relative;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		gap: 8px;
		padding: .75rem 1rem!important;
		overflow: hidden;

		svg {
			position: absolute;
			font-size: 4.5rem;
			opacity: 0.15!important;
			top: -1rem;
			right: -1rem;
		}

		.timer-button-flex {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 4px;
			text-align: left;

			strong {
				font-size: 1rem;
			}
			p {
				font-size: 0.875rem;
				color: var(--black-7);
				margin: 0!important;
				padding: 0!important;
			}
		}
	}

	.timer-header {
		display: grid;
		grid-template-columns: 2fr 2fr 1fr 3em;
		padding: 0.5rem;
		> * {
			align-self: center;
			justify-self: center;
		}
	}

	.timer-entry {
		display: grid;
		grid-template-columns: 2fr 2fr 1fr 3em;
		padding: 0.25rem 0.5rem 0.25rem 1rem;
		&:not(:last-of-type) {
			border-bottom: 1px solid var(--black-3);
		}
		> * {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			align-self: center;
			justify-self: center;
		}
	}

	.has-border {
		border-left: 1px solid var(--black-3);
	}
</style>