<template>
  <div>
    <navigation>
      <div style="display: flex; gap: 5px">
        <div>
          <router-link to="/associations/add">
            <rg-button
              type="is-primary"
              icon-left="building"
              title="Anlegen"
              label="Anlegen"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
            />
          </router-link>
          <rg-dropdown>
            <template
              #trigger
            >
              <rg-button
                icon="chevron-down"
                style="border-top-left-radius: 0; border-bottom-left-radius: 0"
              />
            </template>
            <router-link to="/accounts/add">
              <rg-button
                icon-left="user-plus"
                label="Account"
              />
            </router-link>
            <router-link to="/companies/add">
              <rg-button
                icon-left="industry"
                label="Unternehmen"
              />
              <router-link to="/associations/add">
                <rg-button
                  icon-left="building"
                  label="Unternehmensgruppe"
                />
              </router-link>
            </router-link>
          </rg-dropdown>
        </div>
      </div>
    </navigation>
    <main>
      <h1>
        <fa
          icon="building"
          class="fa-fw"
        /> {{ data.association_title }}
      </h1>
      <div>
        <code
          style="font-weight: bold"
          title="ID"
        >
          ID: {{ data.id }}
        </code>
      </div>
      <br>
      <rg-tabs>
        <router-link
          tag="button"
          replace
          :to="'/associations/' + $route.params.id + '/home'"
        >
          <fa icon="building" />&nbsp;Übersicht
        </router-link>
      </rg-tabs>
      <br><br>
      <router-view :data="data" />
    </main>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import superagent from "superagent";
import {altBackendUrl} from "@/constants.js";
export default {
  name: "UsersDetail",
  components: {
    "rg-dropdown": () => import("@/components/elements/rg-dropdown"),
    "rg-tabs": () => import("@/components/elements/rg-tabs"),
  },
  props: {},
  setup(props, context) {
    let data = ref({});

    const gqQueryCompany = `query CompanyAssociations($associationId: Int) {
        CompanyAssociations(id: $associationId) {
          id
          association_title
          association_description
          association_pricing_id
          association_manager_id
          alternate_contact_information
          createdat
          updatedat
        }
      }
      `;
    superagent
      .post(altBackendUrl)
      .send({
        query: gqQueryCompany,
        variables: { associationId: parseInt(context.root.$route.params.id) },
      })
      .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
      .then((res) => {
        data.value = res.body.data.CompanyAssociations[0];
      });

    return { data };
  },
};
</script>

<style lang="scss" module>
.detail {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-auto-flow: dense;
  grid-auto-columns: 1fr 1fr;
}
</style>
