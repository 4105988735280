<template>
  <div>
    <div v-if="!resultFilter.loading">
      <h2>{{ userCount }} Ergebnisse</h2>
      <rg-button
        :disabled="page.current <= 1"
        icon="angle-double-left"
        @click="stride(page.current, 1)"
      />
      <rg-button
        :disabled="page.current <= 1"
        icon="angle-left"
        @click="stride(page.current, page.current - 1)"
      />
      {{ page.current }} von {{ Math.ceil(pages) }}
      <rg-button
        :disabled="page.current >= Math.ceil(pages)"
        icon="angle-right"
        @click="stride(page.current, page.current + 1)"
      />
      <rg-button
        :disabled="page.current >= Math.ceil(pages)"
        icon="angle-double-right"
        @click="stride(page.current, Math.ceil(pages))"
      />
    </div>
    <rg-widget>
      Benutzer
      <template #data>
        <table class="rg-table condensed">
          <thead>
            <th>ID</th>
            <th>Status</th>
            <th>Name</th>
            <th>Account</th>
            <th>E-Mail</th>
            <th>Rollen</th>
          </thead>
          <tr v-for="account in accounts" :key="account.id">
            <td>
              <code>{{ account.id }}</code>
            </td>
            <td>
              <code>{{ account.status ? "aktiv" : "inaktiv" }}</code>
            </td>
            <th>
              {{ account.salutation }}
              {{ account.prename }}
              {{ account.name }}
            </th>
            <td>{{ account.login }}</td>
            <td>{{ account.email }}</td>
            <td>{{ account.roles.join(", ") }}</td>
            <td>
              <router-link :to="'/accounts/' + account.id">
                <rg-button
                  label="Öffnen"
                  type="is-primary is-small"
                  title="Diesen Benutzer bearbeiten"
                />
              </router-link>
            </td>
          </tr>
        </table>
      </template>
    </rg-widget>
  </div>
</template>

<script>
import RgWidget from "@/components/elements/rg-widget.vue";
import { ref } from "@vue/composition-api";
import superagent from "superagent";
import { backendUrl } from "@/constants.js";

export default {
  name: "Companyaccounts",
  components: {
    RgWidget,
  },
  props: { data: { type: Object, default: () => {} } },
  setup(props, context) {
    let accounts = ref([]);
    let resultFilter = ref({
      step: 25,
      limit: 25,
      offset: 0,
      loading: true,
      timeout: null,
    });
    let userCount = ref({});
    let pages = ref({});
    let page = ref({
      current: 1,
    });

    function sortUsers() {
      accounts.value.sort((a, b) => (a.id > b.id ? 1 : -1));
      accounts.value.sort((a, b) => b.status - a.status);
    }

    getData();

    function getData() {
      const gqQueryUsers = `query Query($limit: Int!, $offset: Int!, $id: Int) {
        Company(id: $id) {
          Users(offset: $offset, limit: $limit) {
            id
            prename
            name
            login
            email
            status
            roles
            company
          }
        }
        Statistics {
          totalCompanyUsersCount(companyId: $id)
        }
      }`;

      superagent
        .post(backendUrl)
        .send({
          query: gqQueryUsers,
          variables: {
            id: parseInt(context.root.$route.params.id),
            limit: resultFilter.value.limit,
            offset: resultFilter.value.offset,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          accounts.value = res.body?.data.Company.Users;
          userCount.value = res.body?.data.Statistics.totalCompanyUsersCount;
          pages.value = userCount.value / resultFilter.value.step;
          sortUsers();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          resultFilter.value.loading = false;
        });
    }

    function stride(from, to) {
      page.value.current = to;
      resultFilter.value.offset =
        page.value.current === 1
          ? 0
          : resultFilter.value.step * page.value.current -
            resultFilter.value.step;
      getData();
    }

    return {
      accounts,
      resultFilter,
      page,
      pages,
      userCount,
      stride,
    };
  },
};
</script>

<style lang="scss" module></style>
