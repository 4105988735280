<template>
  <section class="rg-grid">
    <div class="level w12">
      <div>
        <!--Please, do something-->
        <h2>This simply is not working and has to be refactored.exe</h2>
        <br>
      </div>
    </div>
    <div class="form w6">
      <h2>
        <fa
          icon="comment-alt"
          class="fa-fw"
        />&nbsp;Vorhandene Dateien
      </h2>
      <hr>

      <br>
      <br>
    </div>
    <div class="form w6">
      <h2>
        <fa
          icon="file-upload"
          class="fa-fw"
        />&nbsp;Dateien hochladen
      </h2>
      <hr>
      <br>
      <file-pond
        ref="pond"
        name="machineFiles"
        label-idle="Dateien hochladen"
        :allow-multiple="true"
        :allow-paste="false"
        :accepted-file-types="['image/png', 'image/jpeg', 'application/pdf']"
        :files="machineFiles.files"
        :server="fileServer"
      />
    </div>
  </section>
</template>

<script>
import { ref, computed } from "@vue/composition-api";
// import { api } from "@/helper.js";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";

const FilePond = vueFilePond();

export default {
  components: { FilePond },
  beforeRouteLeave(_to, _from, next) {
    next(window.confirm("Formular wirklich verlassen?"));
  },
  props: { data: { type: Object, default: () => {} } },
  setup() {
    // TODO: root is deprecated in Vue3, even do not need store with vuex -> see https://medium.com/@mario.brendel1990/vue-3-the-new-store-a7569d4a546f
    // const token = computed(
    //   () => `Bearer ${context.root.$store.state.hydraToken}`
    // );

    let machineFiles = ref({});

    const fileServer = computed(() => {
      //TODO
      //This cannot be used anymore and has to be refactored. Was wrong the whole time anyway (as /content/file would have been correct)
      // return {
      //   url: api + "/content/files",
      //   process: {
      //     method: "POST",
      //     url: "/",
      //     headers: {
      //       Authorization: token.value,
      //       "x-maschineId": context.root.$route.params.id,
      //     },
      //     onload: (response) => response.data,
      //     onerror: (response) => response.data,
      //   },
      //   revert: null,
      //   restore: null,
      //   load: null,
      //   fetch: null,
      //   patch: null,
      // };
      return false;
    });

    return {
      machineFiles,
      fileServer,
    };
  },
};
</script>

<style src="filepond/dist/filepond.min.css"></style>

<style lang="scss" module>
.file input {
  display: none !important;
}
</style>