<script>
import Vue from 'vue'
import d_pad from '@/components/elements/rg-d-pad'
import dropdown from '@/components/elements/rg-dropdown'
import { getColor } from '@/helper.js'

export default {
  components: { 'rg-d-pad': d_pad, 'rg-dropdown': dropdown },
  props: {
    index: { type: Number, default: 0 },
    prevent: { type: Array, default: () => [] },
    element: { type: Object, default: () => {} },
    selected: { type: Boolean, default: false }
  },
  setup(props, context) {
    function setFocus (input = 'title') {
      try {
        context.refs[input]?.focus()
      } catch (e) {
        console.error(e)
      }
    }
    function toggleComment() {
      context.emit('set-comment', !props.element.showComment)
      if (props.element.showComment) { Vue.nextTick(() => { context.refs['comment'].focus() }) }
    }
    return { getColor, setFocus, toggleComment }
  }
}
</script>