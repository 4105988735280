<template>
  <div :class="$style.login">
    <form
      :class="[$style.form_wrap, 'card']"
      @submit.prevent="login"
    >
      <h1 style="text-align: center">
        Welcome Back
      </h1>
      <br>
      <label>User name</label>
      <input
        v-model="username"
        placeholder="Snoopy"
        required
        type="text"
      >
      <label>Password</label>
      <input
        v-model="password"
        placeholder="Password"
        required
        type="password"
      >
      <br>
      <rg-button
        :disabled="status !== ''"
        style="width: 100%"
        type="is-primary"
      >
        {{ status || "Login" }}
      </rg-button>
    </form>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
// import { randomBytes, createHash, createCipheriv } from "crypto";

export default {
  name: "Login",
  setup(props, context) {
    let username = ref("");
    let password = ref(``);
    let status = ref("");

    async function login() {
      status.value = "Loading…";
      const trimmedUsername = username.value.trim().toLowerCase();
      if (trimmedUsername !== "" && password.value !== "") {
        context.root.$store
          .dispatch("login", {
            username: trimmedUsername,
            password: btoa(password.value),
          })
          .then(() => {
            context.root.$router.push("/machines");
          })
          .catch(() => {
            status.value = "Error";
            setTimeout(() => {
              status.value = "";
            }, 1000);
          });
      }
    }

    return {
      username,
      password,
      status,
      login,
    };
  },
};
</script>

<style lang="scss" module>
.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  button {
    justify-content: center;
  }
  input {
    margin: 5px 0 10px;
    width: 100%;
  }
}

.form_wrap {
  border-radius: 8px;
  padding: 25px 20px 20px;
  width: 100%;
  max-width: 300px;
}
</style>
