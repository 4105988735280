<template>
  <div class="rg-grid">
    <div class="level w12">
      <h2>Stammdaten bearbeiten</h2>
      <div class="level">
        <span>Bearbeitung</span>
        <label
          class="switch"
          style="margin: 0 5px 0 10px"
          @click="showUsers = false"
        >
          <input
            v-model="editable.form"
            type="checkbox"
            @change="toggleEdit($event)"
          />
          <span class="slider" />
        </label>
        <rg-button
          type="is-primary"
          :disabled="!editable.form || !checkFormInput()"
          icon-left="save"
          label="Speichern"
          @click="saveChanges()"
        />
      </div>
    </div>
    <rg-widget
      v-if="showUsers"
      :title="clickedInput === 'manager' ? 'Manager Liste' : 'Pricing Liste'"
      class="w6 form"
      :style="{
        zIndex: 2,
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        width: clickedInput === 'manager' ? '300px' : '650px',
        minHeight: '428px',
      }"
    >
      <template #data>
        <div style="display: flex; align-items: center">
          <input
            v-model="resultFilter.search"
            type="text"
            placeholder="Suche..."
            style="margin-right: 10px"
            @input="dispatchSearch()"
            @keypress.enter="dispatchSearch(0)"
          />
          <rg-button
            type="is-primary"
            label="Schließen"
            style="margin-bottom: 10px"
            @click="showUsers = false"
          />
        </div>
        <div
          v-if="clickedInput === 'manager'"
          style="
            padding: 2px;
            margin-bottom: 4px;
            background-color: #33333333;
            cursor: pointer;
            transition: background-color 0.3s ease;
          "
          @click="
            (editable.data.association_manager_id = null),
              (showUsers = false),
              (editable.selectedUser.value = null)
          "
          @mouseover="hoverEffect($event, '#6b6b6b')"
          @mouseout="hoverEffect($event, '#33333333')"
        >
          None
        </div>
        <div
          v-if="clickedInput === 'pricing'"
          style="
            padding: 2px;
            margin-bottom: 4px;
            background-color: #33333333;
            cursor: pointer;
            transition: background-color 0.3s ease;
          "
          @click="
            (editable.data.association_pricing_id = null),
              (showUsers = false),
              (page.current = 1),
              (editable.selectedPricing.value = null)
          "
          @mouseover="hoverEffect($event, '#6b6b6b')"
          @mouseout="hoverEffect($event, '#33333333')"
        >
          None
        </div>
        <div v-if="clickedInput === 'manager'">
          <div
            v-for="user in users"
            :key="user.id"
            style="
              padding: 2px;
              background-color: #33333333;
              cursor: pointer;
              transition: background-color 0.3s ease;
            "
            @click="
              (editable.data.association_manager_id = user.id),
                (showUsers = false),
                (page.current = 1),
                (editable.selectedUser.value =
                  user.userName + ' (' + user.id + ')')
            "
            @mouseover="hoverEffect($event, '#6b6b6b')"
            @mouseout="hoverEffect($event, '#33333333')"
          >
            {{ user.userName }} ({{ user.id }})
          </div>
        </div>
        <div v-if="clickedInput === 'pricing'">
          <div
            v-for="pricing in pricings"
            :key="pricing.id"
            style="
              padding: 2px;
              background-color: #33333333;
              cursor: pointer;
              transition: background-color 0.3s ease;
            "
            @click="
              (editable.data.association_pricing_id = pricing.id),
                (showUsers = false),
                (editable.selectedPricing.value =
                  pricing.tier +
                  ' - ' +
                  pricing.description +
                  ' (' +
                  pricing.invoice_amount +
                  ' ' +
                  pricing.invoice_currency +
                  ')')
            "
            @mouseover="hoverEffect($event, '#6b6b6b')"
            @mouseout="hoverEffect($event, '#33333333')"
          >
            {{ pricing.tier }} | {{ pricing.description }} ({{
              pricing.invoice_amount
            }}
            {{ pricing.invoice_currency }})
          </div>
        </div>

        <div
          style="display: flex; justify-content: center;, padding: 4px; margin-bottom: 40px; position: absolute; bottom: 0; left: 0; right: 0;"
        >
          <rg-button
            :disabled="page.current <= 1"
            icon="angle-double-left"
            @click="stride(page.current, 1)"
          />
          <rg-button
            :disabled="page.current <= 1"
            icon="angle-left"
            style="padding-left: 3px; margin-left: 3px"
            @click="stride(page.current, page.current - 1)"
          />
          <rg-button
            v-if="clickedInput === 'manager'"
            :disabled="page.current >= Math.ceil(userPages)"
            icon="angle-right"
            style="padding-left: 5px; margin-left: 5px"
            @click="stride(page.current, page.current + 1)"
          />
          <rg-button
            v-if="clickedInput === 'manager'"
            :disabled="page.current >= Math.ceil(userPages)"
            icon="angle-double-right"
            style="padding-left: 3px; margin-left: 3px"
            @click="stride(page.current, Math.ceil(userPages))"
          />
          <rg-button
            v-if="clickedInput === 'pricing'"
            :disabled="page.current >= Math.ceil(pricingPages)"
            icon="angle-right"
            style="padding-left: 5px; margin-left: 5px"
            @click="stride(page.current, page.current + 1)"
          />
          <rg-button
            v-if="clickedInput === 'pricing'"
            :disabled="page.current >= Math.ceil(pricingPages)"
            icon="angle-double-right"
            style="padding-left: 3px; margin-left: 3px"
            @click="stride(page.current, Math.ceil(pricingPages))"
          />
        </div>
        <div
          v-if="clickedInput === 'manager'"
          style="
            display: flex;
            justify-content: space-between;
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 20px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
          "
        >
          <span>{{ totalUsersCount }} Ergebnisse</span>
          <span>{{ page.current }} von {{ Math.ceil(userPages) }}</span>
        </div>
        <div
          v-if="clickedInput === 'pricing'"
          style="
            display: flex;
            justify-content: space-between;
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 25px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
          "
        >
          <span>{{ totalPricingsCount }} Ergebnisse</span>
          <span>{{ page.current }} von {{ Math.ceil(pricingPages) }}</span>
        </div>
      </template>
    </rg-widget>
    <rg-widget title="Allgemein" class="w6 form">
      <template #data>
        <strong class="level"
          ><span>Titel</span>
          <span
            v-if="
              editable.form &&
              formDirty(data.association_title, editable.data.association_title)
            "
            :class="$style.changed"
          />
        </strong>
        <input
          v-if="editable.form"
          v-model="editable.data.association_title"
          type="text"
          @click="showUsers = false"
        />
        <input v-else :value="data.association_title" disabled type="text" />
        <strong class="level"><span>Pricing</span> </strong>
        <input
          v-if="editable.form"
          v-model="editable.selectedPricing.value"
          readonly
          type="text"
          @click="
            (showUsers = true),
              (page.current = 1),
              (resultFilter.search = null),
              stride(page.current, page.current),
              (clickedInput = 'pricing')
          "
        />
        <input v-else :value="selectedPricing" disabled type="text" />
        <strong class="level">
          <span>Manager</span>
        </strong>
        <div v-if="selectedUserStatus === false" style="color: red">
          Dieser User ist deaktiviert!
        </div>
        <input
          v-if="editable.form"
          v-model="editable.selectedUser.value"
          type="text"
          readonly
          @click="
            (showUsers = true),
              (page.current = 1),
              (resultFilter.search = null),
              stride(page.current, page.current),
              (clickedInput = 'manager')
          "
        />
        <input v-else :value="selectedUser" disabled type="text" />
      </template>
    </rg-widget>
    <rg-widget title="Informationen" class="w6 form">
      <template #data>
        <strong class="level"><span>Beschreibung</span> </strong>
        <textarea
          v-if="!editable.form"
          :value="data.association_description"
          disabled
          cols="30"
          rows="3"
        />
        <textarea
          v-else
          v-model="editable.data.association_description"
          cols="30"
          rows="3"
          @click="showUsers = false"
        />
        <strong class="level"><span>Kontaktinformationen</span> </strong>
        <textarea
          v-if="!editable.form"
          :value="data.alternate_contact_information"
          disabled
          cols="30"
          rows="5"
        />
        <textarea
          v-else
          v-model="editable.data.alternate_contact_information"
          cols="30"
          rows="5"
          @click="showUsers = false"
        />
      </template>
    </rg-widget>
    <rg-widget title="Weiteres" class="w6 form">
      <template #data>
        <div class="level">
          <strong><span>Erstellt</span></strong>
          {{
            new Date(data.createdat).toLocaleString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          }}
        </div>
        <div class="level">
          <strong><span>Zuletzt aktualisiert</span></strong>
          {{
            new Date(data.updatedat).toLocaleString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          }}
        </div>
      </template>
    </rg-widget>
  </div>
</template>

<script>
import RgWidget from "@/components/elements/rg-widget.vue";
import { ref, watchEffect } from "@vue/composition-api";
import { formDirty } from "@/helper.js";
import superagent from "superagent";
import { altBackendUrl } from "@/constants.js";
import Vue from "vue";

export default {
  name: "AssociationHome",
  components: {
    RgWidget,
  },
  beforeRouteLeave(to, from, next) {
    next(
      !this.editable.form ||
        window.confirm("Bearbeitung aktiviert. Formular wirklich verlassen?")
    );
  },
  props: {
    data: { type: Object, default: () => {} },
  },
  setup(props, context) {
    let data = ref({
      association_title: "",
      association_description: "",
      association_pricing_id: "",
      association_manager_id: "",
      alternate_contact_information: "",
    });
    let editable = ref({
      loginValidated: null,
      emailValidated: null,
      form: false,
      data: {},
      selectedUser: null,
      selectedPricing: null,
    });
    let page = ref({
      current: 1,
    });
    let resultFilter = ref({
      skip: 8,
      limit: 8,
      search: "",
      offset: 1,
      timeout: null,
      loading: true,
    });
    let users = ref({});
    let selectedUser = ref();
    let selectedUserStatus = ref();
    let totalUsersCount = ref({});
    let userPages = ref({});

    let pricings = ref({});
    let selectedPricing = ref();
    let totalPricingsCount = ref({});
    let pricingPages = ref({});

    let showUsers;
    let clickedInput = ref({});

    watchEffect(() => {
      data.value = props.data;
      if (
        data?.value?.id !== undefined &&
        selectedPricing.value === undefined &&
        selectedUser.value == undefined
      ) {
        getValueData();
      }
    });
    getUsers();
    getPricings();

    const dispatchSearch = (ms = 300) => {
      clearTimeout(resultFilter.value.timeout);
      resultFilter.value.timeout = setTimeout(() => {
        resultFilter.value.loading = true;
        page.value.current = 1;
        if (resultFilter.value.search === "") {
          resultFilter.value.offset = 1;
        }
        if (clickedInput.value === "manager") {
          resultFilter.value.offset = 0;
          getUsers(resultFilter.value.search);
        } else {
          resultFilter.value.offset = 1;
          getPricings(resultFilter.value.search);
        }
      }, resultFilter.value.search?.length && ms);
    };

    function getValueData() {
      const payload = {
        userId: data.value.association_manager_id || null,
        pricingId: data.value.association_pricing_id || 0,
      };
      const gqQueryUsers = `
          query getValueData($userId: Int, $pricingId: Int!) {
            User(userId: $userId) {
              userName
              enabled
            }
            Pricing(pricingId: $pricingId) {
              tier
              description
              invoice_amount
              invoice_currency
            }
          }
        `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryUsers,
          variables: { userId: payload.userId, pricingId: payload.pricingId },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          if (payload.userId === null) {
            selectedPricing.value = `${res.body.data.Pricing.tier} - ${res.body.data.Pricing.description} (${res.body.data.Pricing.invoice_amount} ${res.body.data.Pricing.invoice_currency})`;
          } else {
            selectedUser.value = `${res.body.data.User.userName} (${data.value.association_manager_id})`;
            selectedUserStatus.value = res.body.data.User.enabled;
            selectedPricing.value = `${res.body.data.Pricing.tier} - ${res.body.data.Pricing.description} (${res.body.data.Pricing.invoice_amount} ${res.body.data.Pricing.invoice_currency})`;
          }
        });
    }

    function getUsers(search = resultFilter.value.search) {
      search = search?.length >= 3 ? search : "";
      const gqQueryUsers = `
        query Query($skip: Int!, $limit: Int!, $search: String, $filter: UserFilter) {
          Users(skip: $skip, limit: $limit, search: $search, filter: $filter) {
              nodes{
                id,
                userName,
              }
          }
          Statistics{totalUsersCount(search: $search)}
        }
        `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryUsers,
          variables: {
            skip: resultFilter.value.offset,
            limit: resultFilter.value.limit,
            search: search,
            filter: { roles: ["user"], status: true },
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          users.value = res?.body?.data?.Users?.nodes;
          totalUsersCount.value = res?.body?.data?.Statistics?.totalUsersCount;
          userPages.value = totalUsersCount.value / resultFilter.value.skip;
        })
        .finally(() => {
          resultFilter.value.loading = false;
        });
    }

    function getPricings(search = resultFilter.value.search) {
      search = search?.length >= 2 ? search : "";
      const gqQueryUsers = `
        query Pricings($skip: Int!, $limit: Int!, $search: String) {
          Pricings(skip: $skip, limit: $limit, search: $search) {
            id
            tier
            description
            invoice_amount
            invoice_currency
          }
          Statistics{totalPricingsCount(search: $search, isActive: true)}
        }
        `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryUsers,
          variables: {
            skip: resultFilter.value.offset - 1,
            limit: resultFilter.value.limit,
            search: search,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          pricings.value = res?.body?.data?.Pricings;
          totalPricingsCount.value =
            res?.body?.data?.Statistics?.totalPricingsCount;
          pricingPages.value =
            totalPricingsCount.value / resultFilter.value.skip;
        })
        .finally(() => {
          resultFilter.value.loading = false;
        });
    }

    function stride(from, to) {
      page.value.current = to;
      resultFilter.value.offset =
        page.value.current === 1
          ? 1
          : resultFilter.value.skip * page.value.current -
            resultFilter.value.skip;
      if (clickedInput.value === "manager") {
        getUsers();
      } else {
        getPricings();
      }
    }

    function toggleEdit(val) {
      if (val) {
        editable.value.data = { ...data.value };
        editable.value.selectedUser = { ...selectedUser };
        editable.value.selectedPricing = { ...selectedPricing };
      }
    }

    function checkFormInput() {
      if (editable.value.data.id && editable.value.data.association_title)
        return true;
    }

    function saveChanges() {
      const payload = {
        id: parseInt(editable.value.data.id) || null,
        associationTitle: editable.value.data.association_title || null,
        associationPricingId:
          parseInt(editable.value.data.association_pricing_id) || null,
        associationManagerId:
          parseInt(editable.value.data.association_manager_id) || null,
        associationDescription:
          editable.value.data.association_description || null,
        alternateContactInformation:
          editable.value.data.alternate_contact_information || null,
      };
      const updateCompanyMutation = `mutation Mutation($id: Int, $associationTitle: String!,
         $associationPricingId: Int, $associationManagerId: Int, $associationDescription: String,
         $alternateContactInformation: String) {
        	CompanyAssociations(id: $id, associationTitle: $associationTitle, associationPricingId: $associationPricingId,
          associationManagerId: $associationManagerId, associationDescription: $associationDescription,
          alternateContactInformation: $alternateContactInformation
          ) {
            id
          }
        }`;
      superagent
        .post(altBackendUrl)
        .send({
          query: updateCompanyMutation,
          variables: {
            id: payload.id,
            associationTitle: payload.associationTitle,
            associationPricingId: payload.associationPricingId,
            associationManagerId: payload.associationManagerId,
            associationDescription: payload.associationDescription,
            alternateContactInformation: payload.alternateContactInformation,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((resAssociation) => {
          if (!resAssociation.body?.data?.errors && resAssociation.body?.data) {
            Vue.$toast.open({
              icon: "check",
              message: "Die Gruppe wurde erfolgreich gespeichert.",
            });
            setTimeout(() => {
              context.root.$router.go();
            }, 2000);
          } else {
            Vue.$toast.open({
              icon: "times-circle",
              type: "error",
              message: "FEHLER: Die Gruppe konnte nicht gespeichert werden.",
            });
            editable.value.form = false;
            console.table(payload);
            console.log(resAssociation.body?.data?.errors);
          }
        })
        .catch((err) => {
          Vue.$toast.open({
            icon: "times-circle",
            type: "error",
            message: "FEHLER: Die Gruppe konnte nicht gespeichert werden.",
          });
          editable.value.form = false;
          console.table(payload);
          console.error(err?.response?.body?.errors);
        });
    }

    function hoverEffect(event, color) {
      event.target.style.backgroundColor = color;
    }

    return {
      toggleEdit,
      editable,
      checkFormInput,
      formDirty,
      saveChanges,
      showUsers,
      dispatchSearch,
      page,
      stride,
      hoverEffect,
      clickedInput,
      resultFilter,
      users,
      userPages,
      totalUsersCount,
      pricings,
      pricingPages,
      totalPricingsCount,
      getPricings,
      getUsers,
      selectedUser,
      selectedUserStatus,
      selectedPricing,
    };
  },
};
</script>

<style lang="scss" module>
.detail {
  grid-auto-flow: dense;
}

.changed {
  height: 10px;
  width: 10px;
  background: red;
  display: inline-block;
  border-radius: 5px;
  margin-left: 5px;
}
</style>
