<template>
  <div :class="[(size == 'small') ? $style.small : $style.large, $style.widget, 'card']">
    <div :class="$style.rg_widget__head">
      <h3><slot>{{ title }}</slot></h3>
      <div><slot name="meta" /></div>
    </div>
    <div :class="$style.rg_widget__body">
      <slot name="data">
        fallback
      </slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "RgWidget",
    props: {
      title: { type: String, default: "" },
      size: { type: String, default: "small" }
    },
  }
</script>

<style lang="scss" module>
.rg_widget__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > h3 {
    font-weight: normal;
    font-size: 1.5em;
    margin-bottom: 10px;
  }
}
.rg_widget__body {
  padding: 10px 0 0;
  h3 {
    font-weight: normal;
    font-size: 1.5em;
    margin-bottom: 10px;
  }
}

.widget {
  background: var(--card-light);
  color: var(--text-dark);
  min-height: 100px;
  border-radius: 6px;
  padding: 20px 15px 10px;
}

.large { grid-column: span 2; }
</style>
