<template>
  <div :class="$style.techinput_level">
    <span
      v-for="i in level"
      :key="i"
      :class="$style.techinput_level__dot"
    />
  </div>
</template>

<script>
export default {
  props: {
    level: { type: Number, default: 0 }
  }
}
</script>

<style lang="scss" module>
.techinput_level {
  display: flex;
  align-items: center;
  justify-content: center;
  .techinput_level__dot {
    display: block;
    border-radius: 5px;
    width: 15px;
    height: 10px;
    background: rgba(128,128,128, .5);
    margin: 0 18px;
    &:nth-child(5n+1) { background: var(--level-1) }
    &:nth-child(5n+2) { background: var(--level-2) }
    &:nth-child(5n+3) { background: var(--level-3) }
    &:nth-child(5n+4) { background: var(--level-4) }
    &:nth-child(5n+5) { background: var(--level-5) }
  }
}
</style>