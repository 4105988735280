<template>
  <div class="rg-grid">
    <div class="level w12">
      <h2>Stammdaten bearbeiten</h2>
      <div class="level">
        <span>Bearbeitung</span>
        <label class="switch" style="margin: 0 5px 0 10px">
          <input
            v-model="editable.form"
            type="checkbox"
            @change="toggleEdit($event)"
          />
          <span class="slider" />
        </label>
        <rg-button
          type="is-primary"
          :disabled="!checkFormInput()"
          icon-left="save"
          label="Speichern"
          @click="saveChanges()"
        />
      </div>
    </div>
    <rg-widget title="Allgemein" class="w8 form">
      <template #data>
        <strong class="level"
          ><span>Anrede</span>
          <span
            v-if="
              editable.form &&
              formDirty(data.User.salutation, editable.data.salutation)
            "
            :class="$style.changed"
          />
        </strong>
        <div v-if="!editable.form">
          <select :value="data.User.salutation" disabled class="rg-select">
            <optgroup
              v-for="(group, name) in salutations"
              :key="name"
              :label="name"
            >
              <option
                v-for="option in group"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </optgroup>
          </select>
          <div v-if="!['Herr', 'Frau'].includes(data.User.salutation)">
            <strong><span>Anrede Freitext</span></strong>
            <input :value="data.User.salutation" type="text" disabled />
          </div>
        </div>
        <select v-else v-model="editable.data.salutation" class="rg-select">
          <optgroup
            v-for="(group, name) in salutations"
            :key="name"
            :label="name"
          >
            <option
              v-for="option in group"
              :key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </optgroup>
        </select>
        <div
          v-if="
            editable.form &&
            !['Herr', 'Frau'].includes(editable.data.salutation)
          "
        >
          <strong><span>Anrede Freitext</span></strong>
          <input v-model="editable.data.salutation" type="text" />
        </div>
        <strong class="level"
          ><span>Geschlecht</span>
          <span
            v-if="editable.form && formDirty(data.User.sex, editable.data.sex)"
            :class="$style.changed"
          />
        </strong>
        <select
          v-if="!editable.form"
          :value="data.User.sex"
          disabled
          class="rg-select"
        >
          <optgroup v-for="(group, name) in sexes" :key="name" :label="name">
            <option
              v-for="option in group"
              :key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </optgroup>
        </select>
        <select v-else v-model="editable.data.sex" class="rg-select">
          <optgroup v-for="(group, name) in sexes" :key="name" :label="name">
            <option
              v-for="option in group"
              :key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </optgroup>
        </select>

        <strong class="level"
          ><span>Vorname</span>
          <span
            v-if="
              editable.form &&
              formDirty(data.User.prename, editable.data.prename)
            "
            :class="$style.changed"
          />
        </strong>
        <input
          v-if="editable.form"
          v-model="editable.data.prename"
          type="text"
        />
        <input v-else :value="data.User.prename" disabled type="text" />

        <strong class="level"
          ><span>Nachname</span>
          <span
            v-if="
              editable.form && formDirty(data.User.name, editable.data.name)
            "
            :class="$style.changed"
          />
        </strong>
        <input v-if="editable.form" v-model="editable.data.name" type="text" />
        <input v-else :value="data.User.name" disabled type="text" />

        <strong class="level"
          ><span>E-Mail</span>
          <span
            v-if="
              editable.form && formDirty(data.User.email, editable.data.email)
            "
            :class="$style.changed"
          />
        </strong>
        <span
          v-if="
            editable.form &&
            editable.emailValidated !== null &&
            !editable.emailValidated
          "
          style="color: red"
          >Ungültige E-Mail Adresse</span
        >
        <input v-if="editable.form" v-model="editable.data.email" type="text" />
        <input v-else :value="data.User.email" disabled type="text" />

        <strong class="level">
          <span>Login (Benutzername)</span>
          <span
            v-if="
              editable.form && formDirty(data.User.login, editable.data.login)
            "
            :class="$style.changed"
          />
        </strong>
        <span
          v-if="
            editable.form &&
            formDirty(data.User.login, editable.data.login) &&
            editable.loginValidated !== null &&
            !editable.loginValidated
          "
          style="color: red"
          >Benutzername existiert bereits</span
        >
        <input
          v-if="editable.form"
          v-model="editable.data.login"
          type="text"
          @blur="validateLogin(editable.data.login)"
        />
        <input v-else :value="data.User.login" disabled type="text" />

        <hr />

        <strong class="level"
          ><span>Kommentar</span>
          <span
            v-if="
              editable.form &&
              formDirty(data.User.comment, editable.data.comment)
            "
            :class="$style.changed"
          />
        </strong>
        <input
          v-if="editable.form"
          v-model="editable.data.comment"
          type="text"
        />
        <input v-else :value="data.User.comment" disabled type="text" />

        <strong class="level"
          ><span>Nutzer Rolle</span>
          <span
            v-if="
              editable.form && formDirty(data.User.roles, editable.data.roles)
            "
            :class="$style.changed"
          />
        </strong>
        <select v-if="editable.form" v-model="editable.data.roles" multiple>
          <option
            v-for="role in roles"
            :key="role"
            :value="role"
            @click="changeRoles(role)"
          >
            {{ role }}
          </option>
        </select>
        <select v-else :value="data.User.roles" disabled>
          <option :value="data.User.roles">
            {{ data.User.roles }}
          </option>
        </select>
        <strong class="level"
          ><span>Benutzergruppe</span>
          <span
            v-if="
              editable.form && formDirty(data.User.tier, editable.data.tier)
            "
            :class="$style.changed"
          />
        </strong>
        <select
          v-if="!editable.form"
          :value="data.User.tier"
          disabled
          class="rg-select"
        >
          <option
            v-for="option in tiers"
            :key="option"
            :value="option"
            :selected="option === data.User.tier"
          >
            {{ option }}
          </option>
        </select>
        <select v-else v-model="editable.data.tier" class="rg-select">
          >
          <option
            v-for="option in tiers"
            :key="option"
            :value="option"
            :selected="option === data.User.tier"
          >
            {{ option }}
          </option>
        </select>
      </template>
    </rg-widget>
    <rg-widget title="Informationen" class="w4 form">
      <template #data>
        <div class="level">
          <strong><span>Benutzer erstellt</span></strong>
          {{
            new Date(data.User.createdat).toLocaleString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          }}
        </div>
        <div class="level">
          <strong><span>Letzte Änderung</span></strong>
          {{
            new Date(data.User.updatedat).toLocaleString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          }}
        </div>
        <div class="level">
          <strong><span>Firma des Benutzers</span></strong>
          <router-link replace :to="`/companies/${data.User.Company.id}`">
            {{ data.User.Company.company_title }}
          </router-link>
        </div>
        <div class="level">
          <strong><span>GRANIT Kundennr.</span></strong>
          {{ data.User.Company.granitid }}
        </div>
        <hr />
        <h3>Regionale Einstellungen</h3>
        <strong class="level"
          ><span>Land</span>
          <span
            v-if="
              editable.form &&
              formDirty(data.User.country, editable.data.country)
            "
            :class="$style.changed"
          />
        </strong>
        <select
          v-if="!editable.form"
          :value="data.User.country"
          disabled
          class="rg-select"
        >
          <option
            v-for="option in countries"
            :key="option.id"
            :value="option.country_code"
            :selected="option.country_code === data.User.country"
          >
            {{ option.country_name }} ({{ option.country_code }})
          </option>
        </select>
        <select v-else v-model="editable.data.country" class="rg-select">
          >
          <option
            v-for="option in countries"
            :key="option.id"
            :value="option.country_code"
            :selected="option.country_code === data.User.country"
          >
            {{ option.country_name }} ({{ option.country_code }})
          </option>
        </select>
        <strong class="level"
          ><span>Sprache</span>
          <span
            v-if="
              editable.form &&
              formDirty(
                data.User.language_for_contact.id,
                editable.data.language_for_contact.id
              )
            "
            :class="$style.changed"
          />
        </strong>
        <select
          v-if="!editable.form"
          :value="data.User.language_for_contact.id"
          disabled
          class="rg-select"
        >
          <option
            v-for="option in languages"
            :key="option.id"
            :value="option.id"
            :selected="option.id === data.User.language_for_contact.id"
          >
            {{ option.language_name }} ({{ option.language_code }})
          </option>
        </select>
        <select
          v-else
          v-model="editable.data.language_for_contact.id"
          class="rg-select"
        >
          >
          <option
            v-for="option in languages"
            :key="option.id"
            :value="option.id"
            :selected="option.id === editable.data.language_for_contact.id"
          >
            {{ option.language_name }} ({{ option.language_code }})
          </option>
        </select>
        <hr />
        <h3>Status</h3>
        <div class="level">
          <strong class="level"
            ><span>Account aktiviert</span>
            <span
              v-if="
                editable.form &&
                formDirty(data.User.status, editable.data.status)
              "
              :class="$style.changed"
            />
          </strong>
          <label class="switch">
            <input
              v-model="editable.data.status"
              :disabled="!editable.form"
              type="checkbox"
            />
            <span class="slider" />
          </label>
        </div>
        <div v-if="!data.User.status && data.User.cancelledat" class="level">
          <strong><span>Deaktiviert am</span></strong>
          {{
            new Date(data.User.cancelledat).toLocaleString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          }}
        </div>
        <hr />
        <h3>Support</h3>
        <rg-button
          type="is-primary"
          icon-left="headset"
          label="Ticket erstellen"
          @click="createTicket()"
        />
      </template>
    </rg-widget>
  </div>
</template>

<script>
import { ref, watchEffect, onMounted } from "@vue/composition-api";
import { formDirty } from "@/helper.js";
import superagent from "superagent";
import { backendUrl } from "@/constants.js";
import Vue from "vue";
import { altBackendUrl } from "../../../constants";
// import {MultiSelectPlugin } from '@syncfusion/ej2-vue-dropdowns';
// Vue.use(MultiSelectPlugin)

export default {
  components: {
    "rg-widget": () => import("@/components/elements/rg-widget"),
    // Multiselect,
  },
  beforeRouteLeave(to, from, next) {
    next(
      !this.editable.form ||
        window.confirm("Bearbeitung aktiviert. Formular wirklich verlassen?")
    );
  },
  props: { data: { type: Object, default: () => {} } },
  setup(props, context) {
    let data = ref({});
    let editable = ref({
      loginValidated: null,
      emailValidated: null,
      form: false,
      data: {
        roles: [],
      },
    });
    let salutations = {
      Herr: [{ text: "Herr", value: "Herr" }],
      Frau: [{ text: "Frau", value: "Frau" }],
      Andere: [{ text: "Freitext", value: "" }],
    };
    let sexes = {
      male: [{ text: "männlich", value: "m" }],
      female: [{ text: "weiblich", value: "w" }],
      other: [{ text: "andere", value: "u" }],
    };
    let countries = ref([]);
    let languages = ref([]);
    let tiers = ref([]);
    let roles = ref([]);
    let selectedRoles = ref([]);

    onMounted(() => {
      getCountries();
      getLanguages();
      getTiers();
      getRoles();
    });

    watchEffect(() => {
      data.value = props.data;
      const checkRegEx = /[a-zA-Z0-9_-]*.[@][a-zA-Z0-9_-]*[.][a-z]{2,3}/;
      if (editable.value.data.email === "") {
        editable.value.emailValidated = null;
      } else if (checkRegEx.test(editable.value.data.email)) {
        editable.value.emailValidated = true;
      } else {
        editable.value.emailValidated = false;
      }
    });

    function getCountries() {
      const gqlQueryAppCountries = `
      query Query {
        AppCountries {
          country_code
          country_name
          id
        }
      }
      `;

      superagent
        .post(backendUrl)
        .send({
          query: gqlQueryAppCountries,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          countries.value = res.body.data.AppCountries;
        });
    }

    function getLanguages() {
      const gqQueryAppLanguages = `
      query Query {
        AppLanguages {
          language_name
          id
          language_code
        }
      }
      `;

      superagent
        .post(backendUrl)
        .send({
          query: gqQueryAppLanguages,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          languages.value = res.body.data.AppLanguages;
        });
    }

    function getRoles() {
      const gqQueryRoles = `
      query MyQuery {
        UserRoles {
          role_name
        }
      }
      `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryRoles,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          roles.value = res.body.data.UserRoles.map((o) => o.role_name);
        });
    }

    function changeRoles(role) {
      if (selectedRoles.value.includes(role)) {
        selectedRoles.value = selectedRoles.value.filter(
          (element) => element !== role
        );
      } else {
        selectedRoles.value.push(role);
      }
      editable.value.data.roles = [...selectedRoles.value];
    }

    function getTiers() {
      const gqQueryPricingTiers = `
      query Machine {
        Pricings {
          tier
        }
      }
      `;

      superagent
        .post(backendUrl)
        .send({
          query: gqQueryPricingTiers,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          tiers.value = res.body.data.Pricings.map((pricing) => pricing.tier)
            .filter(
              (value, index, self) =>
                self[index] && self.indexOf(value) === index
            )
            .sort();
        });
    }

    function toggleEdit(val) {
      if (val) {
        editable.value.data = { ...data.value.User };
        selectedRoles.value = [...data.value.User.roles];
      }
    }

    function checkFormInput() {
      if (
        editable.value.data.id &&
        editable.value.data.salutation &&
        editable.value.data.sex &&
        editable.value.data.prename &&
        editable.value.data.name &&
        editable.value.data.email &&
        editable.value.emailValidated &&
        editable.value.data.login &&
        editable.value.loginValidated &&
        editable.value.data.country &&
        // editable.value.data.granitid &&
        editable.value.data.tier &&
        editable.value.data.roles
      )
        return true;
    }
    validateLogin(editable.value.data.login);

    function validateLogin(login) {
      const gqQuery = `query Query($userLogin: String){
              User(login: $userLogin) {
                id
              }
            }`;

      superagent
        .post(backendUrl)
        .send({
          query: gqQuery,
          variables: { userLogin: login },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          editable.value.loginValidated =
            data.value.User.login === editable.value.data.login
              ? true
              : res.body?.data?.User?.id
              ? false
              : true;
        });
    }

    function saveChanges() {
      const payload = {
        roles: editable.value.data.roles || null,
        id: editable.value.data.id || null,
        salutation: editable.value.data.salutation || null,
        sex: editable.value.data.sex || "u",
        prename: editable.value.data.prename || null,
        name: editable.value.data.name || null,
        email: editable.value.data.email || null,
        login: editable.value.data.login || null,
        comment: editable.value.data.comment || null,
        tier: editable.value.data.tier || null,
        country: editable.value.data.country || null,
        status: editable.value.data.status ? true : false,
        cancelledAt: !editable.value.data.status
          ? new Date(Date.now()).toISOString()
          : null,
        languageId: editable.value.data.language_for_contact.id,
        countryName: editable.value.data.country,
      };

      const updateUserMutation = `mutation Mutation($userId: Int!, $userLogin: String, $userRoles: [String!]!, $userEmail: String,
        $userSalutation: String, $userPrename: String, $userName: String, $userSex: String,
        $userTier: String, $userComment: String, $userCountry: String, $userStatus: Boolean, $userCancelledat: DateTime, $languageForContactId: Int) {
          User(
            userId: $userId
            login: $userLogin,
            user_roles: $userRoles,
            email: $userEmail,
            salutation: $userSalutation,
            prename: $userPrename,
            name: $userName,
            sex: $userSex,
            tier: $userTier,
            comment: $userComment,
            country: $userCountry,
            status: $userStatus,
            cancelledat: $userCancelledat,
            language_for_contact_id: $languageForContactId
          ) {
              userName
              email
              id
            }
          }
      `;

      superagent
        .post(altBackendUrl)
        .send({
          query: updateUserMutation,
          variables: {
            userId: payload.id,
            userLogin: payload.login,
            userRoles: payload.roles,
            userEmail: payload.email,
            userSalutation: payload.salutation,
            userPrename: payload.prename,
            userName: payload.name,
            userSex: payload.sex,
            userTier: payload.tier,
            userComment: payload.comment,
            userCountry: payload.country,
            userStatus: payload.status,
            userCancelledat: payload.cancelledAt,
            languageForContactId: payload.languageId,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((resUser) => {
          if (!resUser.body?.data?.errors && resUser.body?.data?.User) {
            Vue.$toast.open({
              icon: "check",
              message: "Der Benutzer wurde erfolgreich gespeichert.",
            });
            setTimeout(() => {
              context.root.$router.go();
            }, 2000);
          } else {
            Vue.$toast.open({
              icon: "times-circle",
              type: "error",
              message: "FEHLER: Der Benutzer konnte nicht gespeichert werden.",
            });
            editable.value.form = false;
          }
        })
        .catch((err) => {
          Vue.$toast.open({
            icon: "times-circle",
            type: "error",
            message: "FEHLER: Der Benutzer konnte nicht gespeichert werden.",
          });
          editable.value.form = false;
          console.error(err?.response?.body?.errors);
        });
    }

    function createTicket(subject = "") {
      window.open(
        `https://frixlab.fricke.de/innovationlab/repguide/support/-/issues/new?issue[title]=${data.value.User.id}%20-%20${data.value.User.login}%20-%20${data.value.User.Company.granitid}%20-%20${subject}`,
        "_blank"
      );
    }
    console.log(data);
    return {
      editable,
      toggleEdit,
      formDirty,
      salutations,
      saveChanges,
      tiers,
      sexes,
      countries,
      checkFormInput,
      validateLogin,
      languages,
      createTicket,
      roles,
      changeRoles,
    };
  },
};
</script>

<style lang="scss" module>
.detail {
  grid-auto-flow: dense;
}

.changed {
  height: 10px;
  width: 10px;
  background: red;
  display: inline-block;
  border-radius: 5px;
  margin-left: 5px;
}
</style>
