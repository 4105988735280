<template>
  <div class="rg-grid">
    <div class="level w12">
      <h2>Berechtigungen</h2>
      <div class="level">
        <rg-button
          type="is-primary"
          icon-left="save"
          label="Speichern"
          @click="saveChanges()"
        />
      </div>
    </div>
    <div
      class="card w6"
      style="border-radius: 10px; padding: 20px; margin-bottom: 10px"
    >
      <br>
      <h3>Module</h3>
      <br>
      <div
        v-for="module in modules"
        :key="module.id"
        style="display: flex; gap: 10px; margin-bottom: 10px"
      >
        <div>
          <label
            class="switch"
            style="margin-right: 5px"
          >
            <input
              type="checkbox"
              :checked="
                permittedModules && permittedModules.includes(module.id)
              "
              @change="changePermission(module.id)"
            >
            <span class="slider" />
          </label>
        </div>
        <div>
          <div>
            <strong>
              <span>{{ module.module_name }}</span>
            </strong>
          </div>
          <div v-if="module.module_description">
            {{ module.module_description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect, onMounted } from "@vue/composition-api";
import superagent from "superagent";
import {backendUrl} from "@/constants.js";
import Vue from "vue";

export default {
  props: { data: { type: Object, default: () => {} } },
  setup(props, context) {
    const machine = ref({});
    // const modules = ref([
    //   { id: 1, key: "rg_default", name: "Basismodul Rep.Guide" },
    // ]);
    const modules = ref([]);
    const permissions = ref([]);
    let permittedModules = ref([]);

    watchEffect(() => {
      machine.value = props.data;
    });

    onMounted(() => {
      fetchModules();
      fetchPermissions();
    });

    function fetchModules() {
      const gqQueryModules = `
      query Query($languageIds: [Int], $countryIds: [Int]) {
        AppModules(language_ids: $languageIds, country_ids: $countryIds) {
          id
          module_key
          module_name
          module_description
          createdat
          updatedat
          country_ids
          language_ids
        }
      }
      `;
      //TODO: add language and country lookup from app_languages and app_countries
      superagent
        .post(backendUrl)
        .send({
          query: gqQueryModules,
          variables: {
            languageIds: null,
            coutryIds: null,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(({ body: moduleList }) => {
          modules.value = moduleList.data.AppModules;
        })
        .catch((err) => {
          Vue.$toast.open(err);
        });
    }

    function fetchPermissions() {
      const gqQueryPermissions = `
      query Query($relationType: String, $relationId: Int) {
        AppPermissions(relation_type: $relationType, relation_id: $relationId) {
          id
          relation_type
          relation_id
          permitted_modules
          createdat
          updatedat
        }
      }
      `;

      superagent
        .post(backendUrl)
        .send({
          query: gqQueryPermissions,
          variables: {
            relationType: "machines",
            relationId: parseInt(machine?.value?.id) || null,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(({ body: perm }) => {
          permissions.value = perm.data.AppPermissions;
          permittedModules.value =
            permissions.value[0]?.permitted_modules || [];
        })
        .catch((err) => {
          Vue.$toast.open(err);
        });
    }

    function changePermission(id) {
      if (!permittedModules.value.includes(id)) {
        permittedModules.value.push(id);
      } else {
        permittedModules.value.splice(permittedModules.value.indexOf(id), 1);
      }
    }

    function saveChanges() {
      const sorted_modules = permittedModules.value.sort((a, b) => a - b);
      if (
        permissions?.value[0]?.relation_id &&
        parseInt(machine?.value?.id) !== permissions?.value[0]?.relation_id
      ) {
        Vue.$toast.open({
          message:
            "Integritätsprüfung fehlgeschlagen: ID der Maschine stimmt nicht mit der zugehörigen ID der Berechtigung überein.",
          icon: "times-circle",
        });
        return false;
      } else {
        const mSavePermissionsMutation = `
        mutation Mutation($appPermissionId: Int, $relationType: String, $relationId: Int, $permittedModules: [Int]) {
          AppPermission(id: $appPermissionId, relation_type: $relationType, relation_id: $relationId, permitted_modules: $permittedModules) {
            id
          }
        }
        `;

        superagent
          .post(backendUrl)
          .send({
            query: mSavePermissionsMutation,
            variables: {
              appPermissionId: permissions.value[0]?.id || -1,
              relationType: "machines",
              relationId: machine.value.id,
              permittedModules: sorted_modules,
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then(() => {
            Vue.$toast.open({
              message: "Die Berechtigungen wurden erfolgreich gespeichert.",
              icon: "check",
            });
          })
          .catch((err) => {
            Vue.$toast.open(err);
          });
      }
    }

    return {
      machine,
      modules,
      permittedModules,
      changePermission,
      saveChanges,
    };
  },
};
</script>