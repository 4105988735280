<script>
import dropdown from "@/components/elements/rg-dropdown";
import { getColor } from "@/helper.js";

export default {
  components: { "rg-dropdown": dropdown },
  props: {
    index: { type: Number, default: 0 },
    prevent: { type: Array, default: () => [] },
    element: { type: Object, default: () => {} },
    selected: { type: Boolean, default: false },
  },
  setup(_, context) {
    function setFocus(input = "title") {
      try {
        context.refs[input]?.focus();
      } catch (e) {
        console.error(e);
      }
    }
    return { getColor, setFocus };
  },
};
</script>
