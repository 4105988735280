<template>
  <div>
    <navigation>
      <div style="display: flex; gap: 5px">
        <div>
          <router-link
            :to="{
              name: 'add_contract',
              params: { company_id: data.company_id },
            }"
          >
            <rg-button
              type="is-primary"
              icon-left="file-contract"
              title="Anlegen"
              label="Anlegen"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
            />
          </router-link>
          <rg-dropdown>
            <template #trigger>
              <rg-button
                icon="chevron-down"
                style="border-top-left-radius: 0; border-bottom-left-radius: 0"
              />
            </template>
            <router-link
              :to="{
                name: 'add_contract',
                params: { company_id: data.company_id },
              }"
            >
              <rg-button icon-left="file-contract" label="Vertrag" />
            </router-link>
            <router-link to="/companies/add">
              <rg-button icon-left="users" label="Unternehmen" />
              <router-link to="/associations/add">
                <rg-button icon-left="building" label="Unternehmensgruppe" />
              </router-link>
              <router-link to="/accounts/add">
                <rg-button icon-left="user-plus" label="Account" />
              </router-link>
            </router-link>
          </rg-dropdown>
        </div>
        <rg-button
          type="is-warning"
          icon-left="folder-minus"
          title="Kündigen"
          label="Kündigen"
          style="color: hsl(348, 100%, 61%)"
          @click="show = true"
        />
        <rg-widget
          v-if="show"
          class="w6 form"
          title="Kündigungsgrund:"
          style="
            z-index: 2;
            position: fixed;
            left: 0;
            top: 0;
            margin-left: 38%;
            margin-top: 5%;
            padding: 20px;
          "
        >
          <template #data>
            <div style="width: 650px; height: 250px">
              <textarea
                v-model="cancellation.reason"
                cols="30"
                rows="10"
                required
              />
              <rg-button
                label="Kündigen"
                type="is-primary"
                style="margin-right: 5px"
                @click="(show = false), cancelContract()"
              />
              <rg-button label="Abbrechen" @click="show = false" />
            </div>
          </template>
        </rg-widget>
      </div>
    </navigation>
    <main :style="show ? { filter: 'blur(5px)' } : ''">
      <h1><fa icon="file-contract" class="fa-fw" />{{ data.contract_type }}</h1>
      <div>
        <code style="font-weight: bold" title="ID">
          Vertrags-ID:
          {{ data.id }}
        </code>
        <span style="opacity: 0.5"> | </span>
        <code style="font-weight: bold" title="Unternehmens-ID">
          {{ companyTitle }}
          (Firmen-ID: {{ data.company_id }})
        </code>
      </div>
      <br />
      <rg-tabs>
        <router-link
          tag="button"
          replace
          :to="'/companies/' + data.company_id + '/contracts'"
        >
          <fa icon="file-contract" />&nbsp;Vertragsübersicht
        </router-link>
        <router-link
          tag="button"
          :to="'/companies/' + data.company_id + '/home'"
        >
          <fa icon="industry" />&nbsp;Zum Unternehmen
        </router-link>
      </rg-tabs>
      <br /><br />
      <router-view :data="data" />
    </main>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import superagent from "superagent";
import { backendUrl, altBackendUrl } from "@/constants.js";
import Vue from "vue";
import dropdown from "@/components/elements/rg-dropdown";
import RgWidget from "../../../components/elements/rg-widget.vue";
import RgButton from "../../../components/elements/rg-button.vue";

export default {
  name: "UsersDetail",
  components: {
    "rg-dropdown": dropdown,
    "rg-tabs": () => import("@/components/elements/rg-tabs"),
    RgWidget,
    RgButton,
  },
  props: {},
  setup(props, context) {
    let data = ref({});
    let show = false;
    let cancellation = ref({});
    const companyTitle = ref("");

    const gqQueryContract = `
      query Query($contractId: Int!) {
        Contract(id: $contractId) {
          id
          company_id
          contract_sequence
          contract_type
          contract_successor
          contract_start
          contract_end
          contract_cancellation_period_end
          contract_annotation
          invoice_amount
          invoice_currency
          invoice_item_number
          contract_options
          createdat
          updatedat
          billing_blocked_until
          free_period_boost
          cancellation_reason
          PaymentExemptions{
            id
            exemption_start
            exemption_end
            exemption_comment
            updatedat
            exemption_granted_by {
              id
              prename
              name
            }
          }
        }
      }
    `;
    superagent
      .post(backendUrl)
      .send({
        query: gqQueryContract,
        variables: { contractId: parseInt(context.root.$route.params.id) },
      })
      .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
      .then((res) => {
        data.value = res.body.data.Contract;
        data.value.companyId = res.body.data.Contract.company_id;
        data.value.free_period_boost = getDuration(
          data.value.free_period_boost || ""
        );
        cancellation.value.reason = data.value.cancellation_reason;
        const gqQueryCompanyTitle = `
          query GetCompanyTitle($companyId: Int!) {
            Company(id: $companyId) {
              title
            }
          }
        `;
        superagent
          .post(altBackendUrl)
          .send({
            query: gqQueryCompanyTitle,
            variables: { companyId: parseInt(data.value.companyId) },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            if (res.body.errors) {
              console.error("GraphQL Errors:", res.body.errors);
              return;
            }
            companyTitle.value = res.body.data.Company.title;
          })
          .catch((err) => {
            console.error(err?.response?.body?.errors);
          });
      })
      .catch((error) => {
        if (error.status === 426) {
          Vue.$toast.open({
            message: "Sitzung abgelaufen. Bitte neu anmelden",
            icon: "exclamation",
            type: "error",
          });
          context.root.$store.dispatch("logout").then(() => {
            context.root.$router.push("/login");
          });
        }
      });

    function cancelContract() {
      let confirmed = null;
      confirmed = confirm(
        `Achtung:
        Hiermit wird der Vetrag mit der ID ${data.value.id}
        zum ${
          data.value.contract_end
            ? new Date(data.value.contract_end).toLocaleString(undefined, {
                timeZone: "UTC",
              })
            : new Date().toLocaleString()
        } (UTC) gekündigt.
        Bitte bestätigen.`
      );
      if (confirmed) {
        console.log(`Let's cancel contract ID ${data.value.id}`);
        const gqMutationCancelContract = `mutation Mutation($contractId: Int, $contractContractAnnotation: String,
          $contractContractEnd: DateTime, $contractContractSuccessor: String, $cancellation_reason: String) {
            Contract(id: $contractId, contract_annotation: $contractContractAnnotation, contract_end: $contractContractEnd,
            contract_successor: $contractContractSuccessor, cancellation_reason: $cancellation_reason) {
              id
            }
          }
        `;
        superagent
          .post(backendUrl)
          .send({
            query: gqMutationCancelContract,
            variables: {
              contractId: parseInt(data.value.id),
              contractContractAnnotation:
                data.value.contract_annotation || null,
              contractContractEnd: data.value.contract_end || new Date(),
              contractContractSuccessor: null,
              cancellation_reason: cancellation.value.reason,
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((resCtr) => {
            if (!resCtr.body?.data?.errors && resCtr.body?.data?.Contract) {
              Vue.$toast.open({
                icon: "check",
                message: "Der Vertrag wurde erfolgreich gekündigt.",
              });
              setTimeout(() => {
                context.root.$router.go();
              }, 2000);
            } else {
              Vue.$toast.open({
                icon: "times-circle",
                type: "error",
                message: "FEHLER: Der Vertrag konnt enicht gekündigt werden",
              });
            }
          })
          .catch((err) => {
            Vue.$toast.open({
              icon: "times-circle",
              type: "error",
              message: "FEHLER: Der Vertrag konnt enicht gekündigt werden",
            });
            console.error(err?.response?.body?.errors);
          });
      } else {
        Vue.$toast.open({
          icon: "dumpster",
          message: "Der Vorgang wurde abgebrochen.",
        });
      }
    }

    function getDuration(isoInterval) {
      if (isoInterval == "") return 0;
      const splitIntervalRegex = new RegExp(
        "^P(\\d+)\\w(\\d+)\\w(\\d+)\\wT(\\d+)\\w(\\d+)\\w(\\d+)\\w$",
        "g"
      );
      let intervals = splitIntervalRegex.exec(isoInterval);
      let duration_in_months =
        parseInt(intervals[1]) * 12 + parseInt(intervals[2]);
      return duration_in_months;
    }

    return {
      data,
      cancelContract,
      companyTitle,
      show,
      cancellation,
    };
  },
};
</script>

<style lang="scss" module>
.detail {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-auto-flow: dense;
  grid-auto-columns: 1fr 1fr;
}
</style>
