const types = [
  { "id": "fec28d9f-f153-4fd3-b213-d524d9c70975", "name": "AUSSENBORDMOTOREN" },
  { "id": "122751d7-2fe2-42b2-867f-d445281f94cc", "name": "BUS" },
  { "id": "07e78aaf-8fdf-4560-a245-5821063f389c", "name": "FABRIKANTEN" },
  { "id": "98144307-a146-41b6-aa87-3c09c9f920b1", "name": "INNENBORDMOTOREN" },
  { "id": "a46b5d6a-cd6d-4bbc-a27c-ed8348dab216", "name": "JET SKI" },
  { "id": "f6da7333-fd5f-47c9-afd0-471ae36421f9", "name": "KEHRMASCHINEN" },
  { "id": "7506d6f3-2980-45e8-bff8-9e34aafef294", "name": "LANDWIRTSCHAFTLICHES FAHRZEUG" },
  { "id": "a338a007-2687-4a75-a6fd-7a1529e6b7ad", "name": "LEICHTFAHRZEUG" },
  { "id": "66ada0e3-4330-416e-815d-a3f0de5c75d5", "name": "LKW" },
  { "id": "3867359e-673f-4405-ab8d-7ecee312e490", "name": "OHW- UND BAUFAHRZEUGE" },
  { "id": "dfe3e21c-4ee9-492b-b103-fa88b3145494", "name": "SPEZIALFAHRZEUG" },
  { "id": "63996a59-719e-465f-95d3-cd680e74e1de", "name": "STATIONÄRMOTOREN" },
  { "id": "080c9d26-60f1-4ad3-8598-d276bed6c2ba", "name": "TRAILER" },
]

const brands = [
  { "id": "d662f774-e426-4c45-8a68-ef48652450b8", "name": "AGCO", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "d8fc07e9-b557-483d-9fe3-1e0911a19b88", "name": "AMAZONE", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "3bc0817a-a8ca-4ee5-a0d9-75a41a02eed9", "name": "ANTONIO CARRARO", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "a1d0ccbe-5510-4b4e-b3a5-3609abfb9903", "name": "ARBOS", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "9b387872-665d-4236-b14c-f14a6fa19ee1", "name": "BCS", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "7d7f4979-56ce-48e8-b675-31ee3c2c01e1", "name": "BELARUS - MTZ", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "98da7f05-0ce9-4155-b2d5-3a7de0733aac", "name": "BOBCAT", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "7b5f5d1c-2fc0-42b3-b936-a38867fc38a8", "name": "BRANSON", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "64629c55-eb0f-412f-b46d-0c88d0aad942", "name": "CARRARO", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "2a335014-5cd9-4f2e-b0d0-672516731526", "name": "CASE iH", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "1610194a-2495-4461-b45b-61e0e8c7162d", "name": "CATERPILLAR", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "db2dccd5-b8b2-4d55-82a9-7080809be2ad", "name": "CHALLENGER", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "8ac01cae-8474-4d74-bd96-8d536bd49405", "name": "CLAAS", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "fee4158d-d0c0-4092-ba51-acde31c41c2e", "name": "DEUTZ-FAHR", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "8654b7e1-abda-4d17-9988-c7b3a9ffd0ec", "name": "DIECI", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "eb46b87e-5fe3-41bb-b815-95e1806b8102", "name": "ENERGREEN", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "fecb0577-2c24-4bea-8046-559ba19204b5", "name": "FARESIN", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "0f337453-e28e-4559-b0fb-b51566a880a1", "name": "FARMTRAC", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "e9d5d79a-f6a4-4afb-b77c-d7a756b9dc95", "name": "FENDT", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "e7d8f644-d3c8-448c-a3be-b49ff17bc6f5", "name": "FERRARI", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "0e6451b7-342e-41d6-9a0e-155cb8b15db9", "name": "FRANZ KLEINE", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "8404636b-4200-407f-9e2e-c6f2f891cb69", "name": "GILIOLI", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "05e5d839-0ce7-4294-a2dd-b360cf805439", "name": "GLEANER", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "f4d2389a-bf82-447d-b253-8280bd7e804b", "name": "GOLDONI", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "d7b023f8-ad2c-4e90-8868-a6c29c096e3d", "name": "GREGOIRE", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "86a27266-7c44-44d6-a05e-7b0932e7148c", "name": "HAGIE", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "fd89e83d-170b-4227-a0b4-4494df014644", "name": "HÜRLIMANN", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "9735ad4d-0bdd-44c4-8e93-cb024fe859aa", "name": "ISEKI", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "f72e3a8d-aec1-409a-9feb-593e3b5c685a", "name": "ISOBUS", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "4a72320f-e11b-4095-9d44-fb920642eeb0", "name": "ITALMIX", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "a26ed4fd-fb6b-4b92-9b7f-fc1a48f45a99", "name": "JALTEST ISOBUS CONTROL", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "c70d1812-0952-445e-b86b-850474c7e008", "name": "JCB", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "12892610-0531-4719-8380-feb0a805b2c3", "name": "JOHN DEERE", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "15091382-9384-45b6-ae70-f3d6ffabe633", "name": "KAMAZ", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "96115a0c-5098-4a93-8eda-b8b0040a06fc", "name": "KIOTI", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "a88844eb-c929-4f45-871c-a32ba46683d4", "name": "KRAMER", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "0766303c-76c9-4f1a-8e9b-d9941a3af5ed", "name": "KRONE", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "cb1f60c8-41f4-4225-a373-02789d93fcf4", "name": "KUBOTA", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "0e939eb5-313b-4fb7-8b86-c11da229a34c", "name": "KUHN", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "452f59e7-bfed-4cc0-a5a8-6c9f57d26f59", "name": "KVERNELAND", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "da7f027d-31f6-4b1a-a100-5b9a5eaeeeae", "name": "LAMBORGHINI", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "a67bbb22-617f-40e5-ad82-50886f3d9f02", "name": "LANDINI", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "5f47a6b6-32d8-469c-bb36-9870fc6bd754", "name": "LAVERDA", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "0041479a-3980-4103-83be-8cda28d6c776", "name": "LINDNER", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "a8966026-ec90-4170-9aee-6dcf154e8bb8", "name": "LS", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "42946a52-ca67-44ec-af4e-fcffb5c01623", "name": "LUCAS G", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "aad262a2-b8ba-40a6-bbbb-f693a6a3a1e2", "name": "MAC DON", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "f59e6643-c020-4880-8495-bfaa026f8754", "name": "MAHINDRA", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "d322f74d-7cda-4275-a141-0f09bcd8990b", "name": "MANITOU", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "48514f42-76d7-44ed-a121-f114bf379759", "name": "MASSEY FERGUSON", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "ec807a0c-0337-45d1-855f-69da319c5c8f", "name": "MATRIX", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "b8947f44-4b9b-43ce-bf95-3a0938c72af2", "name": "MAZZOTTI", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "f945c2e5-0893-4fb6-bd72-1a0c6c6baeb8", "name": "McCORMICK", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "aef7103a-921e-4a1d-91cb-9b6d325cf5e2", "name": "MERCEDES-BENZ", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "3e07799a-4d30-4441-93f6-d4548676fe0a", "name": "MERLO", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "2b3bd3a9-ccfd-40dc-8d52-be614809fe29", "name": "MTS-SANDEI", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "43a697b2-5f4a-4f2b-9b63-7b794124fc46", "name": "NEW HOLLAND", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "4fee81c8-feab-4197-9f1f-38b4fddf1b53", "name": "PASQUALI", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "1a3d4fd9-f76e-4a45-9ea2-0954e462cb07", "name": "PELLENC", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "017b9378-f8e5-44db-a01b-08dabaef5894", "name": "RENAULT", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "86a70338-8746-4aa0-b78e-a7edef19abd9", "name": "ROGATOR", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "f386d112-7915-4367-83c0-0cd55985f72e", "name": "ROPA", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "c2baf952-f11f-44fe-bc6a-7cf4e7cde12f", "name": "ROSTSELMASH", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "69229444-6bde-4a6c-8f13-6a31c747ec55", "name": "SAE STANDARD", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "10142a09-3758-4523-81af-6c6a21e1b99b", "name": "SAME", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "e9035da0-ce83-4161-979f-0f9c6e96151d", "name": "SCHÄFFER", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "8c2b0868-fb30-48b3-864b-fb6b08a5f443", "name": "SITREX - AGM", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "7b479164-aec9-4cdb-b551-c69dfc73fc7c", "name": "SPRA-COUPE", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "badc1492-d053-4cc3-aac6-40ca80041bf7", "name": "STEYR", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "7493d5ab-0c0a-4253-83fb-feabed08c01e", "name": "STORTI", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "91c42a7e-4b92-49e1-8420-8f7a02111c5d", "name": "TATOMA", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "21cb9053-a737-4ebb-982f-5ea9c9700283", "name": "TECNOMA", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "07de5553-9646-4baa-92a1-da0cf498362b", "name": "TERRAGATOR", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "7c843faf-8753-4652-ad12-d06b24fb0f73", "name": "TERRION", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "a82360d2-425d-4a6f-9de0-d59a448901ce", "name": "TYM", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "695dba79-8956-4cd2-b774-23cc1ec15fee", "name": "URSUS", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "9e709830-0842-4c78-aba7-4905b4966f45", "name": "VALPADANA", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "b77ae549-1c3f-4d59-bdab-66837415700c", "name": "VALTRA", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "fe7a48af-13ea-4dbc-9362-29e3e52e4aad", "name": "VERSATILE", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "5d02a206-7de7-4250-a8c6-6190988833a4", "name": "WEIDEMANN", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "950e9917-a63f-4eaf-a4ae-39151c34a4b1", "name": "YANMAR", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "a19a5444-e0ae-49ea-b7ab-2342bca8a40b", "name": "ZETOR", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" },
  { "id": "0198a97f-a0c2-4011-99d1-1554808dfb37", "name": "ZOOMLION", "vehicleTypeId": "7506d6f3-2980-45e8-bff8-9e34aafef294" }
]

export { types, brands }