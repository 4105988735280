<template>
  <button
    :class="[type, {'is-round': round}]"
    v-bind="$attrs"
    @click="$emit('click', $event)"
  >
    <fa
      v-if="iconLeft"
      class="fa-fw icon-left"
      :icon="iconLeft"
    />
    <template v-if="icon">
      <fa-layers
        v-if="icon === 'jaltest'"
        class="fa-fw"
      >
        <fa
          icon="circle"
          transform="shrink-3 left-1.5 up-1.5"
        />
        <fa
          icon="circle"
          transform="right-5 down-5 shrink-10"
        />
      </fa-layers>
      <fa
        v-else
        class="fa-fw"
        :icon="icon"
      />
    </template>
    <slot v-else>
      {{ label }}
    </slot>
    <fa
      v-if="iconRight"
      class="fa-fw icon-right"
      :icon="iconRight"
    />
    <transition name="appear">
      <span
        v-if="notice"
        class="button-notice"
      >{{ notice }}</span>
    </transition>
  </button>
</template>

<script>

export default {
  props: {
    round: { type: Boolean, default: false },
    label: { type: String, default: null },
    iconLeft: { type: [String, Array], default: null },
    iconRight: { type: [String, Array], default: null },
    icon: { type: [String, Array], default: null },
    type: { type: [String, Object, Array], default: null },
    notice: { type: [String, Number], default: null },
  }
}
</script>

<style lang="scss">
button, .button {
  -webkit-app-region: no-drag;
  color: var(--text-dark);
  display: inline-flex;
  padding: 10px 8px;
  border-radius: 8px;
  font-size: 1em;
  line-height: 1;
  font-family: Roboto;
  background: var(--black-2);
  transition: background .1s ease;
  border: none;
  cursor: not-allowed;
  position: relative;
  text-decoration: none;
  &:focus { outline: none!important; }

  svg { opacity: .7 }

  .button-notice {
    position: absolute;
    top: -8px;
    right: -8px;
    z-index: 999999;
    background: red;
    border-radius: 10px;
    font-size: .8em;
    height: 20px;
    min-width: 20px;
    padding: 4px 4px;
    pointer-events: none;
    color: white;
  }
  .icon-left {margin-right: 5px}
  .icon-right {margin-left: 5px}
  &.is-danger {
    color: hsl(348, 100%, 61%);
  }
  &.is-primary {
    background: #1575f7;
    color: white;
  }
  &.is-flagged {
    background: #ff3a97;
    color: white;
  }
  &.is-round {
    border-radius: 2em;
  }
  &.is-small {
    font-size: .8em;
    padding: 8px 8px!important;
  }
  &.is-large {
    font-size: 1.2em;
    padding: 12px 16px;
  }
  &.is-block {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  &:not([disabled]) {
    cursor: pointer!important;
    &:hover {
      filter: brightness(1.1);
      z-index: 999;
    }
    &:active {
      transition: none;
      filter: brightness(.8)
    }
  }
  &[disabled] {
    filter: saturate(.5);
    opacity: .8;
    svg { opacity: .5 }
  }
}

.appear-enter-active, .appear-leave-active { transform: scale(1); transition: all .2s ease!important; }
.appear-enter, .appear-leave-to { opacity: 0; transform: scale(.5)!important }
</style>