var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.element)?_c('div',{class:[
    _vm.$style.techinput,
    _vm.$style.techinput_group,
    _vm.selected ? _vm.$style.is_selected : null ],on:{"click":function($event){return _vm.$emit('select', _vm.element.line)}}},[_c('code',{class:_vm.$style.techinput_index},[_vm._v(_vm._s(_vm.element.line + 1))]),_c('div',{staticClass:"flex-center",style:('color: ' + _vm.getColor(_vm.element.level))},[_c('fa',{staticClass:"fa-lg",attrs:{"icon":"folder"}})],1),_c('rg-button',{attrs:{"tabindex":"-1","icon":"flag","type":{ 'is-flagged': _vm.element.flagged },"disabled":""}}),_c('div',{style:({
      border: '1px solid var(--card-light-stack)',
      background: 'var(--input-background)',
      padding: '8px',
      borderRadius: '8px',
      fontSize: '1em',
      lineHeight: '1em',
      color: 'var(--text-dark)',
      userSelect: 'all',
      cursor: 'text',
    })},[_vm._v(" "+_vm._s(_vm.element.title)+" ")]),_c('rg-button',{attrs:{"icon":"copy","title":"Gruppe kopieren"},on:{"click":function($event){return _vm.$emit('copy')}}}),_c('div',{staticStyle:{"display":"grid","place-content":"center","opacity":"0.2"}},[_c('fa',{staticClass:"fa-fw fa-lg",attrs:{"icon":"arrows-alt"}})],1),_c('p',{style:({
      margin: 0,
      color: _vm.element.description ? 'black' : 'gray',
      opacity: _vm.element.description ? 1 : 0.5,
    })},[_c('fa',{staticClass:"fa-fw",attrs:{"icon":"info-circle"}}),_vm._v(" "+_vm._s(_vm.element.description || "Keine Beschreibung")+" ")],1),_c('p',{style:({
      margin: 0,
      color: _vm.element.comment ? '#1575f7' : 'gray',
      opacity: _vm.element.comment ? 1 : 0.5,
    })},[_c('fa',{staticClass:"fa-fw",attrs:{"icon":"comment"}}),_vm._v(" "+_vm._s(_vm.element.comment || "Kein Kommentar")+" ")],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }