import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import home from "@/pages/index";
import login from "@/pages/loginButWithACaptialL";

import machines from "@/pages/machines/_index";
import machine from "@/pages/machines/machine/_index";
import machine_add from "@/pages/machines/add";
import machine_home from "@/pages/machines/machine/machine_home";
import machine_type from "@/pages/machines/machine/machine_type";
import machine_tech from "@/pages/machines/machine/machine_tech";
import machine_tabs from "@/pages/machines/machine/machine_tabs";
import machine_plan from "@/pages/machines/machine/machine_plan";
import machine_parts from "@/pages/machines/machine/machine_parts";

import machine_files from "@/pages/machines/machine/machine_files";

import machine_jalt from "@/pages/machines/machine/machine_jalt";
import machine_varta from "@/pages/machines/machine/machine_varta";

import machine_perm from "@/pages/machines/machine/machine_perm";

import machine_cnct from "@/pages/machines/machine/machine_cnct";

import machine_tech_input from "@/pages/machines/machine/machine_tech_input";
import machine_tech_preview from "@/pages/machines/machine/machine_tech_preview";

import machinetabs from "@/pages/machinetabs/_index";
import machinetab from "@/pages/machinetabs/machinetab/_index";
import machinetab_add from "@/pages/machinetabs/add";

import accounts from "@/pages/accounts/_index";
import account from "@/pages/accounts/account/_index";
import account_add from "@/pages/accounts/account/account_add";
import account_home from "@/pages/accounts/account/account_home";
import account_perm from "@/pages/accounts/account/account_perm";
import account_scty from "@/pages/accounts/account/account_security";

import companies from "@/pages/companies/_index";
import company from "@/pages/companies/company/_index";
import company_add from "@/pages/companies/company/company_add";
import company_home from "@/pages/companies/company/company_home";
import company_acts from "@/pages/companies/company/company_acts";
import company_crts from "@/pages/companies/company/company_crts";

import associations from "@/pages/associations/_index";
import association from "@/pages/associations/association/_index";
import association_home from "@/pages/associations/association/association_home";
import association_add from "@/pages/associations/association/association_add";

import contract from "@/pages/contracts/contract/_index";
import contract_add from "@/pages/contracts/contract/contract_add";
import contract_home from "@/pages/contracts/contract/contract_home";

import pricings from "@/pages/pricings/_index";
import pricing from "@/pages/pricings/pricing";

import permissions from "@/pages/permissions/_index";
// import permission from '@/pages/permissions/permission'

// import jaltest from "@/pages/jaltest/_index";

import error from "@/pages/FourOFour";

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", component: home },
    {
      path: "/login",
      name: "Login",
      component: login,
      meta: { layout: "base" },
    },

    { path: "/machines", component: machines },
    { path: "/machines/add", component: machine_add },
    {
      path: "/machines/:id(\\d+)",
      redirect: "/machines/:id(\\d+)/home",
      component: machine,
      children: [
        { path: "home", component: machine_home },
        { path: "type", component: machine_type },
        { path: "tech", component: machine_tech },
        { path: "tabs", component: machine_tabs },
        { path: "plan", component: machine_plan },
        { path: "spareparts", component: machine_parts }, 

        { path: "files", component: machine_files },

        { path: "jaltest", component: machine_jalt },
        { path: "varta", component: machine_varta },

        { path: "permissions", component: machine_perm },

        { path: "connections", component: machine_cnct },
      ],
    },
    {
      path: "/machines/:id(\\d+)/tech/:version(\\d+)",
      component: machine_tech_input,
    },
    {
      path: "/machines/:id(\\d+)/tech/*",
      redirect: "/machines/:id(\\d+)/tech",
    },
    {
      path: "/machines/:id(\\d+)/preview/:version(\\d+)",
      component: machine_tech_preview,
    },
    {
      path: "/machines/:id(\\d+)/preview/*",
      redirect: "/machines/:id(\\d+)/tech",
    },
    { path: "/machines/:id(\\d+)/*", redirect: "/machines/:id(\\d+)/home" },
    { path: "/machines/*", redirect: "/machines" },

    { path: "/machinetabs", component: machinetabs },
    { path: "/machinetabs/:id(\\d+)", component: machinetab },
    { path: "/machinetabs/add", component: machinetab_add },

    { path: "/machinetabs/*", redirect: "/machinetabs" },

    { path: "/accounts", component: accounts },
    {
      path: "/accounts/:id(\\d+)",
      redirect: "/accounts/:id(\\d+)/home",
      component: account,
      children: [
        { path: "home", component: account_home },
        { path: "perm", component: account_perm },
        { path: "security", component: account_scty },
      ],
    },
    { path: "/accounts/add", component: account_add, name:"add_account" },
    { path: "/accounts/:id(\\d+)/*", redirect: "/accounts/:id(\\d+)/home" },
    { path: "/accounts/*", redirect: "/accounts" },

    { path: "/companies", component: companies },
    {
      path: "/companies/:id(\\d+)",
      redirect: "/companies/:id(\\d+)/home",
      component: company,
      children: [
        { path: "home", component: company_home },
        { path: "accounts", component: company_acts },
        { path: "contracts", component: company_crts },
      ],
    },
    { path: "/companies/add", component: company_add },
    { path: "/companies/:id(\\d+)/*", redirect: "/companies/:id(\\d+)/home" },
    { path: "/companies/*", redirect: "/companies" },



    { path: "/associations", component: associations },
    {
      path: "/associations/:id(\\d+)",
      redirect: "/associations/:id(\\d+)/home",
      component: association,
      children: [
        { path: "home", component: association_home },
      ],
    },
    { path: "/associations/add", component: association_add },

    { path: "/contracts", redirect: "/companies" },
    {
      path: "/contracts/:id(\\d+)",
      redirect: "/contracts/:id(\\d+)/home",
      component: contract,
      children: [{ path: "home", component: contract_home }],
    },
    { path: "/contracts/add", component: contract_add, name:"add_contract" },
    { path: "/contracts/:id(\\d+)/*", redirect: "/contracts/:id(\\d+)/home" },
    { path: "/contracts/*", redirect: "/contracts" },

    { path: "/pricings", component: pricings },
    { path: "/pricings/:id(\\d+)", component: pricing },
    { path: "/pricings/*", redirect: "/pricings" },

    { path: "/permissions", component: permissions },
    // { path: '/permission/:id(\\d+)', component: permission },
    { path: "/permissions/*", redirect: "/permissions" },

    // { path: "/jaltest", component: jaltest },
    // { path: '/jaltest/:id(\\d+)', component: jaltest },
    // { path: "/jaltest/*", redirect: "/jaltest" },

    { path: "/*", component: error },
  ],
});

router.beforeEach((to, _from, next) => {
  const isAuthenticated = localStorage.getItem("user");
  to.path !== "/login" && !isAuthenticated ? next("/login") : next();
});

export default router;
