<template>
  <section class="rg-grid" style="position: relative">
    <div class="w12 level">
      <div>
        <h2>Spare Part Files</h2>
      </div>
      <div>
        <rg-button
          label="Neue Ersatzteile hochladen"
          icon-left="plus"
          type="is-primary"
          @click="newPartsFile.modalOpen = true"
        />
      </div>
    </div>

    <section class="w12">
      <article
        v-for="oemSet in oemSets"
        :key="oemSet.id"
        class="card"
        :style="{
          padding: '10px',
          borderRadius: '8px',
          marginBottom: '32px',
        }"
      >
        <header
          :style="{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }"
        >
          <h2>"{{ oemSet.set_name }}"</h2>
          <div>
            <rg-button
              label="Löschen"
              icon-left="trash"
              type="is-danger"
              @click="deletePartsFile(oemSet.id)"
            />
          </div>
        </header>
        <div class="form">
          <hr />
        </div>
        <div class="rg-grid">
          <div class="w6">
            <em
              :style="{
                textTransform: 'uppercase',
                fontSize: '1rem',
                color: 'var(--black-6)',
                letterSpacing: '0.1rem',
                fontStyle: 'normal',
              }"
            >
              Meta-informationen
            </em>
            <table style="width: 100%; table-layout: fixed; text-align: left">
              <tr>
                <th style="width: 160px; vertical-align: top">
                  <h3>Oem Set ID</h3>
                </th>
                <td>
                  <code style="font-size: 1rem">
                    {{ oemSet.id }}
                  </code>
                </td>
              </tr>
              <tr>
                <th style="width: 160px; vertical-align: top">
                  <h3>Eingabesprache</h3>
                </th>
                <td v-if="!languages || !languages.length">
                  {{
                    languages.find(
                      (l) => l.language_code == oemSet.Language.language_code
                    ).language_name
                  }}
                </td>
                <td v-else>Sprach-ID {{ oemSet.Language.language_name }}</td>
              </tr>
              <tr>
                <th style="width: 160px; vertical-align: top">
                  <h3>Quelle der Daten</h3>
                </th>
                <td>
                  <a
                    v-if="oemSet.set_sources.length != 0"
                    target="_blank"
                    :href="oemSet.source"
                    rel="noopener noreferrer"
                  >
                    {{ oemSet.set_sources[0] }}
                  </a>
                  <span v-else style="color: var(--black-5)">
                    Keine Quelle angegeben
                  </span>
                </td>
              </tr>
              <tr>
                <th style="width: 160px; vertical-align: top">
                  <h3>Erstellt am</h3>
                </th>
                <td>
                  {{ new Date(oemSet.createdat).toLocaleString("de-DE") }}
                </td>
              </tr>
              <tr>
                <th style="width: 160px; vertical-align: top">
                  <h3>Überschrieben am</h3>
                </th>
                <td>
                  <template v-if="oemSet.created_at !== oemSet.updated_at">
                    {{ new Date(oemSet.updatedat).toLocaleString("de-DE") }}
                  </template>
                  <span v-else style="color: var(--black-5)"> Nie </span>
                </td>
              </tr>
            </table>
          </div>
          <div class="w6">
            <em
              :style="{
                textTransform: 'uppercase',
                fontSize: '1rem',
                color: 'var(--black-6)',
                letterSpacing: '0.1rem',
                fontStyle: 'normal',
              }"
            >
              Statistiken
            </em>
            <table style="width: 100%; table-layout: fixed; text-align: left">
              <tr>
                <th style="width: 160px; vertical-align: top">
                  <h3>Components</h3>
                </th>
                <td>
                  <h3>{{ components.length }} Stück</h3>
                </td>
              </tr>
              <tr>
                <th style="width: 160px; vertical-align: top">
                  <h3>Parts</h3>
                </th>
                <td>
                  <h3>
                    {{ componentParts.length }} Einträge auf {{ 0 }} Gruppen
                    verteilt
                  </h3>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </article>
      <div
        v-if="!oemSets || !oemSets.length"
        style="text-align: center; padding: 20px; color: #666"
      >
        <p>Keine Ersatzteile vorhanden.</p>
      </div>
    </section>

    <transition name="opacity">
      <section v-if="newPartsFile.modalOpen" :class="[$style.modal, 'card']">
        <div class="form">
          <h2>Neue Ersatzteile hochladen</h2>
          <hr />
          <strong>Ersatzteile file titel</strong>
          <input v-model="newPartsFile.data.set_name" type="text" />

          <strong>Eingabesprache der Excel-Datei</strong>
          <select
            v-if="languages"
            v-model="newPartsFile.data.language"
            class="rg-select"
          >
            <option
              v-for="option in languages"
              :key="option.id"
              :value="option.id"
              :selected="option.id === newPartsFile.data.language"
            >
              {{ option.language_name }} ({{ option.language_code }})
            </option>
          </select>

          <strong> Quelle der Daten </strong>
          <input
            v-model="newPartsFile.data.set_sources"
            type="text"
            placeholder="Vollständiger Link zum Gitlab-Ticket"
          />

          <strong> Excel-Datei </strong>
          <div
            :class="[
              'file-upload-container',
              { 'is-dragging': newPartsFile.dragging },
            ]"
            @drag.stop.prevent
            @dragstart.stop.prevent
            @dragenter.stop.prevent="newPartsFile.dragging = true"
            @dragover.stop.prevent="newPartsFile.dragging = true"
            @dragend.stop.prevent="newPartsFile.dragging = false"
            @dragleave.stop.prevent="newPartsFile.dragging = false"
            @drop.stop.prevent="handleFile"
          >
            <input
              id="file"
              type="file"
              accept=".xls,.xlsx"
              @change="handleFile"
            />
            <label for="file" class="file-upload">
              <span class="file-upload-select">Datei auswählen</span> oder
              <span class="file-upload-drop">hier ablegen</span>
            </label>
          </div>
          <small v-if="newPartsFile.data.file" style="font-family: monospace">
            {{ newPartsFile.data.file.name }}
          </small>
        </div>
        <template v-if="newPartsFile.errors.length">
          <p
            v-for="error in newPartsFile.errors"
            :key="error.message"
            style="color: red"
          >
            {{ error.message }}
          </p>
        </template>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            gap: 5px;
            margin-top: 10px;
          "
        >
          <rg-button
            label="Abbrechen"
            type="is-danger"
            @click="abortNewPartsFileCreation()"
          />
          <rg-button
            label="Speichern"
            type="is-primary"
            :disabled="!newPartsFileValid || newPartsFile.loading"
            @click="saveNewPartsFile()"
          />
        </div>
      </section>
    </transition>
  </section>
</template>

<script>
import { ref, watchEffect, computed, watch } from "@vue/composition-api";
import { generateStatus } from "@/helper.js";
import Vue from "vue";
import superagent from "superagent";
import { altBackendUrl } from "@/constants.js";

const EXCEL_FILE_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export default {
  props: { data: { type: Object, default: () => {} } },
  setup(props, context) {
    let machine = ref({});
    let languages = ref([]);
    let spareparts = ref([]);
    let components = ref([]);
    let componentParts = ref([]);
    let oemSets = ref([]);
    let newPartsFile = ref({
      modalOpen: false,
      loading: false,
      dragging: false,
      data: {
        id: "",
        language: "",
        set_name: "",
        set_description: "",
        set_sources: [],
        file: null,
      },
      errors: [],
    });
    const newPartsFileValid = computed(() => {
      if (
        !newPartsFile.value.data.language ||
        !newPartsFile.value.data.set_name ||
        !newPartsFile.value.data.file
      )
        return false;

      return true;
    });

    const abortNewPartsFileCreation = () => {
      if (
        newPartsFile.value.data.set_name === "" &&
        newPartsFile.value.data.set_sources === [] &&
        newPartsFile.value.data.file === null
      )
        return (newPartsFile.value.modalOpen = false);

      if (window.confirm("Änderungen werden verworfen. Wirklich abbrechen?")) {
        newPartsFile.value.modalOpen = false;
        newPartsFile.value.data = {
          language: 1,
          set_name: "",
          set_sources: [],
          file: null,
        };
      }
    };

    const saveNewPartsFile = () => {
      newPartsFile.value.loading = true;
      const FORM_DATA = new FormData();

      FORM_DATA.append(
        "operations",
        JSON.stringify({
          query: `
          mutation mSparePartsFileUpload($file: File!, $machineId: Int!, $languageId: Int!, $fileTitle: String!) {
            SparePartsFileUpload(file: $file, machineId: $machineId, languageId: $languageId, fileTitle: $fileTitle)
          }
        `,
          variables: {
            file: null,
            machineId: parseInt(machine.value.id),
            languageId: parseInt(newPartsFile.value.data.language),
            fileTitle: newPartsFile.value.data.set_name,
          },
        })
      );
      FORM_DATA.append("map", JSON.stringify({ 0: ["variables.file"] }));
      FORM_DATA.append("0", newPartsFile.value.data.file);

      superagent
        .post(altBackendUrl)
        .send(FORM_DATA)
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          if (res.body.errors) {
            newPartsFile.value.data.file = null;
            newPartsFile.value.errors = res.body.errors;
            newPartsFile.value.loading = false;
          } else {
            Vue.$toast.open(
              generateStatus(res.status, {
                ok: "Ersatzteile gespeichert. Lade neu...",
              })
            );

            setTimeout(() => {
              context.root.$router.go();
            }, 1000);
          }
        })
        .catch((err) => {
          newPartsFile.value.data.file = null;
          newPartsFile.value.errors = err?.response?.body?.errors;
          newPartsFile.value.loading = false;
        });
    };

    const deletePartsFile = () => {
      Vue.$toast.open("Diese Funktion folgt in einem späteren Update.");
    };

    const handleFile = (e) => {
      newPartsFile.value.dragging = false;
      const FILE = e.dataTransfer?.files?.[0] || e.target.files?.[0];

      if (FILE.type === EXCEL_FILE_TYPE) {
        newPartsFile.value.data.file = FILE;
        newPartsFile.value.errors = [];
      } else {
        Vue.$toast.open({
          message:
            "Unbekanntes Dateiformat. Bitte nur Excel-Dateien hochladen.",
          icon: "exclamation-triangle",
          type: "is-danger",
        });
      }
    };

    function fetchData() {
      const gqQueryMachineParts = `
        query Query($machineId: Int!) {
          AppLanguages {
            language_name
            id
            language_code
          }
          Machine(id: $machineId) {
            Contents {
              __typename
            ... on SparePartsContent {
                id
                OemMachineSets {
                  id
                  machine_id
                  set_annotations
                  updatedat
                  Set {
                    id
                    set_description
                    set_name
                    set_sources
                    updatedat
                    createdat
                    Language {
                      id
                      language_code
                      language_name
                    }
                  }
                  Components {
                    id
                    oem_component_info
                    oem_component_title
                    updatedat
                    OemSetComponentParts {
                      id
                      oem_diagram_information
                      Part {
                        is_pseudo_part
                        oem_part_info
                        oem_part_name
                        oem_part_number
                        oem_part_tags
                      }
                    }
                  }
                }
              }
}
          }
        }
      `;

      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryMachineParts,
          variables: { machineId: Number.parseInt(machine?.value?.id) },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(({ body }) => {
          languages.value = body.data.AppLanguages.sort((a, b) => {
            return a.language_name.localeCompare(b.language_name);
          });
          const content = body.data.Machine?.Contents.filter((content) => {
            return content["__typename"] === "SparePartsContent";
          });
          if (content && content.length > 0) {
            const firstContent = content[0];
            for (const machineSet of firstContent.OemMachineSets) {
              oemSets.value.push(machineSet.Set);
              components.value.push(machineSet.Components);
              componentParts.value.push(
                machineSet?.Components[0]?.OemSetComponentParts
              );
            }
          }
        });
    }

    watch(machine, () => {
      fetchData();
    });

    watchEffect(() => {
      machine.value = props.data;
    });

    return {
      newPartsFile,
      newPartsFileValid,
      abortNewPartsFileCreation,
      saveNewPartsFile,
      deletePartsFile,
      handleFile,
      components,
      componentParts,
      oemSets,
      languages,
      machine,
      spareparts,
    };
  },
};
</script>

<style lang="scss">
.file-upload-container {
  display: flex;
  input {
    display: none !important;
  }
  .file-upload {
    cursor: pointer;
    width: 100%;
    background-color: var(--input-background);
    outline: 1px dashed var(--black-7);
    outline-offset: -6px;
    border-radius: 8px;
    padding: 24px 16px;
    text-align: center;
    color: var(--black-7);
    transition: all 0.2s ease;
    span {
      display: inline-block;
      transform: translateY(0);
      transition: all 0.2s ease;
      text-decoration: underline;
      text-decoration-color: transparent;
    }
  }
  &.is-dragging {
    cursor: copy;
    .file-upload-drop {
      color: var(--black-O);
      transform: translateY(-1px);
      text-decoration-color: var(--black-O);
    }
    .file-upload {
      outline: 1px dashed white;
    }
  }
  &:not(.is-dragging):hover {
    .file-upload-select {
      color: var(--black-O);
      transform: translateY(-1px);
      text-decoration-color: var(--black-O);
    }
  }
}
</style>

<style lang="scss" module>
.modal {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999999999;
  width: 600px;
  max-width: 90vw;
  border-radius: 16px;
  padding: 25px;
}
</style>
