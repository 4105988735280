<template>
  <div>
    <navigation>
      <div style="display: flex; gap: 5px">
        <div>
          <router-link to="/accounts/add">
            <rg-button
              type="is-primary"
              icon-left="user-plus"
              title="Anlegen"
              label="Anlegen"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
            />
          </router-link>
          <rg-dropdown>
            <template
              #trigger
            >
              <rg-button
                icon="chevron-down"
                style="border-top-left-radius: 0; border-bottom-left-radius: 0"
              />
            </template>
            <router-link to="/accounts/add">
              <rg-button
                icon-left="user-plus"
                label="Account"
              />
            </router-link>
            <router-link to="/companies/add">
              <rg-button
                icon-left="industry"
                label="Unternehmen"
              />
            </router-link>
            <router-link to="/associations/add">
              <rg-button
                icon-left="building"
                label="Unternehmensgruppe"
              />
            </router-link>
          </rg-dropdown>
        </div>
        <a
          v-if="data.User"
          target="_blank"
          :href="`https://metabase.repguide.de/question/389-visited-machines-of-a-specific-company?granit_id=${data.User.Company.granitid}`"
        >
          <rg-button
            icon-left="tractor"
            label="Maschinenhistorie"
          />
        </a>
      </div>
    </navigation>
    <div v-if="loading">
      LOADING
    </div>
    <main v-else>
      <h1>
        <fa
          :icon="
            data.User.status
              ? data.User.reset_password
                ? 'user-lock'
                : 'user-check'
              : 'user-times'
          "
          class="fa-fw"
        />
        Profil von
        {{ data.User.prename }}
        {{ data.User.name }}
        <code>{{ data.User.Company.granitid }} </code>
      </h1>
      <p v-if="data.User.reset_password">
        <fa
          icon="exclamation-triangle"
          class="fa-fw"
        />
        Für diesen Benutzer wurde ein Passwortwechsel angefordert und noch nicht
        durchgeführt.
      </p>
      <br>
      <rg-tabs>
        <router-link
          tag="button"
          replace
          :to="'/accounts/' + $route.params.id + '/home'"
        >
          <fa icon="user" />&nbsp;Übersicht
        </router-link>
        <router-link
          tag="button"
          replace
          :to="'/accounts/' + $route.params.id + '/security'"
        >
          <fa icon="lock" />&nbsp;Sicherheit
        </router-link>
        <router-link
          tag="button"
          replace
          :to="'/accounts/' + $route.params.id + '/perm'"
        >
          <fa icon="shield-alt" />&nbsp;Berechtigungen
        </router-link>
      </rg-tabs>
      <br><br>
      <router-view :data="data" />
    </main>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import superagent from "superagent";
import {backendUrl} from "@/constants.js";
import dropdown from "@/components/elements/rg-dropdown";
// import Vue from "vue";
// import { api } from "@/helper.js";

export default {
  name: "UsersDetail",
  components: {
    "rg-dropdown": dropdown,
    "rg-tabs": () => import("@/components/elements/rg-tabs"),
  },
  props: {},
  setup(props, context) {
    let data = ref({});
    let loading = ref(true);

    const gqQuery = `query Query($userId: Int!){
                User(id: $userId) {
                  id
                  login
                  email
                  salutation
                  prename
                  name
                  roles
                  sex
                  status
                  createdat
                  updatedat
                  tier
                  comment
                  activatedat
                  cancelledat
                  reset_password
                  language_for_contact {
                    language_name
                    id
                    language_code
                  }
                  Company {
                    id
                    company_title
                    granitid
                  }
                  country
                }
              }`;

    superagent
      .post(backendUrl)
      .send({
        query: gqQuery,
        variables: { userId: parseInt(context.root.$route.params.id) },
      })
      .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
      .then((res) => {
        loading.value = false;
        data.value = res.body.data;
      });

    return { data, loading };
  },
};
</script>

<style lang="scss" module>
.detail {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-auto-flow: dense;
  grid-auto-columns: 1fr 1fr;
}
</style>
