<template>
  <div :class="[$style.machine_compact, (active ? $style.machine_compact__active : null)]">
    <p style="display: flex">
      <span>
        <code class="tag">{{ machine.id }}</code>&nbsp;
        <strong>{{ machine.title }}</strong> <br>
        <code>{{ machine.slug }}</code>
      </span>
    </p>
    <div :class="$style.machine_compact__actions">
      <rg-button
        v-if="search"
        :type="{'is-primary': parent}"
        icon="search"
        @click="$emit('search', machine.id)"
      />
      <router-link :to="'/machines/'+machine.id+'/home'">
        <rg-button
          type="is-primary"
          label="Öffnen"
          icon-right="chevron-right"
          @click="$emit('open', machine.id)"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    search: { type: Boolean, default: false },
    parent: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
    machine: { type: Object, default: () => {} }
  }
}
</script>

<style lang="scss" module>
.machine_compact {
  border-radius: 8px;
  border: 2px solid grey;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a { margin-left: 5px }
  }
  &__active {
    border-color: var(--primary);
    background: var(--black-1)
  }
}
</style>