import Component from "./Component.vue";

const Api = (Vue, globalOptions = {}) => {
  return {
    open(options) {
      let message;
      if (typeof options === "string") message = options;

      const defaultOptions = { message };

      const propsData = Object.assign(
        {},
        defaultOptions,
        globalOptions,
        options
      );

      return new (Vue.extend(Component))({
        el: document.createElement("div"),
        propsData,
      });
    },
    clear() {
      new Vue().$emit("toast-clear");
    },
  };
};

export default Api;
