<template>
  <div>
    <navigation />
    <main class="rg-grid">
      <div class="level w12">
        <h2><fa icon="folder-open" />&nbsp;Neuen Maschinentab anlegen</h2>
        <rg-button
          type="is-primary"
          icon-left="save"
          label="Speichern"
          @click="saveChanges()"
        />
      </div>

      <rg-widget
        title="Allgemein"
        class="w6 form"
      >
        <template #data>
          <strong>Titel</strong>
          <input
            v-model="tab.tab_title"
            :class="{ 'has-danger-glow': !tab.tab_title.length }"
            type="text"
          >

          <strong>Untertitel</strong>
          <input
            v-model="tab.tab_subtitle"
            type="text"
          >

          <strong>Typ</strong>
          <input
            v-model="tab.tab_type"
            :class="{ 'has-danger-glow': !tab.tab_type.length }"
            type="text"
          >

          <strong>Untertyp</strong>
          <input
            v-model="tab.tab_subtype"
            :class="{ 'has-danger-glow': !tab.tab_subtype.length }"
            type="text"
          >

          <strong>Position</strong>
          <input
            v-model="tab.tab_priority"
            type="text"
          >
        </template>
      </rg-widget>

      <rg-widget
        title="Darstellung"
        class="w6 form"
      >
        <template #data>
          <strong>Icon</strong>
          <input
            v-model="tab.tab_icon"
            :class="{ 'has-danger-glow': !tab.tab_icon.length }"
            type="text"
          >

          <div>
            <a
              style="
                display: inline-block;
                transform: translateY(-15px);
                margin-bottom: 10px;
              "
              href="http://fontawesome.com/icons"
              target="_blank"
              rel="noopener noreferrer"
            >
              <small>Liste der Unterstützen Icons</small>
            </a>
          </div>

          <strong>Tag</strong>
          <input
            v-model="tab.tab_tag"
            type="text"
          >

          <strong>Unternehmensfarbe</strong>
          <input
            v-model="tab.tab_color"
            type="color"
          >

          <br>
          <h4>Vorschau</h4>
          <br>
          <div
            class="w12"
            style="
              border: 1px solid var(--black-4);
              border-radius: 4px;
              padding: 50px 0;
              text-align: center;
            "
          >
            <span v-if="!tab.tab_icon" />
            <fa-layers v-else-if="tab.tab_icon === 'jaltest'">
              <fa
                icon="circle"
                transform="shrink-3 left-1.5 up-1.5"
              />
              <fa
                icon="circle"
                transform="right-5 down-5 shrink-10"
              />
            </fa-layers>
            <fa
              v-else
              :icon="tab.tab_icon"
              class="fa-fw"
            />&nbsp;{{
              tab.tab_title
            }}
            <span
              v-if="tab.tab_tag"
              class="tag"
            >{{ tab.tab_tag }}</span>
          </div>
        </template>
      </rg-widget>

      <rg-widget
        title="Berechtigungen"
        class="w6 form"
      >
        <template #meta>
          <fa
            icon="info"
            style="margin-right: 10px"
          />Kommasepariert
        </template>
        <template #data>
          {{ tab.tab_permissions.contracts }}
          <strong>Verträge</strong>
          <input
            v-model="tab.tab_permissions.contracts"
            type="text"
          >

          <strong>Gruppen</strong>
          <input
            v-model="tab.tab_permissions.tiers"
            type="text"
          >

          <strong>Benutzer</strong>
          <input
            v-model="tab.tab_permissions.users"
            type="text"
          >
          <strong class="level"><span>Module</span> </strong>
          <br>
          <div
            v-for="module in modules"
            :key="module.id"
            style="display: flex; gap: 10px; margin-bottom: 10px"
          >
            <div>
              <label
                class="switch"
                style="margin-right: 5px"
              >
                <input
                  type="checkbox"
                  :checked="
                    permittedModules && permittedModules.includes(module.id)
                  "
                  @change="changePermission(module.id)"
                >
                <span class="slider" />
              </label>
            </div>
            <div>
              <div>
                <strong>
                  <span>{{ module.module_name }}</span>
                </strong>
              </div>
              <div v-if="module.module_description">
                {{ module.module_description }}
              </div>
            </div>
          </div>
        </template>
      </rg-widget>
    </main>
  </div>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import superagent from "superagent";
import {backendUrl} from "@/constants.js";
import Vue from "vue";

export default {
  name: "Machinetab",
  components: {
    "rg-widget": () => import("@/components/elements/rg-widget"),
  },

  setup(props, context) {
    onMounted(() => {
      fetchModules();
      changePermission(1);
    });

    let tab = ref({
      tab_title: "",
      tab_subtitle: "",
      tab_type: "",
      tab_subtype: "",
      tab_priority: 10,
      tab_icon: "",
      tab_color: "#e84910",
      tab_tag: "",
      tab_permissions: {
        contracts: "",
        tiers: "",
        users: "",
      },
    });
    let modules = ref([]);
    let permittedModules = ref([]);

    function saveChanges() {
      const payload = {
        tab_title: tab.value.tab_title || null,
        tab_subtitle: tab.value.tab_subtitle || null,
        tab_type: tab.value.tab_type || null,
        tab_subtype: tab.value.tab_subtype || null,
        tab_priority: parseInt(tab.value.tab_priority) || null,
        tab_icon: tab.value.tab_icon || null,
        tab_color: tab.value.tab_color || null,
        tab_tag: tab.value.tab_tag || null,
        tab_permissions: JSON.stringify({
          // woooOoWOWww
          tiers: tab.value.tab_permissions.tiers.trim()
            ? tab.value.tab_permissions.tiers
                .split(",")
                .filter((e) => e)
                .map((e) => e.trim())
            : [],
          users: tab.value.tab_permissions.users.trim()
            ? tab.value.tab_permissions.users
                .split(",")
                .filter((e) => e)
                .map((e) => parseInt(e.trim()))
            : [],
          contracts: tab.value.tab_permissions.contracts.trim()
            ? tab.value.tab_permissions.contracts
                .split(",")
                .filter((e) => e)
                .map((e) => e.trim())
            : [],
        }),
      };

      const gqAddTabTypeMutation = `
      mutation Mutation($tabTypeId: Int!, $tabType: String, $tabSubtype: String, $tabTitle: String, $tabIcon: String,
      $tabSubtitle: String, $tabColor: String, $tabTag: String, $tabPriority: Int, $tabPermissions: JSON) {
        TabType(id: $tabTypeId, tab_type: $tabType, tab_subtype: $tabSubtype, tab_title: $tabTitle, tab_icon: $tabIcon,
        tab_subtitle: $tabSubtitle, tab_color: $tabColor, tab_tag: $tabTag, tab_priority: $tabPriority,
        tab_permissions: $tabPermissions) {
          id
        }
      }
      `;

      superagent
        .post(backendUrl)
        .send({
          query: gqAddTabTypeMutation,
          variables: {
            tabTypeId: -1,
            tabType: payload.tab_type,
            tabSubtype: payload.tab_subtype,
            tabTitle: payload.tab_title,
            tabIcon: payload.tab_icon,
            tabSubtitle: payload.tab_subtitle,
            tabColor: payload.tab_color,
            tabTag: payload.tab_tag,
            tabPriority: payload.tab_priority,
            tabPermissions: payload.tab_permissions,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          const tabId = res.body.data?.TabType?.id;
          const sorted_modules = permittedModules.value.sort((a, b) => a - b);
          Vue.$toast.open({
            message:
              "Änderungen erfolgreich gespeichert. Speichere Modul-Berechtigungen...",
            icon: "hourglass-half",
          });

          const mSavePermissionsMutation = `
              mutation Mutation($appPermissionId: Int, $relationType: String, $relationId: Int, $permittedModules: [Int]) {
                AppPermission(id: $appPermissionId, relation_type: $relationType, relation_id: $relationId, permitted_modules: $permittedModules) {
                  id
                }
              }
              `;

          superagent
            .post(backendUrl)
            .send({
              query: mSavePermissionsMutation,
              variables: {
                appPermissionId: -1,
                relationType: "machine_tab_types",
                relationId: tabId,
                permittedModules: sorted_modules,
              },
            })
            .set(
              "Authorization",
              `Bearer ${context.root.$store.state.user.apiToken}`
            )
            .then(() => {
              Vue.$toast.open({
                message:
                  "Die Modulberechtigungen wurden erfolgreich gespeichert. Lade neu...",
                icon: "check",
              });
              setTimeout(() => {
                context.root.$router.go();
              }, 2000);
            })
            .catch((err) => {
              Vue.$toast.open({
                message: `Fehler beim Speichern der Berechtigungen: ${err}`,
                icon: "exclamation",
              });
            });
        })
        .catch((error) => {
          Vue.$toast.open({
            message:
              error.status === 426
                ? "Sitzung abgelaufen. Bitte neu anmelden"
                : "Netzwerkfehler " + (error.status || ""),
            icon: "exclamation",
            type: "error",
          });
        });
    }

    function fetchModules() {
      const gqQueryModules = `
      query Query($languageIds: [Int], $countryIds: [Int]) {
        AppModules(language_ids: $languageIds, country_ids: $countryIds) {
          id
          module_key
          module_name
          module_description
          createdat
          updatedat
          country_ids
          language_ids
        }
      }
      `;
      //TODO: add language and country lookup from app_languages and app_countries
      superagent
        .post(backendUrl)
        .send({
          query: gqQueryModules,
          variables: {
            languageIds: null,
            coutryIds: null,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(({ body: moduleList }) => {
          modules.value = moduleList.data.AppModules;
        })
        .catch((err) => {
          Vue.$toast.open(err);
        });
    }

    function changePermission(id) {
      if (!permittedModules.value.includes(id)) {
        permittedModules.value.push(id);
      } else {
        permittedModules.value.splice(permittedModules.value.indexOf(id), 1);
      }
    }

    return { tab, saveChanges, modules, permittedModules, changePermission };
  },
};
</script>

<style lang="scss" module>
.detail {
  grid-auto-flow: dense;
}

.changed {
  height: 10px;
  width: 10px;
  background: red;
  display: inline-block;
  border-radius: 5px;
  margin-left: 5px;
}
</style>
