<template>
  <h1>
    <fa icon="search" />
    404 NOT FOUND
  </h1>
</template>

<script>
  export default {
    name: "FourOFour"
  }
</script>

<style scoped>

</style>