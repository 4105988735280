const rules = [
  "<strong>Erstes Element</strong> muss auf <strong>Level 0</strong>",
  "<strong>Titel</strong> dürfen <strong>nicht leer</strong> sein",
  "<strong>Attributwerte</strong> dürfen <strong>nicht leer</strong> sein",
  "<strong>Attribute</strong> dürfen <strong>nicht auf Level 0</strong> sein",
  "<strong>Attribute</strong> dürfen <strong>keine Unterelemente</strong> haben",
  "<strong>Gruppen</strong> dürfen <strong>nicht leer</strong> sein",
  "<strong>Elemente</strong> dürfen <strong>nicht doppelt eingerückt</strong> werden",
  "<strong>Texteingaben</strong> dürfen <strong>keine Sonder-Leerzeichen</strong> enthalten",
  "<strong>Attribute</strong> dürfen <strong>nicht vom Typ Markdown</strong> sein",
];

/* check if attribute is on level 0 */
const rule__AttributeLevel = (element) => {
  try {
    if (element.is === "attribute" && element.level === 0) {
      pushError("Attribute dürfen nicht auf Level 0");
    }
  } catch (e) {
    pushError(e, true);
  }
};

/* check if element has a title */
const rule__TitleMandatory = (element) => {
  try {
    if (!element.title && element.type !== 'markdown') {
      pushError("Titel müssen gesetzt sein");
    }
  } catch (e) {
    pushError(e, true);
  }
};

/* check if attribute has a value */
const rule__ValueMandatory = (element) => {
  try {
    if (
      element.is === "attribute" &&
      (element.value == null || element.value === "")
    ) {
      pushError("Attributwerte müssen gesetzt sein");
    }
  } catch (e) {
    pushError(e, true);
  }
};

/* check if attribute is childless */
const rule__AttributeChildless = (element) => {
  try {
    if (element.is === "attribute" && element.next?.level > element.level) {
      pushError("Attribut darf keine Unterelemente haben", true);
    }
  } catch (e) {
    pushError(e, true);
  }
};

/* check if element is only 1 level higher as the previous meaningful level */
const rule__NoDoubleIndent = (element) => {
  try {
    if (element.level > previousIndent + 1) {
      pushError("Element doppelt eingerückt");
    }
  } catch (e) {
    pushError(e, true);
  }
};

/* check if first element is on level 0 */
const rule__StartOnLevel0 = (element) => {
  try {
    if (!element.prev && element.level !== 0) {
      pushError("Erstes Element nicht auf Level 0");
    }
  } catch (e) {
    pushError(e, true);
  }
};

/* check if groups have children */
const rule__NoEmptyGroups = (element) => {
  try {
    if (
      element.next &&
      element.is === "group" &&
      element.next.level === element.level
    ) {
      pushError("Keine leeren Gruppen");
    }
  } catch (e) {
    pushError(e, true);
  }
};

/* check if inputs only contain normal spaces and printable chars */
const rule__NoSpecialChars = (element) => {
  try {
    const FIELDS = [
      { key: "value", label: "Wert" },
      { key: "title", label: "Titel" },
      { key: "unit", label: "Einheit" },
      { key: "description", label: "Beschreibung" },
    ]
    const ERRORS = [];

    FIELDS.forEach(FIELD => {
        const REPLACED = element[FIELD.key]
          // match all space characters and non-printable characters
          // NOTE: https://flaviocopes.com/non-printable-ascii-characters/
          // eslint-disable-next-line no-control-regex
          ?.toString().match(/[\s\u0000-\u001F]/g)
          // discard all regular spaces (\u0020 / char-code 32)
          ?.filter(space => space.charCodeAt(0) !== 32)
          // replace all non-regular spaces with an exclamation mark
          ?.reduce((a, b) => a.replace(b, '⚠️'), element[FIELD.key])

        if (REPLACED && REPLACED !== element[FIELD.key]) {
          ERRORS.push(`${FIELD.label}: "${REPLACED}"`);
        }
    });

    if (ERRORS.length) {
      pushError("Enthält ein oder mehrere Sonder-Leerzeichen. Kein Handlungsbedarf: Es werden automatisch normale Leerzeichen gespeichert.");
      ERRORS.forEach(ERROR => { pushError(ERROR) });
    }
  } catch (e) {
    pushError(e, true);
  }
};

/* check if there are markdown attributes */
const rule__NoMarkdown = (element) => {
  try {
    if (element.is === "attribute" && element.type === "markdown") {
      pushError("Markdown muss konvertiert werden");
    }
  } catch (e) {
    pushError(e, true);
  }
};

let audit = [];
let previousIndent = 0;

function pushError(msg, fatal = false) {
  if (msg) {
    audit[audit.length - 1].errors.push(msg);
    audit[audit.length - 1].fatal = fatal;
    audit.errors += 1;
    audit.fatal += fatal ? 1 : 0;
  }
}

const validate = (elements) => {
  return new Promise((resolve, reject) => {
    audit = [];
    audit.errors = 0;
    audit.fatal = 0;
    audit.performance = performance.now();
    previousIndent = 0;

    elements.forEach((element, index) => {
      try {
        element.prev = !index
          ? null
          : {
              level: elements[index - 1].level,
              is: elements[index - 1].is,
            };
        element.next =
          index === elements.length - 1
            ? null
            : {
                level: elements[index + 1].level,
                is: elements[index + 1].is,
              };
        audit.push({
          id: "audit_" + element.id,
          is: element.is,
          title: element.title,
          line: index,
          errors: [],
          fatal: false,
        });
        if (element.prev && element.level !== element.prev.level) {
          previousIndent = element.prev.level;
        }

        rule__AttributeLevel(element);
        rule__TitleMandatory(element);
        rule__ValueMandatory(element);
        rule__AttributeChildless(element);
        rule__NoDoubleIndent(element);
        rule__StartOnLevel0(element);
        rule__NoEmptyGroups(element);
        rule__NoSpecialChars(element);
        rule__NoMarkdown(element);
      } catch (error) {
        reject({ element, line: index, error });
      }
    });
    audit.performance = Math.ceil(performance.now() - audit.performance);
    resolve(audit);
  });
};

module.exports = { validate, rules };
