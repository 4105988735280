<template>
  <div :class="$style.control_bubble">
    <rg-button
      style="pointer-events:none"
      tabindex="-1"
      round
      icon="times"
    />
    <rg-button
      tabindex="-1"
      type="is-primary"
      icon="file"
      title="Attribut einfügen"
      @click="$emit('create', 'attribute')"
    />
    <rg-button
      tabindex="-1"
      type="is-primary"
      icon="folder"
      title="Gruppe einfügen"
      @click="$emit('create', 'group')"
    />
    <rg-button
      tabindex="-1"
      type="is-primary"
      icon="copy"
      :disabled="!$store.state.copyPasta"
      :title="!$store.state.copyPasta.length ? '' : ($store.state.copyPasta[0].is === 'group' ? 'Gruppe \'' : 'Attribut \'') + $store.state.copyPasta[0].title + '\' hier einfügen'"
      @click="$emit('paste')"
    />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" module>
.control_bubble {
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  right: -55px;
  bottom: -25px;
  z-index: 99999999;
  padding: 5px;
  background: var(--bg-primary);
  border-radius: 20px;
  transition: all .1s ease;
  button:not(:first-of-type) {
    margin-right: 5px;
    opacity: 0;
    transition: opacity .1s ease;
  }
  button:first-of-type {
    transform: rotate(-45deg);
    border-top-left-radius: 8px;
    transition: border-radius .1s ease;
    span { transform: rotate(45deg); }
  }

  overflow: hidden;
  width: 50px;
  &:hover {
    background: var(--text-light);
    border-radius: 11px 20px 20px 11px;
    width: 169px;
    box-shadow: 0 4px 8px rgba(0,0,0,.2);
    button { opacity: 1; }
    button:first-of-type {
      border-top-left-radius: 2em;
    }
  }
}
</style>