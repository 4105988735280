<template>
  <div class="tabs">
    <slot />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.tabs {
  margin: 10px 0;
  a, button {
    background: none;
    border-radius: 0;
    display: inline-block;
    padding: 7px 15px!important;
    line-height: 1em;
    text-decoration: none;
    border-bottom: 2px solid var(--card-light-stack);
    transition: border .1s ease;
    &.router-link-active {
      border-color: var(--primary);
    }
  }
}
</style>