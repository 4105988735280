<template>
  <div
    v-if="element"
    :class="[
      $style.techinput, $style.techinput_attribute,
      (selected ? $style.is_selected : null),
    ]"
    @click="$emit('select', element.line)"
  >
    <div :class="$style.techinput_attribute_editorial">
      <code :class="$style.techinput_index">{{ element.line + 1 }}</code>
      <div
        class="flex-center"
        :style="{
          color: getColor(element.level),
          width: '36px',
          height: '36px'
        }"
      >
        <fa
          class="fa-lg" 
          :title="
            element.type === 'widget' ? 'Widget' :
            element.type === 'seperator' ? 'Trennlinie' :
            element.category"
          :icon="(
            element.type === 'widget' ? 'shapes' :
            element.type === 'seperator' ? 'ruler-vertical' :
            element.category === 'Technische Daten' ? 'server' :
            element.category === 'Anzugswerte' ? 'wrench' :
            element.category === 'Füllmengen' ? 'oil-can' :
            element.category === 'Klebstoffe & Dichtmittel' ? 'spray-can' :
            element.category === 'Fehlersuche' ? 'search' :
            element.category === 'Anleitungen' ? 'list-ol' :
            element.category === 'Einstellwerte' ? 'sliders-h' :
            element.category === 'Spezialwerkzeuge' ? 'tools' :
            'file'
          )"
        />
      </div>
      <rg-button
        tabindex="-1"
        icon="flag"
        :type="{'is-flagged': element.flagged}"
        disabled
      />
    </div>
    <rg-button
      icon="copy"
      title="Element kopieren"
      @click="$emit('copy')"
    />
    <div style="display: grid; place-content: center; opacity: 0.2">
      <fa
        class="fa-fw fa-lg"
        icon="arrows-alt"
      />
    </div>

    <div :class="[$style.techinput_attribute_main, $style['type-'+element.type]]">
      <template v-if="element.type === 'string'">
        <div
          :class="$style.fakeInput"
          :title="element.title"
        >
          {{ element.title || 'Titel' }}
        </div>
        <div
          :class="$style.fakeInput"
          :title="element.unit"
        >
          {{ element.unit || 'Einheit' }}
        </div>
        <div
          :class="$style.fakeInput"
          :title="element.value"
        >
          {{ element.value || 'Wert' }}
        </div>
        <div
          :class="$style.fakeInput"
          :title="element.category"
        >
          {{ element.category || 'Kategorie' }}
        </div>
      </template>
      <template v-if="['markdown', 'html'].includes(element.type)">
        <div
          :class="$style.fakeInput"
          :title="element.title"
        >
          {{ element.title || 'Titel' }}
        </div>
        <div
          :class="$style.fakeInput"
          :title="element.unit"
        >
          {{ element.unit || 'Einheit' }}
        </div>
        <div
          :class="$style.fakeInput"
          :title="element.category"
        >
          {{ element.category || 'Kategorie' }}
        </div>
      </template>
      <template v-if="element.type === 'widget'">
        <div
          :class="$style.fakeInput"
          :title="element.title"
        >
          {{ element.title || 'Titel' }}
        </div>
        <div
          :class="$style.fakeInput"
          :title="element.value"
        >
          {{ element.value || 'Wert' }}
        </div>
      </template>
      <template v-if="element.type === 'seperator'">
        <div class="form">
          <hr>
        </div>
        <div
          :class="$style.fakeInput"
          :title="element.title"
        >
          {{ element.title || 'Titel' }}
        </div>
        <div
          :class="$style.fakeInput"
          :title="element.value"
        >
          {{ element.value || 'Wert' }}
        </div>
        <div class="form">
          <hr>
        </div>
      </template>
      <template v-if="element.type === 'link'">
        <div
          :class="$style.fakeInput"
          :title="element.title"
        >
          {{ element.title || 'Titel' }}
        </div>
        <div
          :class="$style.fakeInput"
          :title="element.value"
        >
          {{ element.value || 'Wert' }}
        </div>
        <div class="flex-center">
          <span :style="{ margin: '0 10px 0 5px', lineHeight: '1.1em' }">
            Link öffnet<br><strong>neuen Tab</strong>
          </span>
          <label class="switch">
            <input
              v-model="element.unit"
              type="checkbox"
              value="Bike"
            >
            <span class="slider" />
          </label>
        </div>
      </template>
    </div>

    <div :class="$style.techinput_attribute_support">
      <p
        :style="{
          margin: 0,
          color: element.comment ? '#1575f7' : 'gray',
          opacity: element.comment ? 1 : 0.5,
        }"
      >
        <fa
          icon="comment"
          class="fa-fw"
        />&nbsp;{{
          element.comment || "Kein Kommentar"
        }}
      </p>
      <div
        v-if="element.type === 'markdown'"
        :class="$style.techinput_attribute__markdown"
      >
        <div :class="$style.fakeTextarea">
          {{ element.value }}
        </div>
        <p style="color: var(--danger)">
          <span style="font-size: 1.2rem; font-weight: bold">
            Markdown führt zu schweren Fehlern in der Übersetzung und muss vor der Veröffentlichung in das neue Editor-Format konvertiert werden:
          </span>
          <ol style="font-size: 1rem;">
            <li>
              Den
              <a
                href="https://markdowntohtml.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Markdown-Konverter <fa icon="external-link-alt" />
              </a>
              öffnen und das komplette Markdown im linken Fenster einfügen.
            </li>
            <li>
              Im rechten Fenster oben auf „Raw HTML” klicken und den kompletten Code in den Zwischenspeicher kopieren.
            </li>
            <li>
              Im Admintool den Attributtyp auf "Editor" stellen und den kopierten HTML-Code über die Funktion "HTML einfügen" in die Eingabe übernehmen.
            </li>
          </ol>
        </p>
      </div>
      <div
        v-if="element.type === 'html'"
        :class="$style.techinput_attribute__html"
        v-html="element.value"
      />
    </div>
  </div>
</template>

<script>
import element from "@/components/elements/tech_input/_rg-element";

export default {
  extends: element,
};
</script>

<style lang="scss" module>
.fakeInput {
  border: 1px solid var(--card-light-stack);
  background: var(--input-background);
  padding: 8px;
  border-radius: 8px;
  font-size: 1em;
  line-height: 1em;
  color: var(--text-dark);
  user-select: all;
  cursor: text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fakeTextarea {
  border: 1px solid var(--card-light-stack);
  background: var(--input-background);
  padding: 8px;
  border-radius: 8px;
  font-family: "Courier New", Courier, monospace;
  font-size: 1em;
  line-height: 1em;
  color: var(--text-dark);
  user-select: all;
  cursor: text;
}
.techinput {
  padding: 5px;
  border-radius: 10px;
  position: relative;
  background: var(--card-light-stack);
  display: grid;
  grid-gap: 5px;
  &.is_selected {
    box-shadow: 0 0 0 4px #88fc;
  }
  &:hover {
    z-index: 9999;
    background: rgba(100, 100, 100, 0.3);
  }
  .techinput_index {
    position: absolute;
    top: 2px;
    left: 5px;
    text-shadow: 1px 1px 2px var(--white-O);
    font-weight: bold;
  }
}

.techinput_attribute {
  grid-template-areas: "editorial main control";
  grid-template-columns: 78px 1fr 36px 36px;
  grid-template-rows: auto;
  .techinput_attribute_editorial {
    display: flex;
    grid-area: editorial;
    justify-content: space-between;
    align-items: flex-start;
  }
  .techinput_attribute_main {
    grid-area: main;
    display: grid;
    grid-gap: 5px;
    &.type-string {
      grid-template-columns: 1fr 100px 1fr 100px;
    }
    &.type-markdown {
      grid-template-columns: 1fr 100px 150px;
    }
    &.type-html {
      grid-template-columns: 1fr 100px 150px;
    }
    &.type-widget {
      grid-template-columns: 1fr 200px;
    }
    &.type-seperator {
      grid-template-columns: 100px 2fr 1fr 100px;
    }
    &.type-link {
      grid-template-columns: 2fr 1fr auto;
    }
    &.type-media {
      grid-template-columns: 1fr 200px;
    }
  }
  .techinput_attribute_support {
    grid-row: 2;
    grid-column: 1 / span 4;
  }

  .techinput_attribute__markdown {
    div {
      margin: 0.25rem 0 1rem;
    }
  }
}
</style>
