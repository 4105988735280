<template>
  <div
    v-if="element"
    :class="[
      $style.techinput,
      $style.techinput_group,
      (selected ? $style.is_selected : null)
    ]"
    @click="$emit('select', element.line)"
  >
    <code :class="$style.techinput_index">{{ element.line + 1 }}</code>
    <div
      class="flex-center"
      :style="'color: '+getColor(element.level)"
    >
      <fa
        class="fa-lg"
        icon="folder"
      />
    </div>
    <rg-button
      tabindex="-1"
      icon="flag"
      :type="{'is-flagged': element.flagged}"
      title="Ordner markieren"
      @click="element.flagged = !element.flagged"
    />
    <rg-button
      tabindex="-1"
      icon="comment"
      :type="{'is-primary': element.showComment}"
      title="Ordner kommentieren"
      @click="toggleComment()"
    />
    <input
      ref="title"
      v-model.lazy="element.title"
      autocapitalize="on"
      required
      spellcheck="true"
      type="text"
      :data-index="element.line"
      placeholder="Gruppenname"
      @focus="$emit('select', element.line)"
      @keyup.enter="$emit('create', $event.altKey ? 'group' : 'attribute')"
    >
    <rg-dropdown :width="230">
      <p><strong>Kategorie</strong> festlegen:</p>
      <rg-button
        :disabled="prevent.includes('batch')"
        :title="prevent.includes('batch') ? 'In dieser Ansicht nicht verfügbar' : null"
        icon-left="server"
        label="Technische Daten"
        @click="$emit('set-category', 'Technische Daten')"
      />
      <rg-button
        :disabled="prevent.includes('batch')"
        :title="prevent.includes('batch') ? 'In dieser Ansicht nicht verfügbar' : null"
        icon-left="wrench"
        label="Anzugswerte"
        @click="$emit('set-category', 'Anzugswerte')"
      />
      <rg-button
        :disabled="prevent.includes('batch')"
        :title="prevent.includes('batch') ? 'In dieser Ansicht nicht verfügbar' : null"
        icon-left="oil-can"
        label="Füllmengen"
        @click="$emit('set-category', 'Füllmengen')"
      />
      <rg-button
        :disabled="prevent.includes('batch')"
        :title="prevent.includes('batch') ? 'In dieser Ansicht nicht verfügbar' : null"
        icon-left="spray-can"
        label="Klebstoffe & Dichtmittel"
        @click="$emit('set-category', 'Klebstoffe & Dichtmittel')"
      />
      <rg-button
        :disabled="prevent.includes('batch')"
        :title="prevent.includes('batch') ? 'In dieser Ansicht nicht verfügbar' : null"
        icon-left="search"
        label="Fehlersuche"
        @click="$emit('set-category', 'Fehlersuche')"
      />
      <rg-button
        :disabled="prevent.includes('batch')"
        :title="prevent.includes('batch') ? 'In dieser Ansicht nicht verfügbar' : null"
        icon-left="list-ol"
        label="Anleitungen"
        @click="$emit('set-category', 'Anleitungen')"
      />
      <rg-button
        :disabled="prevent.includes('batch')"
        :title="prevent.includes('batch') ? 'In dieser Ansicht nicht verfügbar' : null"
        icon-left="sliders-h"
        label="Einstellwerte"
        @click="$emit('set-category', 'Einstellwerte')"
      />
      <rg-button
        :disabled="prevent.includes('batch')"
        :title="prevent.includes('batch') ? 'In dieser Ansicht nicht verfügbar' : null"
        icon-left="tools"
        label="Spezialwerkzeuge"
        @click="$emit('set-category', 'Spezialwerkzeuge')"
      />
      <hr>
      <rg-button
        :disabled="prevent.includes('copy')"
        tabindex="-1"
        icon-left="copy"
        :title="prevent.includes('copy') ? 'In dieser Ansicht nicht verfügbar' : 'Gruppe kopieren'"
        label="Kopieren"
        @click="$emit('copy')"
      />
      <rg-button
        :disabled="prevent.includes('copy:cut')"
        tabindex="-1"
        icon-left="cut"
        :title="prevent.includes('copy:cut') ? 'In dieser Ansicht nicht verfügbar' : 'Element ausschneiden'"
        label="Ausschneiden"
        @click="$emit('copy', true)"
      />
      <hr>
      <rg-button
        :disabled="prevent.includes('remove:group')"
        tabindex="-1"
        type="is-danger"
        icon-left="folder-minus"
        :title="prevent.includes('remove:group') ? 'In dieser Ansicht nicht verfügbar' : 'Gruppe löschen, Unterelemente behalten'"
        label="Auflösen"
        @click="$emit('remove')"
      />
      <rg-button
        :disabled="prevent.includes('remove:group')"
        tabindex="-1"
        type="is-danger"
        icon-left="trash"
        :title="prevent.includes('remove:group') ? 'In dieser Ansicht nicht verfügbar' : 'Gruppe und Unterelemente löschen'"
        label="Löschen"
        @click="$emit('remove', true)"
      />
    </rg-dropdown>
    <rg-d-pad
      :level="element.level"
      :index="element.line"
      :disabled="prevent.includes('move')"
      @move-up="$emit('move', 'up')"
      @move-left="$emit('move', 'left')"
      @move-right="$emit('move', 'right')"
      @move-down="$emit('move', 'down')"
    />
    <textarea
      ref="description"
      v-model.lazy="element.description"
      spellcheck="true"
      placeholder="Beschreibung"
      @focus="$emit('select', element.line)"
    />
    <input
      v-if="element.showComment"
      ref="comment"
      v-model.lazy="element.comment"
      :class="$style.techinput_attribute__comment"
      placeholder="Kommentar"
      type="text"
      spellcheck="true"
      @focus="$emit('select', element.line)"
      @keyup.enter="$emit('create', $event.altKey ? 'group' : 'attribute')"
    >
    <p
      v-else-if="element.comment"
      style="margin:0; color:#1575f7"
    >
      <fa
        icon="comment"
        class="fa-fw"
      />&nbsp;{{ element.comment }}
    </p>
  </div>
</template>

<script>
import element from '@/components/elements/tech_input/_rg-element'

export default { extends: element }
</script>

<style lang="scss" module>
.techinput {
  padding: 5px;
  border-radius: 10px;
  position: relative;
  background: var(--card-light-stack);
  display: grid;
  grid-gap: 5px;
  &.is_selected { box-shadow: 0 0 0 4px #88fc; }
  &:hover {
    z-index: 9999;
    background: rgba(100,100,100,.3)
  }
  .techinput_index {
    position: absolute;
    top: 2px;
    left: 5px;
    text-shadow: 1px 1px 2px var(--white-O);
    font-weight: bold;
  }
}

.techinput_group {
  grid-template-columns: 36px 36px 36px 1fr 36px 36px;
  textarea, p { grid-column: 1 / span 6 }
  span { grid-column: 2; text-align: center;}
  .techinput_attribute__comment { grid-column: 1 / span 6; }
}

</style>
