import Vue from "vue";
import router from "./router.js";
import store from "./store.js";
import VueCompositionApi from "@vue/composition-api";

import App from "./App.vue";

import Plugin from "./plugins/toast/index.js";
import Clipboard from 'v-clipboard'

import rgButton from "@/components/elements/rg-button";
import navigation from "@/components/layout/navigation";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";

library.add(fas);
library.add(fab);

Vue.config.productionTip = false;
Vue.use(VueCompositionApi);
Vue.use(Plugin);
Vue.use(Clipboard)
Vue.component("rg-button", rgButton);
Vue.component("navigation", navigation);
Vue.component("fa", FontAwesomeIcon);
Vue.component("fa-layers", FontAwesomeLayers);

new Vue({ store, router, render: (h) => h(App) }).$mount("#app");
