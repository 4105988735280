<template>
  <div id="app">
    <router-view />
    <footer v-if="layout !== 'base'">
      <div>
        <img
          :src="version.image"
          height="80"
          :alt="version.name"
        >
        <div>
          <strong :style="{ color: version.color }">{{ version.name }}</strong><br>
          <span>{{ version.number }}</span><br>
          <span>© {{ new Date().getFullYear() }} Fricke Holding</span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import version from "@/version.js";
import { computed, ref } from "@vue/composition-api";

export default {
  name: "App",

  setup(props, context) {
    let layout = computed(() => {
      return context.root.$route.meta?.layout;
    });
    let narrow = ref(true);

    let theme = ref(localStorage.getItem("theme") || "light");
    document.documentElement.setAttribute("data-theme", theme.value);

    window.addEventListener("storage", (e) => {
      if (e.key === "copyPasta" && e.newValue) {
        context.root.$store.commit("setCopyPastaStore", JSON.parse(e.newValue));
      }
    });

    return { layout, narrow, version };
  },
};
</script>

<style lang="scss" module>
@import "@/styles.scss";

.mainview {
  overflow: hidden;
  position: relative;
  padding: 50px 10px 0;
}
footer {
  color: var(--black-5);
  margin: 75px 0 50px 0;
  user-select: none;
  text-align: center;
  > div {
    padding: 25px;
    text-align: left;
    display: inline-flex;
    align-items: center;
    img {
      margin-right: 10px;
      filter: drop-shadow(0 2px 0 whitesmoke) drop-shadow(2px 0 0 whitesmoke)
        drop-shadow(0 -2px 0 whitesmoke) drop-shadow(-2px 0 0 whitesmoke);
    }
  }
  strong {
    font-size: 1.3em;
    display: inline-block;
    margin-bottom: 5px;
  }
}
</style>

<style lang="scss">
dfn {
  border-bottom: 1px dashed rgba(150 150 150 / 0.8);
  font-style: normal;
}
abbr,
dfn {
  cursor: help;
}
html {
  scroll-behavior: smooth;
  color: var(--text-dark);
}
.info__live {
  background: red;
  border-radius: 10px;
  padding: 0 10px;
  letter-spacing: 1px;
  box-shadow: 0 4px 8px rgb(255 0 0 / 0.3);
  text-decoration: none;
  color: white;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--card-light-stack);
  transition: all 0.2s ease;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 0 15px white,
    inset 0 0 0 15px white;
  transition: all 0.2s ease;
  border-radius: 50%;
}

.switch input:checked + .slider {
  background-color: var(--primary);
}

.switch input:focus + .slider:before {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 0 11px white,
    inset 0 0 0 13px grey;
}

input[disabled],
select[disabled],
textarea[disabled],
input[disabled] + .slider {
  filter: saturate(0.5) opacity(0.5);
  cursor: not-allowed;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

select:invalid {
  color: var(--black-5);
}
select {
  option[disabled] {
    display: none;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Roboto;
}
pre,
code {
  font-family: monospace;
}
kbd {
  display: inline-block;
  padding: 3px 5px;
  font-size: 1em;
  color: #444d56;
  vertical-align: middle;
  background-color: #fafbfc;
  border: solid 1px #c6cbd1;
  border-bottom-color: #959da5;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 #959da5;
  user-select: none;
  &:active {
    box-shadow: inset 0 1px 0 #959da5;
  }
}

body {
  margin: 0;
  padding: 0;
  background: var(--bg-primary);
}

main {
  z-index: 1;
  transition: margin 0.1s ease;
  position: relative;
  padding: 50px 10px 0;
  &.customcontrol {
    padding: 10px;
  }
}

table.rg-table {
  border-collapse: collapse;
  width: 100%;

  tr:nth-child(even) {
    background: var(--black-05);
  }
  tr:not(:nth-child(1)):hover {
    background: rgba(0, 0, 0, 0.2);
  }

  th,
  td {
    border: 1px solid var(--primary-color);
    padding: 6px;
    height: 50px;
    filter: blur(0px);
    opacity: 1;
    transition: filter 0.1s ease, opacity 0.1s ease;
  }

  th {
    user-select: none;
    text-align: left;
  }
  tr em {
    background: #ff05;
    font-style: normal;
    font-weight: bold;
  }
  &.condensed {
    th,
    td {
      padding: 0px 6px;
      height: 35px;
    }
  }
  td {
    position: relative;
    z-index: 1;
  }
}

.field {
  display: inline-flex;
  input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.text-center {
  text-align: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.flex-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.columns {
  display: flex;
  > .column {
    flex: 12;
    margin: 5px;
  }
}
.level {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

input[type="text"],
input[type="color"],
input[type="number"],
input[type="search"],
input[type="password"],
input[type="email"],
textarea {
  background: var(--input-background);
  padding: 8px;
  display: block;
  border: 1px solid var(--card-light-stack);
  border-radius: 8px;
  font-size: 1em;
  line-height: 1em;
  color: var(--text-dark);
  &:focus {
    border-color: var(--text-dark);
  }
}
input[type="color"] {
  padding: 3px;
  height: 37px;
}

.has-blue-glow {
  border-color: var(--primary);
  box-shadow: 0 0px 0px 4px var(--primary);
}

.has-danger-glow {
  border-color: hsl(348, 100%, 61%);
  box-shadow: 0 0px 0px 4px hsl(348, 100%, 61%);
}

.text-primary {
  color: var(--primary);
}

a {
  -webkit-app-region: no-drag;
  color: var(--text-dark);
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: rgba(128, 128, 128, 0.5);
  text-decoration-thickness: 2px;
}

.has-shadow {
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}
.card {
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  background: var(--card-light);
  &.has-blue-glow {
    box-shadow: 0 0px 0px 4px var(--primary), 0 12px 28px 0 rgba(0, 0, 0, 0.2),
      0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px var(--primary);
  }
  &.has-danger-glow {
    box-shadow: 0 0px 0px 4px hsl(348, 100%, 61%),
      0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
      inset 0 0 0 1px hsl(348, 100%, 61%);
  }
}

.form {
  hr {
    margin: 15px 0;
    background: rgba(175, 175, 175, 0.3);
    border: none;
    height: 3px;
    width: initial;
    border-radius: 1px;
  }
  input,
  textarea,
  select {
    display: block;
    width: 100%;
    margin: 5px 0 15px;
  }
}
.click-select {
  user-select: all;
  cursor: pointer;
}

.rg-select {
  display: block;
  font-size: 1rem;
  font-family: sans-serif;
  padding: 0.5em 0.5em 0.5em 0.5em;
  max-width: 100%;
  border: none;
  border: 1px solid var(--card-light-stack);
  color: var(--text-dark);
  border-radius: 6px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--input-background);
  text-overflow: ellipsis;
}
.rg-select::-ms-expand {
  display: none;
}
.rg-select:focus {
  border-color: var(--text-dark);
  outline: none;
}
.rg-select option {
  font-weight: normal;
  font-family: sans-serif;
  color: var(--text-dark);
}

.tag {
  background: var(--black-2);
  padding: 2px 5px;
  line-height: 1em;
  display: inline-block;
  border-radius: 4px;
  font-weight: bold;
}

/* begin layout
------------------------------------------ */

.rg-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 2vw 2vw;
  margin: 0 auto;
}

.rg-grid.single {
  grid-template-columns: 1fr !important;
}
.rg-grid.ng {
  grid-gap: 0 !important;
}

.rg-grid .w1 {
  grid-column: auto / span 1;
}
.rg-grid .w2 {
  grid-column: auto / span 2;
}
.rg-grid .w3 {
  grid-column: auto / span 3;
}
.rg-grid .w4 {
  grid-column: auto / span 4;
}
.rg-grid .w5 {
  grid-column: auto / span 5;
}
.rg-grid .w6 {
  grid-column: auto / span 6;
}
.rg-grid .w7 {
  grid-column: auto / span 7;
}
.rg-grid .w8 {
  grid-column: auto / span 8;
}
.rg-grid .w9 {
  grid-column: auto / span 9;
}
.rg-grid .w10 {
  grid-column: auto / span 10;
}
.rg-grid .w11 {
  grid-column: auto / span 11;
}
.rg-grid .w12 {
  grid-column: auto / span 12;
}

.vm {
  display: none;
}
.vl {
  display: none;
}

@media only screen and (max-width: 768px) {
  .rg-grid .s1 {
    grid-column: auto / span 1;
  }
  .rg-grid .s2 {
    grid-column: auto / span 2;
  }
  .rg-grid .s3 {
    grid-column: auto / span 3;
  }
  .rg-grid .s4 {
    grid-column: auto / span 4;
  }
  .rg-grid .s5 {
    grid-column: auto / span 5;
  }
  .rg-grid .s6 {
    grid-column: auto / span 6;
  }
  .rg-grid .s7 {
    grid-column: auto / span 7;
  }
  .rg-grid .s8 {
    grid-column: auto / span 8;
  }
  .rg-grid .s9 {
    grid-column: auto / span 9;
  }
  .rg-grid .s10 {
    grid-column: auto / span 10;
  }
  .rg-grid .s11 {
    grid-column: auto / span 11;
  }
  .rg-grid .s12 {
    grid-column: auto / span 12;
  }

  .hs {
    display: none !important;
  }
  .vm {
    display: none !important;
  }
  .vl {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1170px) {
  .rg-grid .m1 {
    grid-column: auto / span 1;
  }
  .rg-grid .m2 {
    grid-column: auto / span 2;
  }
  .rg-grid .m3 {
    grid-column: auto / span 3;
  }
  .rg-grid .m4 {
    grid-column: auto / span 4;
  }
  .rg-grid .m5 {
    grid-column: auto / span 5;
  }
  .rg-grid .m6 {
    grid-column: auto / span 6;
  }
  .rg-grid .m7 {
    grid-column: auto / span 7;
  }
  .rg-grid .m8 {
    grid-column: auto / span 8;
  }
  .rg-grid .m9 {
    grid-column: auto / span 9;
  }
  .rg-grid .m10 {
    grid-column: auto / span 10;
  }
  .rg-grid .m11 {
    grid-column: auto / span 11;
  }
  .rg-grid .m12 {
    grid-column: auto / span 12;
  }

  .hm {
    display: none !important;
  }
  .vs {
    display: none !important;
  }
  .vl {
    display: none !important;
  }
}

@media only screen and (min-width: 1170px) {
  .rg-grid .l1 {
    grid-column: auto / span 1;
  }
  .rg-grid .l2 {
    grid-column: auto / span 2;
  }
  .rg-grid .l3 {
    grid-column: auto / span 3;
  }
  .rg-grid .l4 {
    grid-column: auto / span 4;
  }
  .rg-grid .l5 {
    grid-column: auto / span 5;
  }
  .rg-grid .l6 {
    grid-column: auto / span 6;
  }
  .rg-grid .l7 {
    grid-column: auto / span 7;
  }
  .rg-grid .l8 {
    grid-column: auto / span 8;
  }
  .rg-grid .l9 {
    grid-column: auto / span 9;
  }
  .rg-grid .l10 {
    grid-column: auto / span 10;
  }
  .rg-grid .l11 {
    grid-column: auto / span 11;
  }
  .rg-grid .l12 {
    grid-column: auto / span 12;
  }

  .hl {
    display: none !important;
  }
  .vs {
    display: none !important;
  }
  .vm {
    display: none !important;
  }
}
</style>
