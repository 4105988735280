<template>
  <div>
    <navigation>
      <div style="display: flex; gap: 5px">
        <div>
          <router-link to="/associations/add">
            <rg-button
              type="is-primary"
              icon-left="building"
              title="Anlegen"
              label="Anlegen"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
            />
          </router-link>
          <rg-dropdown>
            <template
              #trigger
            >
              <rg-button
                icon="chevron-down"
                style="border-top-left-radius: 0; border-bottom-left-radius: 0"
              />
            </template>
            <router-link to="/accounts/add">
              <rg-button
                icon-left="user-plus"
                label="Account"
              />
            </router-link>
            <router-link to="/companies/add">
              <rg-button
                icon-left="industry"
                label="Unternehmen"
              />
              <router-link to="/associations/add">
                <rg-button
                  icon-left="building"
                  label="Unternehmensgruppe"
                />
              </router-link>
              <router-link to="/contracts/add">
                <rg-button
                  icon-left="file-contract"
                  label="Vertrag"
                />
              </router-link>
            </router-link>
          </rg-dropdown>
        </div>
      </div>
    </navigation>
    <main>
      <h1>Unternehmensgruppen</h1>
      <br>
      <rg-tabs>
        <router-link
          tag="button"
          replace
          to="/accounts/"
        >
          <fa icon="users" />&nbsp;Accounts
        </router-link>

        <router-link
          tag="button"
          replace
          to="/companies/"
        >
          <fa icon="industry" />&nbsp;Unternehmen
        </router-link>
        <router-link
          tag="button"
          replace
          to="/associations/"
        >
          <fa icon="building" />&nbsp;Unternehmensgruppen
        </router-link>
      </rg-tabs>
      <br>
      <input
        v-model="resultFilter.search"
        type="text"
        placeholder="Gruppenname"
        @input="dispatchSearch()"
        @keypress.enter="dispatchSearch(0)"
      >
      <br>
      <div v-if="!resultFilter.loading">
        <h2>{{ companiesCount }} Ergebnisse</h2>
        <rg-button
          :disabled="page.current <= 1"
          icon="angle-double-left"
          @click="stride(page.current, 1)"
        />
        <rg-button
          :disabled="page.current <= 1"
          icon="angle-left"
          @click="stride(page.current, page.current - 1)"
        />
        {{ page.current }} von {{ Math.ceil(pages) }}
        <rg-button
          :disabled="page.current >= Math.ceil(pages)"
          icon="angle-right"
          @click="stride(page.current, page.current + 1)"
        />
        <rg-button
          :disabled="page.current >= Math.ceil(pages)"
          icon="angle-double-right"
          @click="stride(page.current, Math.ceil(pages))"
        />
      </div>
      <rg-table
        :rows="data"
        cols="id, association_title, association_description, association_pricing_id, association_manager_id"
      >
        <template #default="slotProps">
          <div v-show="resultFilter.loading">
            LOADING
          </div>
          <template v-for="row in slotProps.chunk">
            <tr :key="row.id">
              <td
                v-for="(value, property) in row"
                :key="property"
              >
                <code
                  v-if="['id'].includes(property)"
                  v-clipboard="value"
                  v-clipboard:success="clipboardSuccessHandler"
                >
                  {{ value }}
                </code>
                <code
                  v-else-if="['association_title'].includes(property)"
                  v-clipboard="value"
                  v-clipboard:success="clipboardSuccessHandler"
                >
                  {{ value }}
                </code>
                <span v-else>{{ value }}</span>
              </td>
              <td>
                <div
                  class="flex-right"
                  style="gap: 5px"
                >
                  <router-link :to="'/associations/' + row.id">
                    <rg-button
                      label="Öffnen"
                      type="is-primary"
                      title="Diesen Eintrag bearbeiten"
                    />
                  </router-link>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </rg-table>
    </main>
  </div>
</template>

<script>
import rg_tabs from "@/components/elements/rg-tabs";
import { ref} from "@vue/composition-api";
import dropdown from "@/components/elements/rg-dropdown";
import superagent from "superagent";
import {altBackendUrl} from "@/constants.js";
import Vue from "vue";

export default {
  name: "Association",
  components: {
    "rg-dropdown": dropdown,
    rgTabs: rg_tabs,
    rgTable: () => import("@/components/elements/Table.vue"),
  },

  setup(props, context) {
    let data = ref([]);
    let salesUsers = ref([]);
    let resultFilter = ref({
      step: 25,
      first: 25,
      offset: null,
      search: "",
      loading: true,
      timeout: null,
    });
    let companiesCount = ref({});
    let pages = ref({});
    let page = ref({
      current: 1,
    });

    getData();

    const dispatchSearch = (ms = 300) => {
      clearTimeout(resultFilter.value.timeout);

      resultFilter.value.timeout = setTimeout(() => {
        resultFilter.value.loading = true;
        resultFilter.value.offset = null;
        if(localStorage.currentPage){
          stride(page.value.current, localStorage.currentPage)
          localStorage.removeItem('currentPage')
        } else {
          page.value.current = 1;
        }
        getData(resultFilter.value.search);
      }, resultFilter.value.search?.length && ms);
    };

    dispatchSearch(0);

    function getData(search = resultFilter.value.search) {
      search =
        search?.length >= 3 ? JSON.stringify(search) : JSON.stringify("");
        console.log(search)
      const gqQueryCompanies = `query CompanyAssociations {
        CompanyAssociations(limit:${resultFilter.value.first}, skip: ${resultFilter.value.offset}, search: ${search}) {
          id
          association_title
          association_description
          association_pricing_id
          association_manager_id
        }
      }
      `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryCompanies,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          data.value = formatInput(res.body.data.CompanyAssociations);
          companiesCount.value = data.value.length;
          pages.value = companiesCount.value / resultFilter.value.step;
        })
        .finally(() => {
          resultFilter.value.loading = false;
        });
    }

    function formatInput(inp) {
      let tmpArr = [];
      for (const obj of inp) {
        tmpArr.push(flattenInput(obj));
      }
      return tmpArr;
    }

    function flattenInput(obj) {
      let tempObj = {};
      for (const [key, value] of Object.entries(obj)) {
        let tempObj2 = {};
        if (typeof obj[key] === "object" && obj[key] != null) {
          tempObj2 = flattenInput(obj[key]);
          Object.assign(tempObj, tempObj2);
        } else {
          tempObj[key] = value;
        }
      }
      return tempObj;
    }

    function clipboardSuccessHandler() {
      Vue.$toast.open({
        message: "Erfolgreich kopiert",
        icon: "clipboard-check",
      });
    }

    function stride(from, to) {
      page.value.current = to;
      resultFilter.value.offset =
        page.value.current === 1
          ? null
          : resultFilter.value.step * page.value.current -
            resultFilter.value.step;
      getData();
    }


    return {
      data,
      dispatchSearch,
      clipboardSuccessHandler,
      getData,
      pages,
      page,
      resultFilter,
      companiesCount,
      stride,
      salesUsers,
    };
  },
};
</script>
