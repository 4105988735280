<template>
  <div :class="[$style.techinput_navigation, (disabled ? $style.techinput_navigation__disabled : null)]">
    <span><fa
      class="fa-fw fa-lg"
      icon="arrows-alt"
    /></span>
    <div :class="$style.techinput_navigation__control">
      <div :class="$style.techinput_navigation__control_contain">
        <rg-button
          tabindex="-1"
          icon="chevron-left"
          title="Element ausrücken"
          :disabled="disabled || level === 0"
          @click="$emit('move-left')"
        />
        <rg-button
          tabindex="-1"
          icon="chevron-up"
          title="Element nach oben bewegen"
          :disabled="disabled || index === 0"
          @click="$emit('move-up')"
        />
        <rg-button
          tabindex="-1"
          icon="chevron-down"
          title="Element nach unten bewegen"
          :disabled="disabled"
          @click="$emit('move-down')"
        />
        <rg-button
          tabindex="-1"
          icon="chevron-right"
          title="Element einrücken"
          :disabled="disabled"
          @click="$emit('move-right')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    level: { type: Number, default: 0 },
    index: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" module>
.techinput_navigation {
  position: relative;
  &.techinput_navigation__disabled {
    span { cursor: not-allowed; }
    div { pointer-events: none; }
  }
  &:not(.techinput_navigation__disabled):hover > div {
    transform: translate3d(-50%, -50%, 0) rotate(-45deg) scale(1)!important;
    opacity: 1!important;
  }
  > span { display:block;text-align:center;padding: 6px 0; opacity: .5; }
  div.techinput_navigation__control {
    z-index: 9999;
    position: absolute;
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) rotate(-45deg) scale(.5);
    width: 72px;
    height: 72px;
    overflow: hidden;
    border-radius: 50px;
    left: 50%;
    top: 50%;
    transition: all .1s ease;

    div.techinput_navigation__control_contain {
      display: grid;
      grid-template-columns: 36px 36px;
      background: var(--text-light);
      border-radius: 50px;
      box-shadow: -2px 2px 5px rgba(0,0,0,.2);
    }

    button:not([disabled]):hover {
      filter: brightness(.5)
    }

    button > svg { transform: rotate(45deg); }
    button:nth-of-type(1) {
      border-radius: 40px 0 0 0;
      padding: 12px 7px 7px 12px!important;
    }
    button:nth-of-type(2) {
      border-radius: 0 40px 0 0;
      padding: 12px 12px 7px 7px!important;
    }
    button:nth-of-type(3) {
      border-radius: 0 0 0 40px;
      padding: 7px 7px 12px 12px!important;
    }
    button:nth-of-type(4) {
      border-radius: 0 0 40px 0;
      padding: 7px 12px 12px 7px!important;
    }
  }
}
</style>