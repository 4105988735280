<template>
  <div>
    <navigation>
      <div style="display: flex; gap: 5px">
        <div>
          <router-link to="/accounts/add">
            <rg-button
              type="is-primary"
              icon-left="user-plus"
              title="Anlegen"
              label="Anlegen"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
            />
          </router-link>
          <rg-dropdown>
            <template #trigger>
              <rg-button
                icon="chevron-down"
                style="border-top-left-radius: 0; border-bottom-left-radius: 0"
              />
            </template>
            <router-link to="/contracts/add">
              <rg-button icon-left="file-contract" label="Vertrag" />
            </router-link>
            <router-link to="/accounts/add">
              <rg-button icon-left="user-plus" label="Account" />
            </router-link>
            <router-link to="/associations/add">
              <rg-button icon-left="building" label="Unternehmensgruppe" />
              <router-link to="/companies/add">
                <rg-button icon-left="users" label="Unternehmen" />
              </router-link>
            </router-link>
          </rg-dropdown>
        </div>
      </div>
    </navigation>
    <div class="rg-grid" style="padding: 15px">
      <div class="level w12">
        <h1>Firma anlegen</h1>
        <div>
          <rg-button
            type="is-primary"
            icon-left="save"
            label="Speichern"
            :disabled="!checkFormInput()"
            @click="addCompany()"
          />
        </div>
      </div>
      <rg-widget title="Allgemein" class="w8 form">
        <template #data>
          <div style="display: flex">
            <div style="margin-right: 15px">
              <strong>GRANIT Kundennr.</strong>
              <input
                v-model="data.granitId"
                type="text"
                :style="
                  resultFilter.granitIdExists
                    ? 'border-color: hsl(348, 100%, 61%)'
                    : ''
                "
                style="width: 100%"
                @input="lookupGranitId(1000)"
                @keypress.enter="lookupGranitId(0)"
              />
            </div>
            <div v-if="!granitIdIsSAPId" style="margin-right: 15px">
              <strong>SAP ID</strong>
              <input
                v-model="data.sapId"
                style="width: 100%"
                type="text"
                :style="
                  resultFilter.sapIdExists
                    ? 'border-color: hsl(348, 100%, 61%)'
                    : ''
                "
                @input="lookupSapId(1000)"
                @keypress.enter="lookupSapId(0)"
              />
            </div>
            <div style="">
              <div>
                <strong>Kundennr. ist SAP Kundennr.</strong>
              </div>
              <label class="switch" style="margin-top: 6px">
                <input v-model="granitIdIsSAPId" type="checkbox" />
                <span class="slider" />
              </label>
            </div>
          </div>

          <strong class="level"><span>Firmenname</span> </strong>
          <input v-model="data.companyTitle" type="text" />
          <strong class="level"><span>Land</span> </strong>
          <select
            v-model="data.country"
            class="rg-select"
            @change="countryChange"
          >
            <option />
            <option
              v-for="option in countries"
              :key="option.id"
              :value="option"
            >
              {{ option.country_name }} ({{ option.country_code }})
            </option>
          </select>

          <strong class="level"><span>Kommentar</span> </strong>
          <textarea v-model="data.companyComment" cols="30" rows="10" />
        </template>
      </rg-widget>
      <rg-widget title="Verknüpfungen" class="w4 form">
        <template #data>
          <strong class="level"
            ><span>GRANIT Zuständiger Gebietsleiter</span>
          </strong>
          <div class="level">
            <input
              v-model="data.salesRepresentative"
              type="text"
              @input="lookupSalesRepresentative(300)"
              @keypress.enter="lookupSalesRepresentative(0)"
            />
            <select
              v-model="data.salesRepresentativeId"
              class="rg-select"
              :disabled="resultFilter.salesRepresentatives.length <= 1"
              :style="
                !data.salesRepresentativeId &&
                resultFilter.salesRepresentatives.length > 1
                  ? 'border-color:hsl(44, 100%, 48%);'
                  : ''
              "
            >
              <option
                v-for="option in resultFilter.salesRepresentatives"
                :key="option.id"
                :value="option.id"
              >
                {{ `${option.id} - ${option.prename} ${option.name}` }}
              </option>
            </select>
          </div>
          <strong class="level"><span>GRANIT Shop Konfiguration</span> </strong>
          <select v-model="data.granitShopId" class="rg-select">
            <option
              v-for="option in granitShops"
              :key="option.id"
              :value="option.id"
            >
              {{ option.title }} {{ `(${option.base_domain})` }}
            </option>
          </select>
          <strong class="level"><span>GRANIT Vertragspartner</span> </strong>
          <select v-model="data.contractPartnerId" class="rg-select">
            <option
              v-for="option in contractPartners"
              :key="option.id"
              :value="option.id"
            >
              {{ `${option.country_full} - ` }}
              {{ option.company }}
              {{ `(${option.support_contact})` }}
            </option>
          </select>
          <strong class="level"><span>Unternehmensgruppe</span> </strong>
          <select v-model="data.companyAssociationId" class="rg-select">
            <option
              v-for="option in companyGroups"
              :key="option.id"
              :value="option.id"
            >
              {{ option.association_title }}
            </option>
          </select>
          <span v-if="data.companyAssociationId">
            {{
              companyGroups.find((g) => g.id === data.companyAssociationId)
                .association_description
            }}
          </span>
        </template>
      </rg-widget>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "@vue/composition-api";
import { formDirty } from "@/helper.js";
import dropdown from "@/components/elements/rg-dropdown";
import superagent from "superagent";
import { backendUrl, altBackendUrl } from "@/constants.js";
import Vue from "vue";

export default {
  components: {
    // dropdown,
    "rg-dropdown": dropdown,
    "rg-widget": () => import("@/components/elements/rg-widget"),
  },
  beforeRouteLeave(to, from, next) {
    next(
      !this.data.name ||
        window.confirm("Bearbeitung aktiviert. Formular wirklich verlassen?")
    );
  },
  setup(props, context) {
    let data = ref({
      contractPartnerId: null,
    });
    let resultFilter = ref({
      timeoutSR: null,
      timeoutCompany: null,
      salesRepresentatives: [],
      granitIdExists: false,
      sapIdExists: false,
      loading: false,
    });
    let company = ref({});
    let granitShops = ref([]);
    let contractPartners = ref([]);
    let countries = ref();
    //will be changed to dynamic list of company groups in separate ticket and after apollo supports this
    let companyGroups = ref([]);
    let granitIdIsSAPId = ref(false);

    watchEffect(() => {
      company.value = data.value;
    });

    getShops();
    getContractPartners();
    getCompanyAssociations();
    getCountries();

    function countryChange() {
      for (const contractPartner of contractPartners.value) {
        if (contractPartner.country == data.value.country.country_code) {
          data.value.contractPartnerId = contractPartner.id;
          break;
        } else {
          data.value.contractPartnerId = null;
        }
      }
    }

    function checkFormInput() {
      if (
        !resultFilter.value.loading &&
        !resultFilter.value.granitIdExists &&
        data.value.companyTitle &&
        data.value.granitShopId &&
        data.value.contractPartnerId &&
        data.value.country.id
      )
        return true;
    }

    function getCompanyAssociations() {
      const gqQueryCompanyAssociation = `
      query CompanyAssociations {
        CompanyAssociations {
          id
          association_title
          association_description
        }
      }
      `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryCompanyAssociation,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((resAssociations) => {
          companyGroups.value =
            resAssociations.body.data.CompanyAssociations.sort((a, b) =>
              a.association_title.localeCompare(b.association_title)
            );
        });
    }

    function getShops() {
      const gqQueryShops = `
      query GranitShops {
        GranitShops {
          id
          title
          base_domain
          shop_key
          createdat
          updatedat
        }
      }
      `;
      superagent
        .post(backendUrl)
        .send({
          query: gqQueryShops,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((resShops) => {
          granitShops.value = resShops.body.data.GranitShops.sort((a, b) =>
            a.title.localeCompare(b.title)
          );
        });
    }

    function getCountries() {
      const gqlQueryAppCountries = `
      query Query {
        AppCountries {
          country_code
          country_name
          id
        }
      }
      `;

      superagent
        .post(backendUrl)
        .send({
          query: gqlQueryAppCountries,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          countries.value = res.body.data.AppCountries;
        });
    }

    function getContractPartners() {
      const gqQueryContractPartners = `
      query GranitShops {
        ContractPartners {
          id
          company
          country
          country_full
          support_contact
          country_flag
        }
      }
      `;
      superagent
        .post(backendUrl)
        .send({
          query: gqQueryContractPartners,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((resContractPartners) => {
          contractPartners.value =
            resContractPartners.body.data.ContractPartners.sort((a, b) =>
              a.country_full.localeCompare(b.country_full)
            );
        });
    }

    function lookupSalesRepresentative(ms = 1000) {
      resultFilter.value.loading = true;
      clearTimeout(resultFilter.value.timeoutSR);
      const gqQueryRepresentatives = `
      query Query($search: String, $first: Int) {
        Users(search: $search, first: $first) {
          id
          roles
          salutation
          prename
          name
          status
        }
      }
      `;
      resultFilter.value.timeoutSR = setTimeout(() => {
        superagent
          .post(backendUrl)
          .send({
            query: gqQueryRepresentatives,
            variables: { search: data.value.salesRepresentative, first: 50 },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            resultFilter.value.salesRepresentatives =
              res.body?.data?.Users.filter((r) => r.roles.includes("sales"));
            data.value.salesRepresentativeId =
              resultFilter.value.salesRepresentatives?.length === 1
                ? resultFilter.value.salesRepresentatives[0]?.id
                : data.value.salesRepresentativeId;
            resultFilter.value.loading = false;
          });
      }, data.value.salesRepresentative && ms);
      resultFilter.value.loading = false;
    }

    function lookupGranitId(ms = 1000) {
      clearTimeout(resultFilter.value.timeoutCompany);
      resultFilter.value.timeoutCompany = setTimeout(() => {
        const gqQueryCompany = `
        query Query($granitId: String) {
          Company(granitID: $granitId) {
            id
          }
        }
      `;
        superagent
          .post(backendUrl)
          .send({
            query: gqQueryCompany,
            variables: { granitId: data.value?.granitId.trim() },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            resultFilter.value.granitIdExists = res.body?.data?.Company?.id
              ? true
              : false;
            resultFilter.value.loading = false;
          });
      }, !resultFilter.value.granitIdExists && ms);
      resultFilter.value.loading = false;
    }

    function lookupSapId(ms = 1000) {
      clearTimeout(resultFilter.value.timeoutCompany);
      resultFilter.value.timeoutCompany = setTimeout(() => {
        const gqQueryCompany = `
        query Query($sapId: String) {
          Company(sapId: $sapId) {
            id
          }
        }
      `;
        superagent
          .post(altBackendUrl)
          .send({
            query: gqQueryCompany,
            variables: { sapId: data.value?.sapId.trim() },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            resultFilter.value.sapIdExists = res.body?.data?.Company?.id
              ? true
              : false;
            resultFilter.value.loading = false;
          });
      }, !resultFilter.value.sapIdExists && ms);
      resultFilter.value.loading = false;
    }

    function addCompany() {
      const payload = {
        granitId: data.value.granitId?.trim() || null,
        companyTitle: data.value.companyTitle?.trim() || null,
        companyComment: data.value.companyComment?.trim() || null,
        salesRepresentativeId:
          parseInt(data.value.salesRepresentativeId) || null,
        granitShopId: parseInt(data.value.granitShopId) || null,
        contractPartnerId: parseInt(data.value.contractPartnerId) || null,
        countryId: parseInt(data.value.country.id) || null,
        companyAssociationId: parseInt(data.value.companyAssociationId) || null,
        sapId: granitIdIsSAPId.value
          ? data.value.granitId
          : data.value.sapId || null,
      };

      const createCompanyMutation = `mutation Mutation($companyCompanyTitle: String!,
        $companyGranitid: String!, $companyGranitShopId: Int, $companyContractPartnerId: Int,
        $companyManagingUserId: Int, $companySalesRepresentativeId: Int, $companyCompanyComment: String, $countryId: Int, $companyAssociatedWith: Int, $sapId: String) {
          Company(companyTitle: $companyCompanyTitle, granitId: $companyGranitid,
            granitShopId: $companyGranitShopId, contractPartnerId: $companyContractPartnerId,
            managingUserId: $companyManagingUserId, salesRepresentativeId: $companySalesRepresentativeId,
            companyComment: $companyCompanyComment,
            countryId: $countryId, companyAssociatedWith: $companyAssociatedWith, sapId: $sapId
          ) {
            id
          }
        }`;

      superagent
        .post(altBackendUrl)
        .send({
          query: createCompanyMutation,
          variables: {
            companyCompanyTitle: payload.companyTitle,
            companyGranitid: payload.granitId,
            companySalesRepresentativeId: payload.salesRepresentativeId,
            companyComment: payload.companyComment,
            companyAssociatedWith: payload.companyAssociationId,
            companyGranitShopId: payload.granitShopId,
            companyContractPartnerId: payload.contractPartnerId,
            countryId: payload.countryId,
            sapId: payload.sapId,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((resCompany) => {
          if (
            !resCompany.body?.data?.errors &&
            resCompany.body?.data?.Company
          ) {
            Vue.$toast.open({
              icon: "check",
              message: "Die Firma wurde erfolgreich angelegt.",
            });
            setTimeout(() => {
              context.root.$router.push({
                path: `/companies/${resCompany?.body?.data?.Company?.id}/home`,
              });
            }, 2000);
          } else {
            Vue.$toast.open({
              icon: "times-circle",
              type: "error",
              message: "FEHLER: Die Firma konnte nicht angelegt werden.",
            });
            console.table(payload);
            console.log(resCompany.body?.data?.errors);
          }
        })
        .catch((err) => {
          Vue.$toast.open({
            icon: "times-circle",
            type: "error",
            message: "FEHLER: Die Firma konnte nicht angelegt werden.",
          });
          console.table(payload);
          console.error(err?.response?.body?.errors);
        });
    }

    return {
      data,
      formDirty,
      addCompany,
      checkFormInput,
      lookupSalesRepresentative,
      resultFilter,
      granitShops,
      contractPartners,
      companyGroups,
      lookupGranitId,
      lookupSapId,
      granitIdIsSAPId,
      countries,
      countryChange,
    };
  },
};
</script>

<style lang="scss" module>
.detail {
  grid-auto-flow: dense;
}

.changed {
  height: 10px;
  width: 10px;
  background: red;
  display: inline-block;
  border-radius: 5px;
  margin-left: 5px;
}
</style>
