<template>
  <div
    v-if="element"
    :class="[
      $style.techinput, $style.techinput_attribute,
      (selected ? $style.is_selected : null),
    ]"
    @click="$emit('select', element.line)"
  >
    <div :class="$style.techinput_attribute_editorial">
      <code :class="$style.techinput_index">{{ element.line + 1 }}</code>
      <div
        class="flex-center"
        :style="{
          color: getColor(element.level),
          width: '36px',
          height: '36px'
        }"
      >
        <fa
          class="fa-lg" 
          :title="
            element.type === 'widget' ? 'Widget' :
            element.type === 'seperator' ? 'Trennlinie' :
            element.category"
          :icon="(
            element.type === 'widget' ? 'shapes' :
            element.type === 'seperator' ? 'ruler-vertical' :
            element.category === 'Technische Daten' ? 'server' :
            element.category === 'Anzugswerte' ? 'wrench' :
            element.category === 'Füllmengen' ? 'oil-can' :
            element.category === 'Klebstoffe & Dichtmittel' ? 'spray-can' :
            element.category === 'Fehlersuche' ? 'search' :
            element.category === 'Anleitungen' ? 'list-ol' :
            element.category === 'Einstellwerte' ? 'sliders-h' :
            element.category === 'Spezialwerkzeuge' ? 'tools' :
            'file'
          )"
        />
      </div>
      <rg-button
        tabindex="-1"
        icon="flag"
        :type="{'is-flagged': element.flagged}"
        title="Attribut markieren"
        @click="element.flagged = !element.flagged"
      />
      <rg-button
        tabindex="-1"
        icon="comment"
        :type="{'is-primary': element.showComment}"
        title="Attribut kommentieren"
        @click="toggleComment()"
      />
    </div>
    <div :class="$style.techinput_attribute_control">
      <rg-dropdown :width="220">
        <p><strong>Attributtyp</strong> festlegen</p>
        <span :class="$style.attribute_type">
          <rg-button
            v-for="type in types"
            :key="type.id"
            :title="type.title"
            tabindex="-1"
            :icon="type.icon"
            :type="type.type"
            :class="[
              (element.type === type.id ? $style.current_type : null),
              {'custom': element.type === type.id},
            ]"
            @click="element.type = type.id, element.value = type.value || element.value"
          />
        </span>
        <hr>
        <rg-button
          tabindex="-1"
          icon-left="copy"
          title="Element kopieren"
          label="Kopieren"
          @click="$emit('copy')"
        />
        <rg-button
          tabindex="-1"
          icon-left="cut"
          title="Element ausschneiden"
          label="Ausschneiden"
          @click="$emit('copy', true)"
        />
        <hr>
        <rg-button
          tabindex="-1"
          type="is-danger"
          icon-left="trash"
          title="Element löschen"
          label="Löschen"
          @click="$emit('remove')"
        />
      </rg-dropdown>
      <rg-d-pad
        :level="element.level"
        :index="element.line"
        :disabled="prevent.includes('move')"
        @move-up="$emit('move', 'up')"
        @move-left="$emit('move', 'left')"
        @move-right="$emit('move', 'right')"
        @move-down="$emit('move', 'down')"
      />
    </div>

    <div :class="[$style.techinput_attribute_main, $style['type-'+element.type]]">
      <template v-if="element.type === 'string'">
        <input
          ref="title"
          v-model.lazy="element.title"
          autocapitalize="on"
          required
          :spellcheck="true"
          type="text"
          :data-index="element.line"
          placeholder="Titel"
          @keyup.enter="$emit('create', $event.altKey ? 'group' : 'attribute')"
          @focus="$emit('select', element.line)"
        >
        <input
          ref="unit"
          v-model.lazy="element.unit"
          :data-index="element.line"
          placeholder="Einheit"
          type="text"
          list="units"
          @change="replaceUnit()"
          @keyup.enter="$emit('create', $event.altKey ? 'group' : 'attribute')"
          @focus="$emit('select', element.line)"
        >
        <input
          ref="value"
          v-model.lazy="element.value"
          autocapitalize="on"
          required
          spellcheck="true"
          type="text"
          :data-index="element.line"
          placeholder="Wert"
          @keyup.enter="$emit('create', $event.altKey ? 'group' : 'attribute')"
          @focus="$emit('select', element.line)"
        >
        <select
          ref="category"
          v-model.lazy="element.category"
          class="rg-select"
          @keyup.enter="$emit('create', $event.altKey ? 'group' : 'attribute')"
          @focus="$emit('select', element.line)"
        >
          <option value="Technische Daten">
            Technische Daten
          </option>
          <option value="Anzugswerte">
            Anzugswerte
          </option>
          <option value="Füllmengen">
            Füllmengen
          </option>
          <option value="Klebstoffe & Dichtmittel">
            Klebstoffe & Dichtmittel
          </option>
          <option value="Fehlersuche">
            Fehlersuche
          </option>
          <option value="Anleitungen">
            Anleitungen
          </option>
          <option value="Einstellwerte">
            Einstellwerte
          </option>
          <option value="Spezialwerkzeuge">
            Spezialwerkzeuge
          </option>
        </select>
      </template>
      <template v-if="['markdown', 'html'].includes(element.type)">
        <input
          ref="title"
          v-model.lazy="element.title"
          autocapitalize="on"
          required
          :spellcheck="true"
          type="text"
          :data-index="element.line"
          placeholder="Titel"
          @keyup.enter="$emit('create', $event.altKey ? 'group' : 'attribute')"
          @focus="$emit('select', element.line)"
        >
        <input
          ref="unit"
          v-model.lazy="element.unit"
          :data-index="element.line"
          placeholder="Einheit"
          type="text"
          list="units"
          @change="replaceUnit()"
          @keyup.enter="$emit('create', $event.altKey ? 'group' : 'attribute')"
          @focus="$emit('select', element.line)"
        >
        <select
          ref="category"
          v-model.lazy="element.category"
          class="rg-select"
          @keyup.enter="$emit('create', $event.altKey ? 'group' : 'attribute')"
          @focus="$emit('select', element.line)"
        >
          <option value="Technische Daten">
            Technische Daten
          </option>
          <option value="Anzugswerte">
            Anzugswerte
          </option>
          <option value="Füllmengen">
            Füllmengen
          </option>
          <option value="Klebstoffe & Dichtmittel">
            Klebstoffe & Dichtmittel
          </option>
          <option value="Fehlersuche">
            Fehlersuche
          </option>
          <option value="Anleitungen">
            Anleitungen
          </option>
          <option value="Einstellwerte">
            Einstellwerte
          </option>
          <option value="Spezialwerkzeuge">
            Spezialwerkzeuge
          </option>
        </select>
      </template>
      <template v-if="element.type === 'widget'">
        <input
          ref="title"
          v-model.lazy="element.title"
          autocapitalize="on"
          required
          :spellcheck="true"
          type="text"
          :data-index="element.line"
          placeholder="Welche Inhalte zeigt das Widget?"
          @keyup.enter="$emit('create', $event.altKey ? 'group' : 'attribute')"
          @focus="$emit('select', element.line)"
        >
        <select
          ref="value"
          v-model="element.value"
          class="rg-select"
          placeholder="Welches Widget soll angezeigt werden?"
          required
          :data-index="element.line"
          @keyup.enter="$emit('create', $event.altKey ? 'group' : 'attribute')"
          @focus="$emit('select', element.line)"
        >
          <option
            v-for="widget in widgets"
            :key="widget.id"
            :value="widget.id"
          >
            {{ widget.title }}
          </option>
        </select>
      </template>
      <template v-if="element.type === 'seperator'">
        <div class="form">
          <hr>
        </div>
        <input
          ref="title"
          v-model.lazy="element.title"
          autocapitalize="on"
          required
          :spellcheck="true"
          type="text"
          :data-index="element.line"
          placeholder="Text auf der Linie"
          @keyup.enter="$emit('create', $event.altKey ? 'group' : 'attribute')"
          @focus="$emit('select', element.line)"
        >
        <select
          ref="value"
          v-model="element.value"
          class="rg-select"
          placeholder="Welches Widget soll angezeigt werden?"
          required
          :data-index="element.line"
          @keyup.enter="$emit('create', $event.altKey ? 'group' : 'attribute')"
          @focus="$emit('select', element.line)"
        >
          <option
            v-for="alignment in alignments"
            :key="alignment.id"
            :value="alignment.id"
          >
            {{ alignment.title }}
          </option>
        </select>
        <div class="form">
          <hr>
        </div>
      </template>
      <template v-if="element.type === 'link'">
        <input
          ref="title"
          v-model.lazy="element.title"
          autocapitalize="on"
          required
          :spellcheck="true"
          type="text"
          :data-index="element.line"
          placeholder="Anzeigetext"
          @keyup.enter="$emit('create', $event.altKey ? 'group' : 'attribute')"
          @focus="$emit('select', element.line)"
        >
        <input
          ref="value"
          v-model.lazy="element.value"
          autocapitalize="on"
          required
          spellcheck="true"
          type="text"
          :data-index="element.line"
          placeholder="Link"
          @keyup.enter="$emit('create', $event.altKey ? 'group' : 'attribute')"
          @focus="$emit('select', element.line)"
        >
        <div class="flex-center">
          <span :style="{ margin: '0 10px 0 5px', lineHeight: '1.1em' }">
            Link öffnet<br><strong>neuen Tab</strong>
          </span>
          <label class="switch">
            <input
              v-model="element.unit"
              type="checkbox"
              value="Bike"
            >
            <span class="slider" />
          </label>
        </div>
      </template>
      <template v-if="element.type === 'media'">
        <input
          ref="title"
          v-model.lazy="element.title"
          autocapitalize="on"
          required
          :spellcheck="true"
          type="text"
          :data-index="element.line"
          placeholder="Anzeigetext"
          @keyup.enter="$emit('create', $event.altKey ? 'group' : 'attribute')"
          @focus="$emit('select', element.line)"
        >
        <input
          ref="title"
          v-model.lazy="element.title"
          autocapitalize="on"
          required
          :spellcheck="true"
          type="text"
          :data-index="element.line"
          placeholder="Anzeigetext"
          @keyup.enter="$emit('create', $event.altKey ? 'group' : 'attribute')"
          @focus="$emit('select', element.line)"
        >
      </template>
    </div>

    <div
      v-if="
        ['markdown', 'html', 'media'].includes(element.type) ||
          element.showComment || element.comment
      "
      :class="$style.techinput_attribute_support"
    >
      <input
        v-if="element.showComment"
        ref="comment"
        v-model.lazy="element.comment"
        placeholder="Kommentar"
        type="text"
        spellcheck="true"
        style="width: 100%"
        @focus="$emit('select', element.line)"
        @keyup.enter="$emit('create', $event.altKey ? 'group' : 'attribute')"
      >
      <p
        v-else-if="element.comment"
        style="margin:0; color:#1575f7"
      >
        <fa
          icon="comment"
          class="fa-fw"
        />&nbsp;{{ element.comment }}
      </p>
      <div
        v-if="element.type === 'markdown'"
        :class="$style.techinput_attribute__markdown"
      >
        <p style="color: var(--danger)">
          <span style="font-size: 1.2rem; font-weight: bold">
            Markdown führt zu schweren Fehlern in der Übersetzung und muss vor der Veröffentlichung in das neue Editor-Format konvertiert werden:
          </span>
          <ol style="font-size: 1rem;">
            <li>
              Den
              <a
                href="https://markdowntohtml.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Markdown-Konverter <fa icon="external-link-alt" />
              </a>
              öffnen und das komplette Markdown im linken Fenster einfügen.
            </li>
            <li>
              Im rechten Fenster oben auf „Raw HTML” klicken und den kompletten Code in den Zwischenspeicher kopieren.
            </li>
            <li>
              Im Admintool den Attributtyp auf "Editor" stellen und den kopierten HTML-Code über die Funktion "HTML einfügen" in die Eingabe übernehmen.
            </li>
          </ol>
        </p>
        <textarea
          ref="value"
          v-model.lazy="element.value"
          required
          cols="30"
          rows="10"
          class="has-danger-glow"
          @focus="$emit('select', element.line)"
        />
      </div>
      <div
        v-if="element.type === 'html'"
        :class="$style.techinput_attribute__html"
      >
        <rgEditor
          ref="value"
          v-model.lazy="element.value"
          @focus="$emit('select', element.line)"
        />
        <!-- 
          required
           -->
      </div>
      <div
        v-if="element.type === 'media'"
        :style="{ display: 'grid', gridTemplateColumns: '1fr 300px' }"
      >
        <div style="padding: 10px">
          <input
            v-if="!image.base64"
            :ref="element.line+'-media'"
            type="file"
            @change="test($event)"
          >
          <div>
            <img
              v-if="image.base64"
              :id="'image-'+element.line"
              :src="image.base64"
              alt="your image"
              style="max-width: 100%"
            >
          </div>
        </div>
        <div>
          <label
            for="upload1"
            display="block"
          >
            <input
              v-if="!image.base64"
              id="upload1"
              :ref="element.line+'-media'"
              type="file"
              style="display: none;"
              @change="test($event)"
            >
            <span class="button is-block"><fa
              icon="upload"
              class="fa-fw icon-left"
            />Neue Datei hochladen</span>
          </label>
          <br>
          <strong>Bild spiegeln</strong>
          <div
            :style="{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gridGap: '5px',
              marginTop: '10px'
            }"
          >
            <rg-button
              icon-left="arrows-alt-h"
              label="Horizontal"
              @click="flip('H')"
            />
            <rg-button
              icon-left="arrows-alt-v"
              label="Vertikal"
              @click="flip('V')"
            />
          </div>
          <br>
          <div class="level">
            <strong>Bild ausrichten</strong>
            <span>{{ tilt }}° <a @click="tilt = 0; rotateto(tilt); convert()">reset</a></span>
          </div>
          <input
            v-model="tilt"
            style="width:100%"
            type="range"
            min="-45"
            max="45"
            step="0.1"
            @input="rotateto(tilt)"
            @change="convert"
          >
          <div
            class="level"
            style="opacity: .5"
          >
            <span>-45°</span>
            <span>±0°</span>
            <span>45°</span>
          </div>
          <br>
          <rg-button
            icon-left="undo"
            label="Änderungen zurücksetzen"
            @click="reset()"
          />
          <br><br>
          <strong>Vorschau</strong>
          <br>
          <img
            v-if="blobby.base64"
            :src="blobby.base64"
            alt="your image"
            style="max-width: 100%"
          >
          <p>Größe der hochgeladenen Datei: {{ image.prettyBytes }}</p>
          <p>Cropped image at 50%: {{ blobby.prettyBytes }} ({{ blobby.sizeDiff }})</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import prettyBytes from 'pretty-bytes'
import Cropper from 'cropperjs';
import Vue from 'vue'
import element from '@/components/elements/tech_input/_rg-element'
import { units } from '@/helper'
import { ref } from '@vue/composition-api'
import rgEditor from '@/components/elements/rg-editor'

export default {
  components: {
    rgEditor
  },
  extends: element,
  props: {
    replaceUnits: { type: Boolean, default: false }
  },
  setup(props, context) {
    let tilt = ref(0)
    let image = ref({
      base64: '',
      size: 0,
      prettyBytes: ''
    })
    let blobby = ref({
      base64: '',
      size: 0,
      prettyBytes: '',
      sizeDiff: ''
    })
    let cropper = ref('')

    const replaceUnit = () => {
      let replacement = units.find(u => u.toLowerCase().replace(/\.$/, '') === props.element.unit.toLowerCase().replace(/\.$/, '') )
      if (replacement && props.replaceUnits) { context.emit('set-unit', replacement) }
    }
    const widgets = [
      { title: 'ElringKlinger', id: 'elringklinger' }
    ]
    const alignments = [
      { title: 'Linksbündig', id: 'left' },
      { title: 'Zentriert', id: 'centered' },
      { title: 'Rechtsbündig', id: 'right' }
    ]

    const types = [
      { title: 'Normales Textattribut', id: 'string', icon: 'i-cursor' },
      { title: 'Editor', id: 'html', icon: 'columns' },
      { title: 'Widget', id: 'widget', icon: 'shapes', value: widgets[0].id },
      { title: 'Trennlinie', id: 'seperator', icon: 'ruler-vertical', value: alignments[0].id },
      { title: 'Markdown (Veraltet, bitte Editor verwenden)', id: 'markdown', icon: ['fab', 'markdown'], type: 'is-danger' },
      // { title: 'Verlinkung', id: 'link', icon: 'link' },
      // { title: 'Bild oder Video', id: 'media', icon: 'image' }
    ]

    function test (e)  {
      if (cropper.value) cropper.value.destroy()
      console.log(e.target.files[0])
      image.value.size = e.target.files[0].size
      image.value.prettyBytes = prettyBytes(image.value.size, {locale: 'de'})
      image.value.base64 = URL.createObjectURL(e.target.files[0])
      Vue.nextTick(() => {
        cropper.value = new Cropper(document.getElementById('image-'+props.element.line), {
          zoomable: false,
          autoCropArea: 1,
          center: false,
          movable: false,
          viewMode: 2,
          dragMode: 'move',
          autoCrop: true,
          ready: () => { convert() },
          cropend: () => { convert() }
        });
      })
    }

    function reset() {
      cropper.value.reset()
      cropper.value.rotateTo(0)
      tilt.value = 0
      convert()
    }

    function resetHard() {
      if (cropper.value) cropper.value.destroy()
      image.value.base64 = ''
      image.value.size = 0
      image.value.prettyBytes = ''
      blobby.value.base64 = ''
      blobby.value.size = 0
      blobby.value.prettyBytes = ''
      blobby.value.sizeDiff = ''
    }

    function convert() {
      cropper.value.getCroppedCanvas({fillColor: 'white'}).toBlob((blob) => {
        blobby.value.size = blob.size
        blobby.value.sizeDiff = prettyBytes((blob.size - image.value.size), {locale: 'de', signed: true})
        blobby.value.prettyBytes = prettyBytes(blob.size, {locale: 'de'})
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function() {
          blobby.value.base64 = reader.result;
        }
      }, 'image/jpeg', .69)
    }

    function flip(dir) {
      let current = cropper.value.getData()
      dir.toLowerCase() === 'h'
        ? cropper.value.scaleX(current.scaleX === 1 ? -1 : 1)
        : cropper.value.scaleY(current.scaleY === 1 ? -1 : 1)
      convert()
    }

    function rotateto (val) {
      cropper.value.rotateTo(val)
    }

    return {
      replaceUnit,
      types,
      test,
      image,
      convert,
      blobby,
      flip,
      tilt,
      rotateto,
      reset,
      resetHard,
      widgets,
      alignments
    }
  }
}
</script>

<style src="cropperjs/dist/cropper.min.css"></style>
<style lang="scss">
.cropper-point {
  &.point-n, &.point-e, &.point-s, &.point-w { display: none; }
  background-color: transparent;
  opacity: 1!important;
  height: 20px!important;
  width: 20px!important;
  &.point-nw {
    top: -5px;
    left: -5px;
    border-top: 5px solid white;
    border-left: 5px solid white;
  }
  &.point-ne {
    top: -5px;
    right: -5px;
    border-top: 5px solid white;
    border-right: 5px solid white;
  }
  &.point-sw {
    left: -5px;
    bottom: -5px;
    border-left: 5px solid white;
    border-bottom: 5px solid white;
  }
  &.point-se {
    right: -5px;
    bottom: -5px;
    border-right: 5px solid white;
    border-bottom: 5px solid white;
  }
}
.cropper-view-box {
  outline: 2px solid white;
}
.cropper-line { opacity: 0; }

.cropper-dashed {
  border: 0 solid white;
  opacity: 0;
  transition: all 200ms ease;
}
.cropper-crop-box:hover {
  .cropper-dashed {
    opacity: 1;
  }
}
</style>

<style lang="scss" module>
.techinput {
  padding: 5px;
  border-radius: 10px;
  position: relative;
  background: var(--card-light-stack);
  display: grid;
  grid-gap: 5px;
  &.is_selected { box-shadow: 0 0 0 4px #88fc; }
  &:hover {
    z-index: 9999;
    background: rgba(100,100,100,.3)
  }
  .techinput_index {
    position: absolute;
    top: 2px;
    left: 5px;
    text-shadow: 1px 1px 2px var(--white-O);
    font-weight: bold;
  }
}

.techinput_attribute {
  grid-template-areas: 'editorial main control';
  grid-template-columns: 118px 1fr 72px;
  grid-template-rows: auto;
  .techinput_attribute_editorial {
    display: flex;
    grid-area: editorial;
    justify-content: space-between;
    align-items: flex-start;
  }
  .techinput_attribute_control {
    display: flex;
    grid-area: control;
    justify-content: space-between;
    align-items: flex-start;
  }
  .techinput_attribute_main {
    grid-area: main;
    display: grid;
    grid-gap: 5px;
    &.type-string { grid-template-columns: 1fr 100px 1fr 100px; }
    &.type-markdown { grid-template-columns: 1fr 100px 150px; }
    &.type-html { grid-template-columns: 1fr 100px 150px; }
    &.type-widget { grid-template-columns: 1fr 200px; }
    &.type-seperator { grid-template-columns: 100px 2fr 1fr 100px; }
    &.type-link { grid-template-columns: 2fr 1fr auto; }
    &.type-media { grid-template-columns: 1fr 200px; }
  }
  .techinput_attribute_support { grid-row: 2; grid-column: 1 / span 3; }

  .techinput_attribute__markdown {
    p { margin: 0; }
    textarea {
      width: 100%;
      resize: vertical;
      font-family:'Courier New', Courier, monospace
    }
  }
}
.attribute_type {
  display: flex;
  background: var(--black-1);
  border-radius: 8px;
  padding: 2px;
  margin: 0 5px;
  box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
  button {
    flex: 1 1;
    margin: 0!important;
    padding: 8px 10px!important;
    border-radius: 6px!important;
    display: inline-flex;
    justify-content: center;
    &[disabled] { opacity: .3; }
    &:not(:last-child) { margin-right: 2px!important; }
    &.current_type {
      background: white;
      color: black;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
