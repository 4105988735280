<template>
  <div>
    <navigation>
      <div style="display: flex; gap: 5px">
        <div>
          <router-link to="/associations/add">
            <rg-button
              type="is-primary"
              icon-left="building"
              title="Anlegen"
              label="Anlegen"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
            />
          </router-link>
          <rg-dropdown>
            <template #trigger>
              <rg-button
                icon="chevron-down"
                style="border-top-left-radius: 0; border-bottom-left-radius: 0"
              />
            </template>
            <router-link to="/contracts/add">
              <rg-button icon-left="file-contract" label="Vertrag" />
            </router-link>
            <router-link to="/accounts/add">
              <rg-button icon-left="user-plus" label="Account" />
            </router-link>
            <router-link to="/companies/add">
              <rg-button icon-left="industry" label="Unternehmen" />
            </router-link>
            <router-link to="/associations/add">
              <rg-button icon-left="building" label="Unternehmensgruppe" />
            </router-link>
          </rg-dropdown>
        </div>
      </div>
    </navigation>
    <div class="rg-grid" style="padding: 15px">
      <div class="level w12">
        <h1>Unternehmensgruppe anlegen</h1>
        <div>
          <rg-button
            type="is-primary"
            icon-left="save"
            label="Speichern"
            :disabled="!checkFormInput()"
            @click="addAssociation()"
          />
        </div>
      </div>
      <rg-widget
        v-if="showUsers"
        :title="clickedInput === 'manager' ? 'Manager Liste' : 'Pricing Liste'"
        class="w6 form"
        :style="{
          zIndex: 2,
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '20px',
          width: clickedInput === 'manager' ? '300px' : '650px',
          minHeight: '428px',
        }"
      >
        <template #data>
          <div style="display: flex; align-items: center">
            <input
              v-model="resultFilter.search"
              type="text"
              placeholder="Suche..."
              style="margin-right: 10px"
              @input="dispatchSearch()"
              @keypress.enter="dispatchSearch(0)"
            />
            <rg-button
              type="is-primary"
              label="Schließen"
              style="margin-bottom: 10px"
              @click="showUsers = false"
            />
          </div>
          <div
            v-if="clickedInput === 'manager'"
            style="
              padding: 2px;
              margin-bottom: 4px;
              background-color: #33333333;
              cursor: pointer;
              transition: background-color 0.3s ease;
            "
            @click="
              (data.association_manager_id = null),
                (showUsers = false),
                (selectedUser = null)
            "
            @mouseover="hoverEffect($event, '#6b6b6b')"
            @mouseout="hoverEffect($event, '#33333333')"
          >
            None
          </div>
          <div
            v-if="clickedInput === 'pricing'"
            style="
              padding: 2px;
              margin-bottom: 4px;
              background-color: #33333333;
              cursor: pointer;
              transition: background-color 0.3s ease;
            "
            @click="
              (data.association_pricing_id = null),
                (showUsers = false),
                (page.current = 1),
                (selectedPricing = null)
            "
            @mouseover="hoverEffect($event, '#6b6b6b')"
            @mouseout="hoverEffect($event, '#33333333')"
          >
            None
          </div>
          <div v-if="clickedInput === 'manager'">
            <div
              v-for="user in users"
              :key="user.id"
              style="
                padding: 2px;
                background-color: #33333333;
                cursor: pointer;
                transition: background-color 0.3s ease;
              "
              @click="
                (data.association_manager_id = user.id),
                  (showUsers = false),
                  (page.current = 1),
                  (selectedUser = user.userName + ' (' + user.id + ')')
              "
              @mouseover="hoverEffect($event, '#6b6b6b')"
              @mouseout="hoverEffect($event, '#33333333')"
            >
              {{ user.userName }} ({{ user.id }})
            </div>
          </div>
          <div v-if="clickedInput === 'pricing'">
            <div
              v-for="pricing in pricings"
              :key="pricing.id"
              style="
                padding: 2px;
                background-color: #33333333;
                cursor: pointer;
                transition: background-color 0.3s ease;
              "
              @click="
                (data.association_pricing_id = pricing.id),
                  (showUsers = false),
                  (selectedPricing = selectedPricing =
                    pricing.tier +
                    ' - ' +
                    pricing.description +
                    ' (' +
                    pricing.invoice_amount +
                    ' ' +
                    pricing.invoice_currency +
                    ')')
              "
              @mouseover="hoverEffect($event, '#6b6b6b')"
              @mouseout="hoverEffect($event, '#33333333')"
            >
              {{ pricing.tier }} | {{ pricing.description }} ({{
                pricing.invoice_amount
              }}
              {{ pricing.invoice_currency }})
            </div>
          </div>

          <div
            style="display: flex; justify-content: center;, padding: 4px; margin-bottom: 40px; position: absolute; bottom: 0; left: 0; right: 0;"
          >
            <rg-button
              :disabled="page.current <= 1"
              icon="angle-double-left"
              @click="stride(page.current, 1)"
            />
            <rg-button
              :disabled="page.current <= 1"
              icon="angle-left"
              style="padding-left: 3px; margin-left: 3px"
              @click="stride(page.current, page.current - 1)"
            />
            <rg-button
              v-if="clickedInput === 'manager'"
              :disabled="page.current >= Math.ceil(userPages)"
              icon="angle-right"
              style="padding-left: 5px; margin-left: 5px"
              @click="stride(page.current, page.current + 1)"
            />
            <rg-button
              v-if="clickedInput === 'manager'"
              :disabled="page.current >= Math.ceil(userPages)"
              icon="angle-double-right"
              style="padding-left: 3px; margin-left: 3px"
              @click="stride(page.current, Math.ceil(userPages))"
            />
            <rg-button
              v-if="clickedInput === 'pricing'"
              :disabled="page.current >= Math.ceil(pricingPages)"
              icon="angle-right"
              style="padding-left: 5px; margin-left: 5px"
              @click="stride(page.current, page.current + 1)"
            />
            <rg-button
              v-if="clickedInput === 'pricing'"
              :disabled="page.current >= Math.ceil(pricingPages)"
              icon="angle-double-right"
              style="padding-left: 3px; margin-left: 3px"
              @click="stride(page.current, Math.ceil(pricingPages))"
            />
          </div>
          <div
            v-if="clickedInput === 'manager'"
            style="
              display: flex;
              justify-content: space-between;
              margin-left: 20px;
              margin-right: 20px;
              margin-bottom: 20px;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
            "
          >
            <span>{{ totalUsersCount }} Ergebnisse</span>
            <span>{{ page.current }} von {{ Math.ceil(userPages) }}</span>
          </div>
          <div
            v-if="clickedInput === 'pricing'"
            style="
              display: flex;
              justify-content: space-between;
              margin-left: 20px;
              margin-right: 20px;
              margin-bottom: 25px;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
            "
          >
            <span>{{ totalPricingsCount }} Ergebnisse</span>
            <span>{{ page.current }} von {{ Math.ceil(pricingPages) }}</span>
          </div>
        </template>
      </rg-widget>
      <rg-widget title="Allgemein" class="w6 form">
        <template #data>
          <strong class="level"><span>Titel</span> </strong>
          <input
            v-model="data.association_title"
            type="text"
            @click="showUsers = false"
          />
          <strong class="level"><span>Pricing</span> </strong>
          <input
            v-model="selectedPricing"
            readonly
            type="text"
            @click="
              (showUsers = true),
                (page.current = 1),
                (resultFilter.search = null),
                stride(page.current, page.current),
                (clickedInput = 'pricing')
            "
          />
          <strong class="level">
            <span>Manager</span>
          </strong>
          <input
            v-model="selectedUser"
            type="text"
            readonly
            @click="
              (showUsers = true),
                (page.current = 1),
                (resultFilter.search = null),
                stride(page.current, page.current),
                (clickedInput = 'manager')
            "
          />
        </template>
      </rg-widget>
      <rg-widget title="Informationen" class="w6 form">
        <template #data>
          <strong class="level"><span>Beschreibung</span> </strong>
          <textarea
            v-model="data.association_description"
            cols="30"
            rows="3"
            @click="showUsers = false"
          />
          <strong class="level"><span>Kontaktinformationen</span> </strong>
          <textarea
            v-model="data.alternate_contact_information"
            cols="30"
            rows="5"
            @click="showUsers = false"
          />
        </template>
      </rg-widget>
    </div>
  </div>
</template>
<script>
import { ref } from "@vue/composition-api";
import superagent from "superagent";
import { altBackendUrl } from "@/constants.js";
import Vue from "vue";

export default {
  components: {
    "rg-dropdown": () => import("@/components/elements/rg-dropdown"),
    "rg-widget": () => import("@/components/elements/rg-widget"),
  },
  beforeRouteLeave(to, from, next) {
    next(
      !this.checkInput() ||
        window.confirm("Bearbeitung aktiviert. Formular wirklich verlassen?")
    );
  },

  setup(props, context) {
    let showUsers;
    let clickedInput = ref({});
    let data = ref({
      association_title: "",
      association_description: "",
      association_pricing_id: "",
      association_manager_id: "",
      alternate_contact_information: "",
    });
    let users = ref({});
    let selectedUser = ref();
    let totalUsersCount = ref({});
    let userPages = ref({});

    let pricings = ref({});
    let selectedPricing = ref();
    let totalPricingsCount = ref({});
    let pricingPages = ref({});

    let page = ref({
      current: 1,
    });
    let resultFilter = ref({
      skip: 8,
      limit: 8,
      search: "",
      offset: 1,
      timeout: null,
      loading: true,
    });
    getUsers();
    getPricings();

    const dispatchSearch = (ms = 300) => {
      clearTimeout(resultFilter.value.timeout);
      resultFilter.value.timeout = setTimeout(() => {
        resultFilter.value.loading = true;
        page.value.current = 1;
        if (resultFilter.value.search === "") {
          resultFilter.value.offset = 1;
        }
        if (clickedInput.value === "manager") {
          resultFilter.value.offset = 0;
          getUsers(resultFilter.value.search);
        } else {
          resultFilter.value.offset = 1;
          getPricings(resultFilter.value.search);
        }
      }, resultFilter.value.search?.length && ms);
    };

    function getUsers(search = resultFilter.value.search) {
      search = search?.length >= 3 ? search : "";
      const gqQueryUsers = `
        query Query($skip: Int!, $limit: Int!, $search: String, $filter: UserFilter) {
          Users(skip: $skip, limit: $limit, search: $search, filter: $filter) {
              nodes{
                id,
                userName,
              }
          }
          Statistics{totalUsersCount(search: $search)}
        }
        `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryUsers,
          variables: {
            skip: resultFilter.value.offset,
            limit: resultFilter.value.limit,
            search: search,
            filter: { roles: ["user"], status: true },
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          users.value = res?.body?.data?.Users?.nodes;
          totalUsersCount.value = res?.body?.data?.Statistics?.totalUsersCount;
          userPages.value = totalUsersCount.value / resultFilter.value.skip;
        })
        .finally(() => {
          resultFilter.value.loading = false;
        });
    }

    function getPricings(search = resultFilter.value.search) {
      search = search?.length >= 2 ? search : "";
      const gqQueryUsers = `
        query Pricings($skip: Int!, $limit: Int!, $search: String) {
          Pricings(skip: $skip, limit: $limit, search: $search) {
            id
            tier
            description
            invoice_amount
            invoice_currency
          }
          Statistics{totalPricingsCount(search: $search, isActive: true)}
        }
        `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryUsers,
          variables: {
            skip: resultFilter.value.offset - 1,
            limit: resultFilter.value.limit,
            search: search,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          pricings.value = res?.body?.data?.Pricings;
          totalPricingsCount.value =
            res?.body?.data?.Statistics?.totalPricingsCount;
          pricingPages.value =
            totalPricingsCount.value / resultFilter.value.skip;
        })
        .finally(() => {
          resultFilter.value.loading = false;
        });
    }

    function stride(from, to) {
      page.value.current = to;
      resultFilter.value.offset =
        page.value.current === 1
          ? 1
          : resultFilter.value.skip * page.value.current -
            resultFilter.value.skip;
      if (clickedInput.value === "manager") {
        getUsers();
      } else {
        getPricings();
      }
    }

    function addAssociation() {
      const payload = {
        associationTitle: data.value.association_title || null,
        associationPricingId:
          parseInt(data.value.association_pricing_id) || null,
        associationManagerId:
          parseInt(data.value.association_manager_id) || null,
        associationDescription: data.value.association_description || null,
        alternateContactInformation:
          data.value.alternate_contact_information || null,
      };
      const createCompanyAssociationMutation = `mutation Mutation($associationTitle: String!,
         $associationPricingId: Int, $associationManagerId: Int, $associationDescription: String,
         $alternateContactInformation: String) {
        	CompanyAssociations(associationTitle: $associationTitle, associationPricingId: $associationPricingId,
          associationManagerId: $associationManagerId, associationDescription: $associationDescription,
          alternateContactInformation: $alternateContactInformation
          ) {
            id
          }
        }`;
      superagent
        .post(altBackendUrl)
        .send({
          query: createCompanyAssociationMutation,
          variables: {
            associationTitle: payload.associationTitle,
            associationPricingId: payload.associationPricingId,
            associationManagerId: payload.associationManagerId,
            associationDescription: payload.associationDescription,
            alternateContactInformation: payload.alternateContactInformation,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((resAssociation) => {
          if (!resAssociation.body?.data?.errors && resAssociation.body?.data) {
            Vue.$toast.open({
              icon: "check",
              message: "Die Gruppe wurde erfolgreich gespeichert.",
            });
            setTimeout(() => {
              context.root.$router.push(
                `/associations/${resAssociation.body.data.CompanyAssociations.id}`
              );
            }, 2000);
          } else {
            Vue.$toast.open({
              icon: "times-circle",
              type: "error",
              message: "FEHLER: Die Gruppe konnte nicht gespeichert werden.",
            });
            console.table(payload);
            console.log(resAssociation.body?.data?.errors);
          }
        })
        .catch((err) => {
          Vue.$toast.open({
            icon: "times-circle",
            type: "error",
            message: "FEHLER: Die Gruppe konnte nicht gespeichert werden.",
          });
          console.table(payload);
          console.error(err?.response?.body?.errors);
        });
    }
    function checkFormInput() {
      if (data.value.association_title) return true;
    }

    function checkInput() {
      if (
        data.value.association_title ||
        data.value.association_description ||
        data.value.association_pricing_id ||
        data.value.association_manager_id ||
        data.value.alternate_contact_information
      )
        return true;
    }
    function hoverEffect(event, color) {
      event.target.style.backgroundColor = color;
    }
    return {
      data,
      addAssociation,
      checkFormInput,
      showUsers,
      dispatchSearch,
      page,
      stride,
      hoverEffect,
      clickedInput,
      resultFilter,
      users,
      userPages,
      totalUsersCount,
      pricings,
      pricingPages,
      totalPricingsCount,
      getPricings,
      getUsers,
      selectedUser,
      selectedPricing,
      checkInput,
    };
  },
};
</script>

<style lang="scss" module>
.detail {
  grid-auto-flow: dense;
}

.changed {
  height: 10px;
  width: 10px;
  background: red;
  display: inline-block;
  border-radius: 5px;
  margin-left: 5px;
}
</style>
