<template>
  <div>
    <navigation><br></navigation>
    <main style="padding: 50px 10px 0;">
      <h1>Herzlich Willkommen im Adminbereich von Rep.Guide!</h1>
      <code>Hier steht jetzt auch was, also haben wir endlich Inhalte auf der Startseite 🥳</code>
    </main>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>