<template>
  <div
    v-if="element"
    :class="[
      $style.techinput,
      $style.techinput_group,
      selected ? $style.is_selected : null,
    ]"
    @click="$emit('select', element.line)"
  >
    <code :class="$style.techinput_index">{{ element.line + 1 }}</code>
    <div
      class="flex-center"
      :style="'color: ' + getColor(element.level)"
    >
      <fa
        class="fa-lg"
        icon="folder"
      />
    </div>
    <rg-button
      tabindex="-1"
      icon="flag"
      :type="{ 'is-flagged': element.flagged }"
      disabled
    />
    <div
      :style="{
        border: '1px solid var(--card-light-stack)',
        background: 'var(--input-background)',
        padding: '8px',
        borderRadius: '8px',
        fontSize: '1em',
        lineHeight: '1em',
        color: 'var(--text-dark)',
        userSelect: 'all',
        cursor: 'text',
      }"
    >
      {{ element.title }}
    </div>
    <rg-button
      icon="copy"
      title="Gruppe kopieren"
      @click="$emit('copy')"
    />
    <div style="display: grid; place-content: center; opacity: 0.2">
      <fa
        class="fa-fw fa-lg"
        icon="arrows-alt"
      />
    </div>
    <p
      :style="{
        margin: 0,
        color: element.description ? 'black' : 'gray',
        opacity: element.description ? 1 : 0.5,
      }"
    >
      <fa
        icon="info-circle"
        class="fa-fw"
      />&nbsp;{{
        element.description || "Keine Beschreibung"
      }}
    </p>
    <p
      :style="{
        margin: 0,
        color: element.comment ? '#1575f7' : 'gray',
        opacity: element.comment ? 1 : 0.5,
      }"
    >
      <fa
        icon="comment"
        class="fa-fw"
      />&nbsp;{{
        element.comment || "Kein Kommentar"
      }}
    </p>
  </div>
</template>

<script>
import element from "@/components/elements/tech_preview/_rg-element";

export default { extends: element };
</script>

<style lang="scss" module>
.techinput {
  padding: 5px;
  border-radius: 10px;
  position: relative;
  background: var(--card-light-stack);
  display: grid;
  grid-gap: 5px;
  &.is_selected {
    box-shadow: 0 0 0 4px #88fc;
  }
  &:hover {
    z-index: 9999;
    background: rgba(100, 100, 100, 0.3);
  }
  .techinput_index {
    position: absolute;
    top: 2px;
    left: 5px;
    text-shadow: 1px 1px 2px var(--white-O);
    font-weight: bold;
  }
}

.techinput_group {
  grid-template-columns: 36px 36px 1fr 36px 36px;
  textarea,
  p {
    grid-column: 1 / span 6;
  }
  span {
    grid-column: 2;
    text-align: center;
  }
  .techinput_attribute__comment {
    grid-column: 1 / span 6;
  }
}
</style>
