<!-- FIXME: DONT COPY AND PASTE-->
<template>
  <transition
    enter-active-class="fadeInUp"
    leave-active-class="fadeOut"
  >
    <div
      v-show="isActive"
      role="alert"
      class="toast"
      :class="[`toast-${type}`, `is-${position}`]"
      @click="whenClicked"
    >
      <fa
        v-if="icon"
        :icon="icon"
        class="fa-fw"
      />
      <span class="toast-text">{{ message }}</span>
    </div>
  </transition>
</template>

<script>
  import Vue from 'vue'
  export default {
    name: 'Toast',
    props: {
      message: { type: String, required: true },
      type: { type: String, default: 'success' },
      position: { type: String, default: 'bottom-right' },
      icon: { type: String, default: '', },
      duration: { type: Number, default: 3000 },
      dismissible: { type: Boolean, default: true },
      onClose: { type: Function, default: () => {} },
      onClick: { type: Function, default: () => {} },
      queue: Boolean,
      container: { type: [Object, Function, typeof window !== 'undefined' ? window.HTMLElement : Object], default: null },
    },
    data() {
      return {
        isActive: false,
        parentTop: null,
        parentBottom: null,
      }
    },
    computed: {
      correctParent() {
        switch (this.position) {
          case 'top-right':
          case 'top':
          case 'top-left':
            return this.parentTop;
          case 'bottom-right':
          case 'bottom':
          case 'bottom-left':
            return this.parentBottom;
        }
        return null
      },
      transition() {
        switch (this.position) {
          case 'top-right':
          case 'top':
          case 'top-left':
            return {
              enter: 'fadeInDown',
              leave: 'fadeOut'
            };
          default:
            return {
              enter: 'fadeInUp',
              leave: 'fadeOut'
            }
        }
      },
    },
    beforeMount() {
      this.setupContainer()
    },
    mounted() {
      this.showNotice();
      new Vue().$on('toast-clear', this.close)
    },
    methods: {
      setupContainer() {
        this.parentTop = document.querySelector('.notices.is-top');
        this.parentBottom = document.querySelector('.notices.is-bottom');
        // No need to create them, they already exists
        if (this.parentTop && this.parentBottom) return;
        if (!this.parentTop) {
          this.parentTop = document.createElement('div');
          this.parentTop.className = 'notices is-top';
        }
        if (!this.parentBottom) {
          this.parentBottom = document.createElement('div');
          this.parentBottom.className = 'notices is-bottom'
        }
        const container = this.container || document.body;
        container.appendChild(this.parentTop);
        container.appendChild(this.parentBottom);
        let containerParentClass = 'is-custom-parent';
        if (this.container) {
          this.parentTop.classList.add(containerParentClass);
          this.parentBottom.classList.add(containerParentClass)
        }
      },
      shouldQueue() {
        if (!this.queue) return false;
        return (
            this.parentTop.childElementCount > 0 ||
            this.parentBottom.childElementCount > 0
        )
      },
      close() {
        clearTimeout(this.timer);
        this.isActive = false;
        // Timeout for the animation complete before destroying
        setTimeout(() => {
          this.onClose.apply(null, arguments);
          this.$destroy();
          this.removeElement(this.$el)
        }, 150)
      },
      removeElement(el) {
        if (typeof el.remove !== 'undefined') {
          el.remove()
        } else {
          el.parentNode.removeChild(el)
        }
      },
      showNotice() {
        if (this.shouldQueue()) {
          // Call recursively if should queue
          setTimeout(() => this.showNotice(), 250);
          return
        }
        this.correctParent.insertAdjacentElement('afterbegin', this.$el);
        this.isActive = true;
        this.timer = setTimeout(() => this.close(), this.duration)
      },
      whenClicked() {
        if (!this.dismissible) return;
        this.onClick.apply(null, arguments);
        this.close()
      }
    },
  }
</script>

<style lang="scss">
.notices {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2em;
  overflow: hidden;
  z-index: 99999999999;
  pointer-events: none;
  flex-direction: column-reverse;

  .toast {
    display: inline-flex;
    align-items: center;
    align-self: flex-end;
    animation-duration: 150ms;
    margin: 0.5em 0;
    background: #1e1e1e;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    pointer-events: auto;
    opacity: 0.95;
    color: #fff;
    min-height: 3em;
    cursor: pointer;
    padding: .25em 1em;

    &-error {
      background: #ff3860;
    }

    svg {
      margin-right: .5em;
      font-size: 1.5em;
    }

    .toast-text {
      word-break: break-all;
    }
  }
}

.fadeInUp {
  transition: all .05s ease;
  transform: translateY(30px);
  opacity: 0!important;
}
.fadeOut {
  transition: all .2s ease;
  opacity: 0!important;
}

</style>