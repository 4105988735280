<template>
  <div>
    <navigation>
      <div style="display: flex; gap: 5px">
        <div>
          <router-link to="/accounts/add">
            <rg-button
              type="is-primary"
              icon-left="user-plus"
              title="Anlegen"
              label="Anlegen"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
            />
          </router-link>
          <rg-dropdown>
            <template #trigger>
              <rg-button
                icon="chevron-down"
                style="border-top-left-radius: 0; border-bottom-left-radius: 0"
              />
            </template>
            <router-link to="/accounts/add">
              <rg-button
                icon-left="user-plus"
                label="Account"
              />
            </router-link>
            <router-link to="/companies/add">
              <rg-button
                icon-left="users"
                label="Unternehmen"
              />
            </router-link>
            <router-link to="/associations/add">
              <rg-button
                icon-left="building"
                label="Unternehmensgruppe"
              />
            </router-link>
            <router-link to="/contracts/add">
              <rg-button
                icon-left="file-contract"
                label="Vertrag"
              />
            </router-link>
          </rg-dropdown>
        </div>
      </div>
    </navigation>
    <main>
      <h1>Nutzerverwaltung</h1>
      <br>
      <rg-tabs>
        <router-link
          tag="button"
          replace
          to="/accounts/"
        >
          <fa icon="users" />&nbsp;Accounts
        </router-link>
        <router-link
          tag="button"
          replace
          to="/companies/"
        >
          <fa icon="industry" />&nbsp;Unternehmen
        </router-link>
        <router-link
          tag="button"
          replace
          to="/associations/"
        >
          <fa icon="building" />&nbsp;Unternehmensgruppen
        </router-link>
      </rg-tabs>
      <br>
      <input
        v-model="resultFilter.search"
        type="text"
        placeholder="Unternehmensname, Granit-ID"
        @input="dispatchSearch()"
        @keypress.enter="dispatchSearch(0)"
      >
      <br>
      <div v-if="!resultFilter.loading">
        <h2>{{ userCount }} Ergebnisse</h2>
        <rg-button
          :disabled="page.current <= 1"
          icon="angle-double-left"
          @click="stride(page.current, 1)"
        />
        <rg-button
          :disabled="page.current <= 1"
          icon="angle-left"
          @click="stride(page.current, page.current - 1)"
        />
        {{ page.current }} von {{ Math.ceil(pages) }}
        <rg-button
          :disabled="page.current >= Math.ceil(pages)"
          icon="angle-right"
          @click="stride(page.current, page.current + 1)"
        />
        <rg-button
          :disabled="page.current >= Math.ceil(pages)"
          icon="angle-double-right"
          @click="stride(page.current, Math.ceil(pages))"
        />
      </div>
      <rg-table
        :rows="data"
        cols="id, login, email, status, roles, companyId, companyGranitid, companyTitle, country, language_code"
      >
        <template #default="slotProps">
          <div v-show="resultFilter.loading">
            LOADING
          </div>
          <template v-for="row in slotProps.chunk">
            <tr :key="row.id">
              <td
                v-for="(value, property) in row"
                :key="property"
              >
                <code
                  v-if="
                    [
                      'id',
                      'companyGranitid',
                      'email',
                      'companyId',
                      'login',
                    ].includes(property)
                  "
                  v-clipboard="value"
                  v-clipboard:success="clipboardSuccessHandler"
                >
                  {{ value }}
                </code>
                <span v-else>{{ value }}</span>
              </td>
              <td>
                <div
                  class="flex-right"
                  style="gap: 5px"
                >
                  <router-link :to="'/accounts/' + row.id">
                    <rg-button
                      label="Öffnen"
                      type="is-primary"
                      title="Diesen Eintrag bearbeiten"
                    />
                  </router-link>
                  <router-link :to="'/companies/' + row.companyId">
                    <rg-button
                      label="Zum Unternehmen"
                      title="Unternehmen dieses Accounts anzeigen"
                    />
                  </router-link>
                  <dropdown :width="225">
                    <template #trigger>
                      <rg-button icon="chevron-down" />
                    </template>
                    <p><strong>Aktionen</strong></p>
                    <rg-button
                      type="is-primary"
                      icon-left="headset"
                      label="Ticket erstellen"
                      @click="createTicket(row)"
                    />
                  </dropdown>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </rg-table>
    </main>
  </div>
</template>

<script>
  import rg_tabs from "@/components/elements/rg-tabs";
  import { ref } from "@vue/composition-api";
  import dropdown from "@/components/elements/rg-dropdown";
  import superagent from "superagent";
  import { backendUrl } from "@/constants.js";
  import Vue from "vue";
  // import { altBackendUrl } from "../../constants";
  export default {
    name: "Accounts",
    components: {
      dropdown,
      "rg-dropdown": dropdown,
      rgTabs: rg_tabs,
      rgTable: () => import("@/components/elements/Table.vue"),
    },

    // eslint-disable-next-line no-unused-vars
    setup(props, context) {
      let data = ref([]);
      let resultFilter = ref({
        step: 25,
        first: 25,
        offset: null,
        search: "",
        loading: true,
        timeout: null,
      });
      let userCount = ref({});
      let pages = ref({});
      let page = ref({
        current: 1,
      });

      getData();

      const dispatchSearch = (ms = 300) => {
        clearTimeout(resultFilter.value.timeout);

        resultFilter.value.timeout = setTimeout(() => {
          resultFilter.value.loading = true;
          resultFilter.value.offset = null;
          page.value.current = 1;
          getData(resultFilter.value.search);
        }, resultFilter.value.search?.length && ms);
      };

      dispatchSearch(0);

      function getData(search = resultFilter.value.search) {
        search = search?.length >= 3 ? search : "";
        const gqQueryUsers = `query Query($first: Int, $offset: Int, $search: String, $statisticSearch: String){
        Users(first: $first, offset: $offset, search: $search) {
          id,
          login,
          email,
          status,
          roles,
          country,
          Company {
            companyId: id,
            companyTitle: company_title,
            companyGranitid: granitid,
          }
          language_for_contact {
            language_code
            language_name
          }
        }
      Statistics{
        totalUsersCount(search: $statisticSearch)
      }
    }`;
        superagent
          .post(backendUrl)
          .send({
            query: gqQueryUsers,
            variables: {
              first: resultFilter.value.first,
              offset: resultFilter.value.offset,
              search: search,
              statisticSearch: search,
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            data.value = formatInput(res.body.data.Users);
            userCount.value = res.body.data.Statistics.totalUsersCount;
            pages.value = userCount.value / resultFilter.value.step;
          })
          .finally(() => {
            resultFilter.value.loading = false;
          });
      }

      function formatInput(inp) {
        let tmpArr = [];
        for (const obj of inp) {
          tmpArr.push(flattenInput(obj));
        }
        return tmpArr;
      }

      function flattenInput(obj) {
        let tempObj = {};
        for (const [key, value] of Object.entries(obj)) {
          let tempObj2 = {};
          if (
            typeof obj[key] === "object" &&
            obj[key] != null &&
            key != "roles"
          ) {
            tempObj2 = flattenInput(obj[key]);
            Object.assign(tempObj, tempObj2);
          } else if (key == "roles") {
            tempObj[key] = value.join(", ");
          } else {
            tempObj[key] = value;
          }
        }
        return tempObj;
      }

      function clipboardSuccessHandler() {
        Vue.$toast.open({
          message: "Erfolgreich kopiert",
          icon: "clipboard-check",
        });
      }

      function stride(from, to) {
        page.value.current = to;
        resultFilter.value.offset =
          page.value.current === 1
            ? null
            : resultFilter.value.step * page.value.current -
              resultFilter.value.step;
        getData();
      }

      function createTicket(ctx, subject = "") {
        window.open(
          `https://frixlab.fricke.de/innovationlab/repguide/support/-/issues/new?issue[title]=${ctx.id}%20-%20${ctx.login}%20-%20${ctx.companyGranitid}%20-%20${subject}`,
          "_blank"
        );
      }

      return {
        data,
        clipboardSuccessHandler,
        dispatchSearch,
        resultFilter,
        page,
        pages,
        userCount,
        stride,
        createTicket,
      };
    },
  };
</script>
