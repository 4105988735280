<template>
  <div>
    <navigation />
    <main>
      <h1><fa icon="folder-open" />&nbsp;Maschinentabs</h1>
      <p>Alle Maschinen Tabs</p>
      <div class="level">
        <h2>{{ data.length }} Tabs</h2>
        <router-link to="machinetabs/add">
          <rg-button
            class="is-large"
            icon-left="plus"
            label="Neuen Tab anlegen"
          />
        </router-link>
      </div>
      <br>
      <table class="rg-table">
        <tr>
          <th>ID</th>
          <th>Prio</th>
          <th>Typ<br><small>Untertyp</small></th>
          <th>Icon</th>
          <th>Titel<br><small>Untertitel</small></th>
          <th>Tag</th>
          <th>Farbe</th>
        </tr>
        <tr
          v-for="tab in data"
          :key="tab.tab_subtype"
        >
          <td>
            <code>{{ tab.id }}</code>
          </td>
          <td>
            <strong>{{ tab.tab_priority }}</strong>
          </td>
          <td>
            <strong>{{ tab.tab_subtype }}</strong><br>
            <small>{{ tab.tab_type }}</small>
          </td>
          <td style="display: flex; align-items: center">
            <div style="margin-right: 10px; width: 30px">
              <fa-layers v-if="tab.tab_icon === 'jaltest'">
                <fa
                  icon="circle"
                  transform="shrink-3 left-1.5 up-1.5"
                />
                <fa
                  icon="circle"
                  transform="right-5 down-5 shrink-10"
                />
              </fa-layers>
              <fa
                v-else
                :icon="tab.tab_icon"
                class="fa-fw"
              />
            </div>
          </td>
          <td>
            <strong>{{ tab.tab_title }}</strong><br>
            <small v-if="tab.tab_subtitle">{{ tab.tab_subtitle }}</small>
            <small
              v-else
              style="color: var(--black-5)"
            >ohne</small>
          </td>
          <td>
            <span
              v-if="tab.tab_tag"
              class="tag"
              style="text-transform: uppercase"
            >{{ tab.tab_tag }}</span>
            <small
              v-else
              style="color: var(--black-5)"
            >ohne</small>
          </td>
          <td>
            <strong :style="'color:' + tab.tab_color">{{
              tab.tab_color
            }}</strong>
          </td>
          <td style="text-align: right">
            <rg-dropdown
              position="top-left"
              style="margin-right: 5px; text-align: left"
              :width="500"
            >
              <template #trigger>
                <rg-button
                  icon-left="cogs"
                  label="Optionen"
                  icon-right="chevron-up"
                />
              </template>
              <p>
                <strong
                  style="margin-bottom: 10px; display: block"
                >Tab-Freigaben</strong>
                Die Freigaben basieren auf den Modulen, die beim Erstellen und Bearbeiten der Tabs gesetzt werden.
              </p>
              <hr>
              <p>
                <strong
                  style="margin-bottom: 10px; display: block"
                >Tab-Optionen</strong>
                Optionen können nur von Administratoren in der Datenbank
                angepasst werden.
              </p>
              <pre
                style="
                  overflow: auto;
                  margin: 10px 15px;
                  padding: 10px;
                  border-radius: 6px;
                  background: var(--black-1);
                "
              >{{ tab.tab_options || "keine Optionen definiert" }}</pre>
            </rg-dropdown>
            <router-link
              :to="'/machinetabs/' + tab.id"
            >
              <rg-button
                icon-left="pen"
                label="Bearbeiten"
                type="is-primary"
              />
            </router-link>
          </td>
        </tr>
      </table>
    </main>
  </div>
</template>

<script>
import dropdown from "@/components/elements/rg-dropdown";
import { ref } from "@vue/composition-api";
import superagent from "superagent";
import {backendUrl} from "@/constants.js";
import Vue from "vue";

export default {
  name: "Machinetabs",
  components: {
    "rg-dropdown": dropdown,
  },

  // eslint-disable-next-line no-unused-vars
  setup(props, context) {
    let data = ref([]);

    const gqQueryTabTypes = `
    query Query {
      TabTypes {
        id
        tab_type
        tab_subtype
        tab_title
        tab_subtitle
        tab_icon
        tab_color
        tab_tag
        tab_priority
        createdat
        updatedat
        tab_options
      }
    }
    `;

    superagent
      .post(backendUrl)
      .send({
        query: gqQueryTabTypes,
      })
      .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
      .then((res) => {
        data.value = res.body.data.TabTypes;
      })
      .catch((error) => {
        if (error.status === 426) {
          Vue.$toast.open({
            message: "Sitzung abgelaufen. Bitte neu anmelden",
            icon: "exclamation",
            type: "error",
          });
          context.root.$store.dispatch("logout").then(() => {
            context.root.$router.push("/login");
          });
        }
      });

    return { data };
  },
};
</script>
