<template>
  <section
    class="rg-grid"
    style="position: relative"
  >
    <div class="w12 level">
      <div>
        <h2>Wartungspläne</h2>
      </div>
      <div>
        <rg-button
          label="Neuen Plan hochladen"
          icon-left="plus"
          type="is-primary"
          @click="newServicePlan.modalOpen = true"
        />
      </div>
    </div>

    <section class="w12">
      <article
        v-for="serviceplan in serviceplans"
        :key="serviceplan.id"
        class="card"
        :style="{
          padding: '10px',
          borderRadius: '8px',
          marginBottom: '32px',
        }"
      >
        <header
          :style="{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }"
        >
          <h2>"{{ serviceplan.title }}"</h2>
          <div>
            <rg-button
              label="Löschen"
              icon-left="trash"
              type="is-danger"
              @click="deleteServicePlan(serviceplan.id)"
            />
          </div>
        </header>
        <div class="form">
          <hr>
        </div>
        <div class="rg-grid">
          <div class="w6">
            <em
              :style="{
                textTransform: 'uppercase',
                fontSize: '1rem',
                color: 'var(--black-6)',
                letterSpacing: '0.1rem',
                fontStyle: 'normal',
              }"
            >
              Meta-informationen
            </em>
            <table style="width: 100%; table-layout: fixed; text-align: left">
              <tr>
                <th style="width: 160px; vertical-align: top">
                  <h3>Wartungsplan-ID</h3>
                </th>
                <td>
                  <code style="font-size: 1rem">
                    {{ serviceplan.id }}
                  </code>
                </td>
              </tr>
              <tr>
                <th style="width: 160px; vertical-align: top">
                  <h3>Eingabesprache</h3>
                </th>
                <td v-if="languages.length">
                  {{
                    languages.find((l) => l.id == serviceplan.language)
                      .language_name
                  }}
                </td>
                <td v-else>
                  Sprach-ID {{ serviceplan.language }}
                </td>
              </tr>
              <tr>
                <th style="width: 160px; vertical-align: top">
                  <h3>Quelle der Daten</h3>
                </th>
                <td>
                  <a
                    v-if="serviceplan.source"
                    :href="serviceplan.source"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ serviceplan.source }}
                  </a>
                  <span
                    v-else
                    style="color: var(--black-5)"
                  >
                    Keine Quelle angegeben
                  </span>
                </td>
              </tr>
              <tr>
                <th style="width: 160px; vertical-align: top">
                  <h3>Erstellt am</h3>
                </th>
                <td>
                  {{ new Date(serviceplan.createdAt).toLocaleString("de-DE") }}
                </td>
              </tr>
              <tr>
                <th style="width: 160px; vertical-align: top">
                  <h3>Erstellt von</h3>
                </th>
                <span v-if="serviceplan.authorId">
                  <fa
                    icon="user"
                    style="margin-right: 5px"
                  />
                  <strong>
                    {{ serviceplan.Author.firstName }}
                    {{ serviceplan.Author.lastName }} (ID:
                    {{ serviceplan.authorId }})
                  </strong>
                </span>
                <span v-else> Autor Unbekannt </span>
              </tr>
              <tr>
                <th style="width: 160px; vertical-align: top">
                  <h3>Überschrieben am</h3>
                </th>
                <td>
                  <template
                    v-if="serviceplan.createdAt !== serviceplan.updatedAt"
                  >
                    {{
                      new Date(serviceplan.updatedAt).toLocaleString("de-DE")
                    }}
                  </template>
                  <span
                    v-else
                    style="color: var(--black-5)"
                  > Nie </span>
                </td>
              </tr>
              <tr>
                <th style="width: 160px; vertical-align: top">
                  <h3>Dateiname</h3>
                </th>
                <td v-if="serviceplan.fileName">
                  {{ serviceplan.fileName }}
                </td>
                <td
                  v-else
                  style="color: var(--black-5)"
                >
                  /
                </td>
              </tr>
            </table>
          </div>
          <div class="w6">
            <em
              :style="{
                textTransform: 'uppercase',
                fontSize: '1rem',
                color: 'var(--black-6)',
                letterSpacing: '0.1rem',
                fontStyle: 'normal',
              }"
            >
              Statistiken
            </em>
            <table style="width: 100%; table-layout: fixed; text-align: left">
              <tr>
                <th style="width: 160px; vertical-align: top">
                  <h3>Intervalle</h3>
                </th>
                <td>
                  <h3>{{ serviceplan.Intervals.nodes.length }} Stück</h3>
                  <div
                    style="
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      width: 100%;
                      color: var(--black-5);
                    "
                    :title="
                      serviceplan.Intervals.nodes.map((n) => n.title).join(', ')
                    "
                  >
                    {{
                      serviceplan.Intervals.nodes.map((n) => n.title).join(", ")
                    }}
                  </div>
                </td>
              </tr>
              <tr>
                <th style="width: 160px; vertical-align: top">
                  <h3>Wartungsarbeiten</h3>
                </th>
                <td>
                  <h3>
                    {{
                      serviceplan.Groups.nodes.reduce(
                        (acc, group) => acc + group.Tasks.nodes.length,
                        0
                      )
                    }}
                    Einträge auf {{ serviceplan.Groups.nodes.length }} Gruppen
                    verteilt
                  </h3>
                  <div
                    v-for="group in serviceplan.Groups.nodes"
                    :key="group.title"
                    style="
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      width: 100%;
                      color: var(--black-5);
                    "
                    :title="group.Tasks.nodes.map((n) => n.title).join(', ')"
                  >
                    <strong>{{ group.title }}</strong>: {{ group.Tasks.nodes.map((n) => n.title).join(", ") }}
                  </div>
                </td>
              </tr>
              <tr>
                <th style="width: 160px; vertical-align: top">
                  <h3>Teile</h3>
                </th>
                <td>
                  <h3>{{ serviceplan.Parts.nodes.length }} Stück</h3>
                  <div
                    style="
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      width: 100%;
                      color: var(--black-5);
                    "
                    :title="
                      serviceplan.Parts.nodes.map((n) => n.name).join(', ')
                    "
                  >
                    {{ serviceplan.Parts.nodes.map((n) => n.name).join(", ") }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </article>
      <div
        v-if="!serviceplans.length"
        style="text-align: center; padding: 20px; color: #666"
      >
        <p>Keine Wartungspläne vorhanden.</p>
      </div>
    </section>

    <transition name="opacity">
      <section
        v-if="newServicePlan.modalOpen"
        :class="[$style.modal, 'card']"
      >
        <div class="form">
          <h2>Neuen Wartungsplan hochladen</h2>
          <hr>
          <strong>Wartungsplantitel</strong>
          <input
            v-model="newServicePlan.data.title"
            type="text"
          >

          <strong>Eingabesprache der Excel-Datei</strong>
          <select
            v-if="languages"
            v-model="newServicePlan.data.language"
            class="rg-select"
          >
            <option
              v-for="option in languages"
              :key="option.id"
              :value="option.id"
              :selected="option.id === newServicePlan.data.language"
            >
              {{ option.language_name }} ({{ option.language_code }})
            </option>
          </select>

          <strong> Quelle der Daten </strong>
          <input
            v-model="newServicePlan.data.source"
            type="text"
            placeholder="Vollständiger Link zum Gitlab-Ticket"
          >

          <strong> Excel-Datei </strong>
          <div
            :class="[
              'file-upload-container',
              { 'is-dragging': newServicePlan.dragging },
            ]"
            @drag.stop.prevent
            @dragstart.stop.prevent
            @dragenter.stop.prevent="newServicePlan.dragging = true"
            @dragover.stop.prevent="newServicePlan.dragging = true"
            @dragend.stop.prevent="newServicePlan.dragging = false"
            @dragleave.stop.prevent="newServicePlan.dragging = false"
            @drop.stop.prevent="handleFile"
          >
            <input
              id="file"
              type="file"
              accept=".xls,.xlsx"
              @change="handleFile"
            >
            <label
              for="file"
              class="file-upload"
            >
              <span class="file-upload-select">Datei auswählen</span> oder
              <span class="file-upload-drop">hier ablegen</span>
            </label>
          </div>
          <small
            v-if="newServicePlan.data.file"
            style="font-family: monospace"
          >
            {{ newServicePlan.data.file.name }}
          </small>
        </div>
        <template v-if="newServicePlan.errors.length">
          <p
            v-for="error in newServicePlan.errors"
            :key="error.message"
            style="color: red"
          >
            <pre style="overflow-x: auto;">{{ error.message }}</pre>
          </p>
        </template>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            gap: 5px;
            margin-top: 10px;
          "
        >
          <rg-button
            label="Abbrechen"
            type="is-danger"
            @click="abortNewServicePlanCreation()"
          />
          <rg-button
            label="Speichern"
            type="is-primary"
            :disabled="!newServicePlanValid || newServicePlan.loading"
            @click="saveNewServicePlan()"
          />
        </div>
      </section>
    </transition>
  </section>
</template>

<script>
import { ref, watchEffect, computed, watch } from "@vue/composition-api";
import { generateStatus } from "@/helper.js";
import Vue from "vue";
import superagent from "superagent";
import { altBackendUrl } from "@/constants.js";

const EXCEL_FILE_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export default {
  props: { data: { type: Object, default: () => {} } },
  setup(props, context) {
    let machine = ref({});
    let languages = ref([]);
    let serviceplans = ref([]);
    let newServicePlan = ref({
      modalOpen: false,
      loading: false,
      dragging: false,
      data: {
        language: 1,
        title: "",
        source: "",
        file: null,
        kitTitle: "",
        kitID: "",
      },
      errors: [],
    });
    const newServicePlanValid = computed(() => {
      if (
        !newServicePlan.value.data.language ||
        !newServicePlan.value.data.title ||
        !newServicePlan.value.data.file
      )
        return false;

      return true;
    });

    const abortNewServicePlanCreation = () => {
      if (
        newServicePlan.value.data.language === 1 &&
        newServicePlan.value.data.title === "" &&
        newServicePlan.value.data.source === "" &&
        newServicePlan.value.data.file === null &&
        newServicePlan.value.data.kitTitle === "" &&
        newServicePlan.value.data.kitID === ""
      )
        return (newServicePlan.value.modalOpen = false);

      if (window.confirm("Änderungen werden verworfen. Wirklich abbrechen?")) {
        newServicePlan.value.modalOpen = false;
        newServicePlan.value.data = {
          language: 1,
          title: "",
          source: "",
          file: null,
          kitTitle: "",
          kitID: "",
        };
      }
    };

    const saveNewServicePlan = () => {
      newServicePlan.value.loading = true;

      const FORM_DATA = new FormData();
      FORM_DATA.append(
        "operations",
        JSON.stringify({
          query: `
          mutation mServiceUpload($file: File!, $machineId: Int!, $languageId: Int!, $planTitle: String, $gitlabURL: String, $authorId: Int, $fileName: String) {
            ServiceUpload(file: $file, machineId: $machineId, languageId: $languageId, planTitle: $planTitle, gitlabURL: $gitlabURL, authorId: $authorId, fileName: $fileName)
          }
        `,
          variables: {
            file: null,
            machineId: parseInt(machine.value.id),
            languageId: parseInt(newServicePlan.value.data.language),
            planTitle: newServicePlan.value.data.title,
            gitlabURL: newServicePlan.value.data.source,
            authorId: context.root.$store.state.user.id,
            fileName: newServicePlan.value.data.file.name,
          },
        })
      );
      FORM_DATA.append("map", JSON.stringify({ 0: ["variables.file"] }));
      FORM_DATA.append("0", newServicePlan.value.data.file);

      superagent
        .post(altBackendUrl)
        .send(FORM_DATA)
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          if (res.body.errors) {
            newServicePlan.value.data.file = null;
            newServicePlan.value.errors = res.body.errors;
            newServicePlan.value.loading = false;
          } else {
            Vue.$toast.open(
              generateStatus(res.status, {
                ok: "Wartungsplan gespeichert. Lade neu...",
              })
            );

            setTimeout(() => {
              context.root.$router.go();
            }, 1000);
          }
        })
        .catch((err) => {
          newServicePlan.value.data.file = null;
          newServicePlan.value.errors = err?.response?.body?.errors;
          newServicePlan.value.loading = false;
        });
    };

    const deleteServicePlan = () => {
      Vue.$toast.open("Diese Funktion folgt in einem späteren Update.");
    };

    const handleFile = (e) => {
      newServicePlan.value.dragging = false;
      const FILE = e.dataTransfer?.files?.[0] || e.target.files?.[0];

      if (FILE.type === EXCEL_FILE_TYPE) {
        newServicePlan.value.data.file = FILE;
        newServicePlan.value.errors = [];
      } else {
        Vue.$toast.open({
          message:
            "Unbekanntes Dateiformat. Bitte nur Excel-Dateien hochladen.",
          icon: "exclamation-triangle",
          type: "is-danger",
        });
      }
    };

    function fetchData() {
      const gqQueryMachineService = `
        query Query($id: Int!) {
          AppLanguages {
            language_name
            id
            language_code
          }
          Machine(id: $id) {
            id
            slug
            Contents {
              __typename
              ... on ServiceContent {
                Plans(locale: "de", limit: 1, skip: 0) {
                  id
                  title
                  authorId
                  fileName
                  Author {
                      id
                      lastName
                      firstName
                  }
                  language
                  source
                  createdAt
                  updatedAt
                  Intervals(limit: 999, skip: 0) {
                    nodes {
                      title
                    }
                  }
                  Groups(limit: 999, skip: 0) {
                    nodes {
                      title
                      Tasks(limit: 999, skip: 0) {
                        nodes {
                          title
                        }
                      }
                    }
                  }
                  Parts(limit: 999, skip: 0) {
                    nodes {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      `;

      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryMachineService,
          variables: { id: Number.parseInt(machine?.value?.id) },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(({ body }) => {
          console.log(body);
          languages.value = body.data.AppLanguages.sort((a, b) => {
            return a.language_name.localeCompare(b.language_name);
          });

          // find content with service plans
          const content = body.data.Machine.Contents.find(
            (content) => content.__typename === "ServiceContent"
          );

          serviceplans.value = content?.Plans || [];
        });
    }

    watch(machine, () => {
      fetchData();
    });

    watchEffect(() => {
      machine.value = props.data;
    });

    return {
      newServicePlan,
      newServicePlanValid,
      abortNewServicePlanCreation,
      saveNewServicePlan,
      deleteServicePlan,
      handleFile,
      languages,
      machine,
      serviceplans,
    };
  },
};
</script>

<style lang="scss">
.file-upload-container {
  display: flex;
  input {
    display: none !important;
  }
  .file-upload {
    cursor: pointer;
    width: 100%;
    background-color: var(--input-background);
    outline: 1px dashed var(--black-7);
    outline-offset: -6px;
    border-radius: 8px;
    padding: 24px 16px;
    text-align: center;
    color: var(--black-7);
    transition: all 0.2s ease;
    span {
      display: inline-block;
      transform: translateY(0);
      transition: all 0.2s ease;
      text-decoration: underline;
      text-decoration-color: transparent;
    }
  }
  &.is-dragging {
    cursor: copy;
    .file-upload-drop {
      color: var(--black-O);
      transform: translateY(-1px);
      text-decoration-color: var(--black-O);
    }
    .file-upload {
      outline: 1px dashed white;
    }
  }
  &:not(.is-dragging):hover {
    .file-upload-select {
      color: var(--black-O);
      transform: translateY(-1px);
      text-decoration-color: var(--black-O);
    }
  }
}
</style>

<style lang="scss" module>
.modal {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999999999;
  width: 600px;
  max-width: 90vw;
  border-radius: 16px;
  padding: 25px;
}
</style>
