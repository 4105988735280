<template>
  <section v-if="loading">
    <p>LOADING…</p>
  </section>
  <section v-else-if="!machine || !machine.type === 'Traktor'">
    Nur Maschinen vom Typ Traktor können aktuell mit Varta verknüpft werden
  </section>
  <section v-else>
    <div :class="['its-a-match', { animate: its_a_match }]">
      <canvas id="its-a-match-canvas" />
      <span>It's a Match!</span>
    </div>
    <div v-if="matching && loading === true">
      <fa icon="spinner" />&nbsp;Prüfe auf Matches, bitte warten.
    </div>
    <div v-else-if="matching && loading === false">
      <div class="rg-grid">
        <div class="w12 m6 l6">
          <h2>Aktuelle Verknüpfung</h2>
          <br>
          <div
            class="card"
            style="border-radius: 10px; padding: 20px"
          >
            <div class="level">
              <div class="level-left">
                <h3 v-if="matchStatus">
                  {{ matchStatus.statusText }}
                </h3>
                <h3 v-else>
                  Keine Verknüpfung vorhanden
                </h3>
              </div>
              <div
                v-if="!showMatching"
                class="level-right"
                style="display: flex; gap: 10px"
              >
                <rg-button
                  v-if="!matchStatus || matchStatus.status === 'notfound'"
                  label="Verknüpfung herstellen"
                  class="is-primary"
                  :disabled="showMatching"
                  @click="openMatching()"
                />
                <rg-button
                  v-else-if="
                    ['new', 'secondlevel-approval'].includes(matchStatus.status)
                  "
                  label="Verknüpfung bestätigen"
                  class="is-primary"
                  :disabled="showMatching"
                  @click="openMatching()"
                />
                <rg-button
                  v-if="matchStatus && matchStatus.status !== 'notfound'"
                  label="Verknüpfung löschen"
                  class="is-danger"
                  @click="match('notfound')"
                />
              </div>
            </div>
            <table
              v-if="matchStatus"
              class="rg-table"
            >
              <tr v-if="matching.dataset_version">
                <td>
                  <fa
                    v-if="!matching.dataset_version"
                    icon="exclamation-triangle"
                    class="fa-fw"
                  />&nbsp;Datenstand
                </td>
                <td>
                  <strong>{{
                    matching.dataset_version || "Keine Version angegeben"
                  }}</strong>
                </td>
              </tr>
              <tr v-if="matching.updatedat">
                <td>Zuletzt bearbeitet</td>
                <td>
                  <strong>{{
                    new Date(matching.updatedat).toLocaleDateString()
                  }}</strong>
                  um
                  <strong>{{
                    new Date(matching.updatedat).toLocaleTimeString()
                  }}</strong>
                </td>
              </tr>
              <tr v-if="matching.matchedat">
                <td>
                  {{
                    matchStatus && matchStatus.status !== "notfound"
                      ? "Verknüpfung erstellt"
                      : "Verknüpfung zurückgestellt"
                  }}
                </td>
                <td>
                  <strong>{{
                    new Date(matching.matchedat).toLocaleDateString()
                  }}</strong>
                  um
                  <strong>{{
                    new Date(matching.matchedat).toLocaleTimeString()
                  }}</strong>
                  von
                  <strong>
                    {{ matching.matchedByName || "unbekannt" }}
                  </strong>
                </td>
              </tr>
              <tr v-if="matching.approvedat">
                <td>Verknüpfung bestätigt</td>
                <td>
                  <strong>{{
                    new Date(matching.approvedat).toLocaleDateString()
                  }}</strong>
                  um
                  <strong>{{
                    new Date(matching.approvedat).toLocaleTimeString()
                  }}</strong>
                  von
                  <strong>
                    {{ matching.matchedByName || "unbekannt" }}
                  </strong>
                </td>
              </tr>
              <tr>
                <td>
                  <fa
                    v-if="!matching.machine_type"
                    icon="exclamation-triangle"
                    class="fa-fw"
                  />&nbsp;Fahzeugtyp
                </td>
                <td>
                  <strong>{{
                    matching.machine_type || "Kein Typ angegeben"
                  }}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <fa
                    v-if="!matching.machine_manufacturer"
                    icon="exclamation-triangle"
                    class="fa-fw"
                  />&nbsp;Hersteller
                </td>
                <td>
                  <strong>{{
                    matching.machine_manufacturer || "Kein Hersteller angegeben"
                  }}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <fa
                    v-if="!matching.machine_series"
                    icon="exclamation-triangle"
                    class="fa-fw"
                  />&nbsp; Serie (Model Line)
                </td>
                <td>
                  <strong>{{
                    matching.machine_series || "Keine Serie angegeben"
                  }}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <fa
                    v-if="!matching.machine_model"
                    icon="exclamation-triangle"
                    class="fa-fw"
                  />&nbsp;Modell (Type)
                </td>
                <td>
                  <strong>{{
                    matching.machine_model || "Kein Modell angegeben"
                  }}</strong>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="w12 m6 l6">
          <h2>Kommentar</h2>
          <br>
          <div
            class="card"
            style="border-radius: 10px; padding: 20px"
          >
            {{ matching.annotations || "Kein Kommentar" }}
          </div>
        </div>
      </div>
      <br>
      <div
        v-if="showMatching"
        class="w12"
      >
        <h2>Matching</h2>
        <br>
        <div
          class="card"
          style="border-radius: 10px; padding: 20px"
        >
          <div class="level">
            <div class="level-left">
              <h3>Interaktives Matching</h3>
            </div>
            <div class="level-right">
              <h3>Informationen zum Fahrzeug</h3>
            </div>
          </div>
          <hr>
          <div class="rg-grid">
            <div class="form w8">
              <strong><span>Fahrzeugtyp</span> </strong>
              <select
                v-model="varta.type"
                class="rg-select"
                :disabled="!vartaTypes || vartaTypes.length < 1"
                @change="getVartaVersions(varta.type)"
              >
                <option
                  v-for="option in vartaTypes"
                  :key="option.key"
                  :value="option.key"
                >
                  {{ `${option.label} (${option.key})` }}
                </option>
              </select>
              <div>
                <strong><span>Datenstand</span> </strong>
                <select
                  v-model="varta.version"
                  class="rg-select"
                  :disabled="
                    !varta.type || !vartaVersions || vartaVersions.length < 1
                  "
                  @change="getVartaManufacturers(varta.type, varta.version)"
                >
                  <option
                    v-for="option in vartaVersions"
                    :key="option"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
              <div>
                <strong><span>Hersteller</span> </strong>
                <select
                  v-model="varta.manufacturer"
                  class="rg-select"
                  :disabled="
                    !varta.type ||
                      !varta.version ||
                      !vartaManufacturers ||
                      vartaManufacturers.length < 1
                  "
                  @change="
                    getVartaSeries(
                      varta.type,
                      varta.version,
                      varta.manufacturer
                    )
                  "
                >
                  <option
                    v-for="option in vartaManufacturers"
                    :key="option.key"
                    :value="option.key"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
              <div>
                <strong><span>Serie</span> </strong>
                <select
                  v-model="varta.series"
                  class="rg-select"
                  :disabled="
                    !varta.type ||
                      !varta.version ||
                      !varta.manufacturer ||
                      !vartaSeries ||
                      vartaSeries.length < 1
                  "
                  @change="
                    getVartaModels(
                      varta.type,
                      varta.version,
                      varta.manufacturer,
                      varta.series
                    )
                  "
                >
                  <option
                    v-for="option in vartaSeries"
                    :key="option.key"
                    :value="option.key"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
              <div>
                <strong><span>Modell</span> </strong>
                <select
                  v-model="varta.model"
                  class="rg-select"
                  :disabled="
                    !varta.type ||
                      !varta.version ||
                      !varta.manufacturer ||
                      !varta.series ||
                      !vartaModels ||
                      vartaModels.length < 1
                  "
                  @change="
                    getVartaProducts(
                      varta.type,
                      varta.version,
                      varta.manufacturer,
                      varta.series,
                      varta.model
                    )
                  "
                >
                  <option
                    v-for="option in vartaModels"
                    :key="option.key"
                    :value="option.key"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
              <div>
                <strong><span>Kommentar zum Matching</span></strong>
                <textarea
                  v-model="varta.comment"
                  rows="5"
                />
              </div>
              <div>
                <span
                  class="level"
                ><strong>Bearbeiter zuweisen</strong>
                  <a @click="removeAssignee()"> Bearbeiter entfernen </a>
                </span>
                <select
                  v-model="varta.assignedto"
                  class="rg-select"
                >
                  <option key="0" />
                  <option
                    v-for="option in staffMembers"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.prename }} {{ option.name }}
                  </option>
                </select>
              </div>
              <div style="display: flex; gap: 10px">
                <div
                  v-if="
                    !matchStatus ||
                      (matchStatus && matchStatus.status === 'notfound')
                  "
                  style="display: flex; gap: 10px"
                >
                  <rg-button
                    label="Verknüpfung erstellen"
                    class="is-primary"
                    :disabled="!matchingCompleted()"
                    @click="match('new')"
                  />
                  <rg-button
                    label="Nachprüfung beantragen"
                    class="is-danger"
                    :disabled="!matchingCompleted()"
                    @click="match('secondlevel-approval')"
                  />
                  <rg-button
                    label="Verknüpfung nicht möglich"
                    class="is-danger"
                    @click="match('notfound')"
                  />
                </div>
                <div v-else>
                  <rg-button
                    label="Verknüpfung bestätigen"
                    class="is-primary"
                    :disabled="!matchingCompleted()"
                    @click="match('approved')"
                  />
                </div>
                <rg-button
                  label="Bearbeitung abbrechen"
                  @click="cancel()"
                />
              </div>
            </div>
            <div class="form w4">
              <table class="rg-table">
                <tr>
                  <td>Fahzeugtyp</td>
                  <td>
                    <strong>{{ machine.type || "unbekannt" }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Hersteller</td>
                  <td>
                    <strong>{{ machine.manufacturer || "unbekannt" }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Serie</td>
                  <td>
                    <strong>{{ machine.series || "unbekannt" }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Modell</td>
                  <td>
                    <strong>{{ machine.model || "unbekannt" }}</strong>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div
        v-if="matching.machine_model || (showMatching && matchingCompleted())"
      >
        <h2>Produkte</h2>
        <br>
        <div>
          <div
            v-if="vartaProducts.batteries && vartaProducts.batteries.length > 0"
          >
            <div
              v-for="product in vartaProducts.batteries"
              :key="product.details.article_no"
            >
              <div class="rg-grid">
                <div class="w8">
                  <div
                    class="card"
                    style="
                      border-radius: 10px;
                      padding: 20px;
                      margin-bottom: 10px;
                    "
                  >
                    <div class="level">
                      <div>
                        <h3>{{ product.details.productline }}</h3>
                        {{ product.details.article_no }}
                        <br>
                        <img
                          v-if="product.image"
                          :src="product.image"
                          alt="Batterie"
                        >
                      </div>
                      <div>
                        <div
                          v-for="benefit in product.keybenefits"
                          :key="benefit.textNo"
                        >
                          <img
                            :src="benefit.iconUrl"
                            alt="icon"
                          >
                          {{ benefit.text }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="level w8"
                      style="
                        dislay: flex;
                        align-items: flex-start;
                        flex-wrap: wrap;
                      "
                    >
                      <div
                        v-for="drawing in product.drawings"
                        :key="drawing.name"
                      >
                        <h3>
                          {{ drawing.type.charAt(0).toUpperCase()
                          }}{{ drawing.type.slice(1) }}
                        </h3>
                        <img
                          :src="drawing.image"
                          alt="icon"
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w4">
                  <div
                    class="card"
                    style="
                      border-radius: 10px;
                      padding: 20px;
                      margin-bottom: 10px;
                    "
                  >
                    <h2>Produktdetails</h2>
                    <table
                      v-if="product.details || product.productsheet"
                      class="rg-table"
                    >
                      <tr v-if="product.details.short_code">
                        <td>
                          <strong>Typenbezeichnung (Short Code)</strong>
                        </td>
                        <td>
                          {{ product.details.short_code }}
                        </td>
                      </tr>
                      <tr v-if="product.details.productline">
                        <td>
                          <strong>Productserie</strong>
                        </td>
                        <td>
                          {{ product.details.productline }}
                        </td>
                      </tr>
                      <tr v-if="product.details.article_no">
                        <td>
                          <strong>Artikelnummer</strong>
                        </td>
                        <td>
                          {{ product.details.article_no }}
                        </td>
                      </tr>
                      <tr v-if="product.details.etn">
                        <td>
                          <strong>ETN Nummerr</strong>
                        </td>
                        <td>
                          {{ product.details.etn }}
                        </td>
                      </tr>
                      <tr v-if="product.productsheet">
                        <td>
                          <strong>Datenblatt </strong>
                        </td>
                        <td>
                          <a :href="product.productsheet">Datenblatt</a>
                        </td>
                      </tr>
                      <tr v-if="product.details.voltage">
                        <td>
                          <strong>Spannung</strong>
                        </td>
                        <td>{{ product.details.voltage }} V</td>
                      </tr>
                      <tr v-if="product.details.ah">
                        <td>
                          <strong>Kapazität</strong>
                        </td>
                        <td>{{ product.details.ah }} Ah</td>
                      </tr>
                      <tr v-if="product.details.cca">
                        <td>
                          <strong>Kaltstartfähigkeit (CCA)</strong>
                        </td>
                        <td>{{ product.details.cca }} A</td>
                      </tr>
                      <tr
                        v-if="
                          product.details.length &&
                            product.details.width &&
                            product.details.height
                        "
                      >
                        <td>
                          <strong>Produktabmessungen (L/B/H)</strong>
                        </td>
                        <td>
                          {{ product.details.length }} mm /
                          {{ product.details.width }} mm /
                          {{ product.details.height }} mm
                        </td>
                      </tr>
                      <tr v-if="product.details.case_size">
                        <td>
                          <strong>Produktabmessungen (Code)</strong>
                        </td>
                        <td>{{ product.details.case_size }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, watchEffect, onMounted } from "@vue/composition-api";
import superagent from "superagent";
import {backendUrl} from "@/constants.js";
import Vue from "vue";
const confetti = require("canvas-confetti");

export default {
  beforeRouteLeave(to, from, next) {
    next(
      !this.showMatching ||
        window.confirm("Bearbeitung aktiviert. Formular wirklich verlassen?")
    );
  },
  props: { data: { type: Object, default: () => {} } },
  setup(props, context) {
    const loading = ref(true);
    const userid = context.root.$store.state.user.id;
    const machine = ref({});
    const matching = ref({});
    const showMatching = ref(false);
    const varta = ref({});
    const vartaTypes = ref([]);
    const vartaVersions = ref([]);
    const vartaManufacturers = ref([]);
    const vartaSeries = ref([]);
    const vartaModels = ref([]);
    const vartaProducts = ref([]);
    const matchStatus = ref({});
    const staffMembers = ref([{}]);
    const its_a_match = ref(false);

    onMounted(() => {
      getMatch();
    });

    watchEffect(() => {
      machine.value = props.data;
    });

    function getStatus() {
      switch (matching?.value?.status) {
        case "new":
          return { status: "new", statusText: "Wartet auf Bestätigung" };
        case "approved":
          return { status: "approved", statusText: "Verknüpfung bestätigt" };
        case "notfound":
          return {
            status: "notfound",
            statusText: "Kein passender Match gefunden",
          };
        case "secondlevel-approval":
          return {
            status: "secondlevel-approval",
            statusText: "Wartet auf erneute Prüfung",
          };
        default:
          return false;
      }
    }

    function getName(id) {
      const gqQueryUserNameAttributes = `
      query VartaModel($userId: Int) {
        User(id: $userId) {
          prename
          name
        }
      }
      `;
      return superagent
        .post(backendUrl)
        .send({
          query: gqQueryUserNameAttributes,
          variables: { userId: id },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(({ body: user }) => {
          return user.data?.User?.prename || user.data?.User?.name
            ? `${user.data?.User?.prename} ${user.data?.User?.name}`
            : "unbekannt";
        })
        .catch((err) => {
          Vue.$toast.open(err);
        });
    }

    function getMatch() {
      //receives current matched data
      const gqQueryMatch = `
      query VartaModel($machineId: Int) {
        VartaModel(machine_id: $machineId) {
          id
          machine_id
          machine_type
          dataset_version
          machine_manufacturer
          machine_series
          machine_model
          createdat
          updatedat
          matchedby
          approvedby
          assignedto
          matchedat
          approvedat
          status
          annotations
        }
      }
      `;
      superagent
        .post(backendUrl)
        .send({
          query: gqQueryMatch,
          variables: { machineId: machine.value.id },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(async ({ body: matches }) => {
          matching.value = {
            ...matches.data?.VartaModel,
            matchedByName: "",
            approvedByName: "",
          };
          matching.value.matchedByName = await getName(
            matching.value.matchedby
          );
          matching.value.approvedByName = await getName(
            matching.value.approvedBy
          );
          matchStatus.value = getStatus();
        })
        .catch((err) => {
          Vue.$toast.open(err);
        })
        .finally(() => {
          if (
            matching.value.machine_type &&
            matching.value.dataset_version &&
            matching.value.machine_manufacturer &&
            matching.value.machine_series &&
            matching.value.machine_model
          ) {
            getVartaProducts(
              matching.value.machine_type,
              matching.value.dataset_version,
              matching.value.machine_manufacturer,
              matching.value.machine_series,
              matching.value.machine_model
            );
          }
          loading.value = false;
        });
      return false;
    }

    function getVartaVehicleTypes() {
      //Varta API Step I
      vartaTypes.value = [];
      delete varta.value.type;
      const gqQueryVartaTypes = `
      query Query {
        VartaVehicleTypes
      }
      `;
      superagent
        .post(backendUrl)
        .send({
          query: gqQueryVartaTypes,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(({ body: types }) => {
          vartaTypes.value = types.data.VartaVehicleTypes;
        })
        .catch((err) => {
          Vue.$toast.open(err);
        });
    }

    function getVartaVersions(type) {
      //Varta API Step II
      vartaVersions.value = [];
      delete varta.value.version;
      const gqQueryVartaVersions = `
      query Query($vehicleType: String!) {
        VartaDataVersions(vehicleType: $vehicleType)
      }
      `;
      superagent
        .post(backendUrl)
        .send({
          query: gqQueryVartaVersions,
          variables: { vehicleType: type },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(({ body: versions }) => {
          vartaVersions.value = versions.data.VartaDataVersions;
        })
        .catch((err) => {
          Vue.$toast.open(err);
        });
    }

    function getVartaManufacturers(type, version) {
      //Varta API Step III
      vartaManufacturers.value = [];
      delete varta.value.manufacturer;
      const gqQueryVartaManufacturers = `
      query Query($dataVersion: String!, $vehicleType: String!) {
        VartaManufacturers(dataVersion: $dataVersion, vehicleType: $vehicleType)
      }
      `;
      superagent
        .post(backendUrl)
        .send({
          query: gqQueryVartaManufacturers,
          variables: { vehicleType: type, dataVersion: version.toString() },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(({ body: manufacturers }) => {
          vartaManufacturers.value = manufacturers.data.VartaManufacturers;
        })
        .catch((err) => {
          Vue.$toast.open(err);
        });
    }

    function getVartaSeries(type, version, manufacturer) {
      //Varta API Step IV
      vartaSeries.value = [];
      delete varta.value.series;
      const gqQueryVartaSeries = `
      query Query($dataVersion: String!, $manufacturer: String!, $vehicleType: String!) {
        VartaSeries(dataVersion: $dataVersion, manufacturer: $manufacturer, vehicleType: $vehicleType)
      }
      `;
      superagent
        .post(backendUrl)
        .send({
          query: gqQueryVartaSeries,
          variables: {
            vehicleType: type,
            dataVersion: version.toString(),
            manufacturer: manufacturer.toString(),
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(({ body: series }) => {
          vartaSeries.value = series.data.VartaSeries;
        })
        .catch((err) => {
          Vue.$toast.open(err);
        });
    }

    function getVartaModels(type, version, manufacturer, series) {
      //Varta API Step V
      vartaModels.value = [];
      delete varta.value.model;
      const gqQueryVartaModels = `
      query Query($dataVersion: String!, $manufacturer: String!, $series: String!, $vehicleType: String!) {
        VartaModels(dataVersion: $dataVersion, manufacturer: $manufacturer, series: $series, vehicleType: $vehicleType)
      }
      `;
      superagent
        .post(backendUrl)
        .send({
          query: gqQueryVartaModels,
          variables: {
            vehicleType: type,
            dataVersion: version.toString(),
            manufacturer: manufacturer.toString(),
            series: series.toString(),
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(({ body: models }) => {
          vartaModels.value = models.data.VartaModels;
        })
        .catch((err) => {
          Vue.$toast.open(err);
        });
    }

    function getVartaProducts(type, version, manufacturer, series, model) {
      //Varta API Step VI - finally
      vartaProducts.value = [];
      const gqQueryVartaProducts = `
      query Query($dataVersion: String!, $manufacturer: String!, $series: String!, $model: String!) {
        VartaProducts(dataVersion: $dataVersion, manufacturer: $manufacturer, series: $series, model: $model)
      }
      `;
      superagent
        .post(backendUrl)
        .send({
          query: gqQueryVartaProducts,
          variables: {
            vehicleType: type,
            dataVersion: version.toString(),
            manufacturer: manufacturer.toString(),
            series: series.toString(),
            model: model.toString(),
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(({ body: products }) => {
          vartaProducts.value = products.data.VartaProducts;
        })
        .catch((err) => {
          Vue.$toast.open(err);
        });
    }

    function openMatching() {
      getVartaVehicleTypes();
      getStaff();
      varta.value = { ...varta.value, assignedto: matching.value.assignedto };
      showMatching.value = true;
    }

    function match(toStatus) {
      const mandatoryFields = [
        "type",
        "version",
        "manufacturer",
        "series",
        "model",
      ];
      if (
        !toStatus === "notfound" &&
        !mandatoryFields.every((elem) =>
          Object.keys(varta.value).includes(elem)
        )
      ) {
        Vue.$toast.open({
          message: "Es wurden nicht alle benötigten Felder ausgefüllt.",
          icon: "times-circle",
        });
        return false;
      } else if (toStatus === "secondlevel-approval" && !varta.value.comment) {
        Vue.$toast.open({
          message: "Eine Nachprüfung erfordert die Eingabe eines Kommentars.",
          icon: "times-circle",
        });
        return false;
      } else {
        const gqMatchingMutation = `
        mutation Mutation($vartaModelId: Int, $machineId: Int, $machineType: String, $datasetVersion: String, $machineManufacturer: String,
          $machineSeries: String, $machineModel: String, $matchedby: Int, $approvedby: Int, $assignedto: Int, $status: String,
          $matchedat: DateTime, $annotations: String, $approvedat: DateTime) {
          VartaModel(id: $vartaModelId, machine_id: $machineId, machine_type: $machineType, dataset_version: $datasetVersion,
            machine_manufacturer: $machineManufacturer, machine_series: $machineSeries, machine_model: $machineModel, matchedby: $matchedby,
            approvedby: $approvedby, assignedto: $assignedto, status: $status, matchedat: $matchedat, annotations: $annotations,
            approvedat: $approvedat) {
              id
          }
        }
        `;
        //conditional payload handling ahead
        const payload = {
          vartaModelId: matching?.value?.id ? parseInt(matching.value.id) : -1,
          machineId: parseInt(machine?.value?.id) || null,
          machineType: varta?.value?.type?.toString(),
          datasetVersion: varta?.value?.version?.toString(),
          machineManufacturer: varta?.value?.manufacturer?.toString(),
          machineSeries: varta?.value?.series?.toString(),
          machineModel: varta?.value?.model?.toString(),
          status: toStatus,
          assignedto: parseInt(varta?.value?.assignedto) || null,
          annotations: varta?.value?.comment?.toString(),
        };

        if (toStatus === "approved") {
          if (!compareMatch()) {
            Vue.$toast.open({
              message: "Die gematchten Daten stimmen nicht überein.",
              icon: "times-circle",
            });
            return false;
          } else {
            payload.approvedby = parseInt(userid) || null;
            payload.approvedat = new Date().toISOString();
          }
        } else if (toStatus === "new" || toStatus === "secondlevel-approval") {
          payload.matchedby = parseInt(userid) || null;
          payload.matchedat = new Date().toISOString();
        } else if (toStatus === "notfound") {
          payload.machineType = null;
          payload.datasetVersion = null;
          payload.machineManufacturer = null;
          payload.machineSeries = null;
          payload.machineModel = null;
          payload.matchedby = parseInt(userid) || null;
          payload.matchedat = new Date().toISOString();
          payload.approvedby = null;
          payload.approvedat = null;
        }
        //payload done

        superagent
          .post(backendUrl)
          .send({
            query: gqMatchingMutation,
            variables: payload,
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then(({ body: series }) => {
            vartaSeries.value = series.data.VartaSeries;
            if (toStatus === "approved") {
              its_a_match.value = true;
              var cv = document.getElementById("its-a-match-canvas");
              var conf = confetti.create(cv, { resize: true });
              conf({ particleCount: 100, spread: 75, origin: { y: 0.7 } });
              setTimeout(() => (its_a_match.value = false), 1250);
              Vue.$toast.open({
                message: "Matching war Erfolgreich.",
                icon: "check",
              });
            } else if (toStatus === "notfound") {
              Vue.$toast.open({
                message:
                  "Der Status wurde gespeichert, ein eventuell bereits vorhandener Match wurde entfernt.",
                icon: "check",
              });
            } else {
              Vue.$toast.open({
                message: "Status und Daten erfolgreich gespeichert.",
                icon: "check",
              });
            }
            Vue.$toast.open({
              message: "Seite wird in Kürze neu geladen...",
              icon: "sync",
            });
            setTimeout(() => {
              context.root.$router.go();
            }, 2000);
          })
          .catch((err) => {
            Vue.$toast.open(err);
          });
      }
    }

    function matchingCompleted() {
      const mandatoryFields = [
        "type",
        "version",
        "manufacturer",
        "series",
        "model",
      ];
      return mandatoryFields.every((elem) =>
        Object.keys(varta.value).includes(elem)
      );
    }

    function compareMatch() {
      return (
        matching.value.machine_type.toString() ===
          varta.value.type.toString() &&
        matching.value.dataset_version.toString() ===
          varta.value.version.toString() &&
        matching.value.machine_manufacturer.toString() ===
          varta.value.manufacturer.toString() &&
        matching.value.machine_series.toString() ===
          varta.value.series.toString() &&
        matching.value.machine_model.toString() === varta.value.model.toString()
      );
    }

    function getStaff() {
      const gqQueryStaffMembers = `
      query Query($status: Boolean, $roles: [String]) {
        Users(status: $status, roles: $roles) {
          id
          prename
          name
        }
      }
      `;
      superagent
        .post(backendUrl)
        .send({
          query: gqQueryStaffMembers,
          variables: {
            status: true,
            roles: ["editor"],
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(({ body: staff }) => {
          staffMembers.value = staff.data.Users;
        })
        .catch((err) => {
          Vue.$toast.open(err);
        });
    }

    function removeAssignee() {
      varta.value.assignedto = null;
    }

    function cancel() {
      if (
        Object.keys(varta.value).length > 1 &&
        !window.confirm(
          "Es wurden bereits Daten erfasst. Soll die Bearbeitung dennoch abgebrochen werden?"
        )
      ) {
        return;
      }
      varta.value = {};
      showMatching.value = false;
    }

    return {
      loading,
      userid,
      machine,
      matching,
      getStatus,
      getName,
      showMatching,
      openMatching,
      match,
      varta,
      vartaTypes,
      vartaVersions,
      getVartaVersions,
      vartaManufacturers,
      getVartaManufacturers,
      vartaSeries,
      getVartaSeries,
      vartaModels,
      getVartaModels,
      vartaProducts,
      getVartaProducts,
      matchingCompleted,
      matchStatus,
      its_a_match,
      staffMembers,
      removeAssignee,
      cancel,
    };
  },
};
</script>
<style lang="scss">
.its-a-match {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  #its-a-match-canvas {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
  }

  background: transparent;
  transition: background 0.2s ease;
  span {
    font-size: 3em;
    font-weight: bold;
    letter-spacing: 1px;
    user-select: none;
    opacity: 0;
    color: var(--black-O);
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(50px);
    position: absolute;
    transition: all 0.5s cubic-bezier(0.31, 1.53, 0.3, 1);
  }

  &.animate {
    background: var(--white-5);
    span {
      transform: translateX(-50%) translateY(0px);
      opacity: 1 !important;
    }
  }
}
</style>
