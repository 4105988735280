<template>
  <div>
    <navigation />
    <main>
      <h1><fa icon="shield-alt" />&nbsp;Berechtigungen</h1>
      <p>TBD ENDPOINTS, FOR META not per User</p>
    </main>
  </div>
</template>

<script>
  export default {
    name: "Permissions"
  }
</script>
